var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('CoreElementWrapper',{key:_vm.sermon?.id,class:_vm.listBorder ? 'list-divider border-theme' : '',attrs:{"show-left":!_vm.video && !_vm.hideSpeaker,"is-inert":_vm.isInert,"large":_vm.largeFeatured,"wrap":_vm.wrap,"remove-buttons":_vm.removeButtons || _vm.small,"loading":!_vm.sermon,"metadata-color":_vm.subtitleColor,"stacked-buttons":_vm.featured && !_vm.small,"wrap-top":_vm.extendedVideo},scopedSlots:_vm._u([{key:"top",fn:function(){return [(_vm.video || _vm.extendedVideo)?_c('SermonThumbnail',{attrs:{"sermon-url":_vm.autoplayPreview ? '' : _vm.sermonVideoUrl,"sermon":_vm.sermon,"preview-controls":_vm.autoplayPreview,"preview":_vm.autoplayPreview},nativeOn:{"click":function($event){return _vm.sendClickTrack.apply(null, arguments)}}}):_vm._e()]},proxy:true},{key:"left",fn:function(){return [_c('SpeakerImage',{class:_vm.largeFeatured ? 'text-16 sm:text-20' : 'text-10',attrs:{"size":_vm.speakerImageSize,"speaker":_vm.sermon ? _vm.sermon.speaker : undefined}})]},proxy:true},{key:"line-1",fn:function(){return [_c('LoadingElement',{class:[_vm.largeFeatured ? 'text-2xl font-light' : ''],attrs:{"type":_vm.SaLinkOrHref,"loading-classes":"w-56","object":_vm.sermon,"component-props":{
        isTo: !_vm.absoluteUrls,
        url: _vm.sermonUrl,
      }},nativeOn:{"click":function($event){return _vm.sendClickTrack.apply(null, arguments)}}},[_c('span',{class:_vm.small || _vm.video || _vm.twoCol ? '' : ' md:hidden'},[_vm._v(_vm._s(_vm.displayTitle))]),_vm._v(" "),(!_vm.small && !_vm.video && !_vm.twoCol)?_c('span',{staticClass:"hidden md:inline"},[_vm._v(_vm._s(_vm.fullTitle))]):_vm._e()])]},proxy:true},{key:"line-2",fn:function(){return [_c('div',{staticClass:"space-y-4"},[_c('SermonMetadata',{staticClass:"relative",attrs:{"sermon":_vm.sermon,"display":_vm.metadataDisplay,"small":_vm.small || _vm.video,"max-extended-info":_vm.maxExtendedInfo,"show-bible-text":_vm.showBibleText,"show-fourth-line":!_vm.smallFeatured,"show-date":!_vm.small,"show-duration":_vm.showDuration,"absolute-urls":_vm.absoluteUrls},on:{"sermonAdClicked":_vm.sendClickTrack}}),_vm._v(" "),(!_vm.small && !_vm.hideDescription)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.description),expression:"description"}],staticClass:"text-gray-metadata line-clamp-2 whitespace-normal"},[_vm._v("\n        "+_vm._s(_vm.description)+"\n      ")]):_vm._e()],1)]},proxy:true},{key:"buttons",fn:function(){return [(_vm.smallVideoThumbnail)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.sermon && _vm.sermon.hasVideo),expression:"sermon && sermon.hasVideo"}],staticClass:"ml-button flex items-center max-xs:hidden"},[_c('SermonThumbnail',{staticClass:"!w-20",attrs:{"sermon-url":_vm.sermon?.hasVideo ? _vm.sermonVideoUrl : _vm.sermonAudioUrl,"sermon":_vm.sermon,"small":"","absolute-urls":_vm.absoluteUrls},on:{"click":_vm.sendClickTrack}})],1):_vm._e(),_vm._v(" "),(
        !_vm.small &&
        !_vm.video &&
        !_vm.twoCol &&
        !_vm.featured &&
        !_vm.hidePlay &&
        _vm.placeholderOrMedia
      )?_c('LoadingElement',{staticClass:"inline-list-button shrink-0 hidden xs:inline-flex hover:!bg-gray-300 dark:hover:!bg-gray-800",attrs:{"type":_vm.sermon ? 'a' : 'div',"loading-classes":"inline-flex bg-transparent-important","object":_vm.sermon,"href":_vm.sermon ? _vm.sermonAudioUrl : ''},nativeOn:{"click":function($event){_vm.openSermon
        _vm.sendClickTrack}}},[_c('SaIcon',{staticClass:"ml-1",attrs:{"name":"play-solid"}})],1):_vm._e(),_vm._v(" "),_vm._t("buttons")]},proxy:true},{key:"stacked-buttons",fn:function(){return [(_vm.largeFeatured && !_vm.hideBroadcasterImage)?_c('LoadingElement',{staticClass:"mt-3",attrs:{"object":_vm.sermon,"text-height":false}},[_c('BroadcasterImage',{staticClass:"w-16",attrs:{"width":64,"broadcaster":_vm.sermon ? _vm.sermon.broadcaster : undefined}})],1):_vm._e()]},proxy:true}],null,true)},[_vm._v(" "),_vm._v(" "),_vm._v(" "),_vm._v(" "),_vm._v(" "),_vm._v(" "),(_vm.featured && _vm.showFeaturedAudioElements)?_c('div',[_c('div',[_c('LoadingElement',{staticClass:"mr-button hover:!bg-gray-200 dark:hover:!bg-gray-800",class:_vm.featuredButtonClasses,attrs:{"type":_vm.SaLinkOrHref,"text-height":false,"component-props":{
          isTo: !_vm.absoluteUrls,
          url: _vm.sermonAudioUrl,
        },"object":_vm.sermon},nativeOn:{"click":function($event){_vm.setPreferredMediaType(_vm.PlayerMediaType.Audio)
          _vm.sendClickTrack()}}},[_c('InlineIcon',{staticClass:"flex-center my-1",attrs:{"icon":"play","slot-classes":_vm.featuredButtonTextClasses}},[_vm._v(_vm._s(_vm.$t('Play Audio')))])],1),(!_vm.sermon || _vm.sermon.hasVideo)?_c('LoadingElement',{staticClass:"hover:!bg-gray-200 dark:hover:!bg-gray-800",class:_vm.featuredButtonClasses,attrs:{"type":_vm.SaLinkOrHref,"text-height":false,"component-props":{
          isTo: !_vm.absoluteUrls,
          url: _vm.sermonVideoUrl,
        },"object":_vm.sermon},nativeOn:{"click":function($event){_vm.setPreferredMediaType(_vm.PlayerMediaType.Video)
          _vm.sendClickTrack()}}},[_c('InlineIcon',{staticClass:"flex-center my-1",attrs:{"icon":"film","slot-classes":_vm.featuredButtonTextClasses}},[_vm._v(_vm._s(_vm.$t('Play Video')))])],1):_vm._e()],1),_vm._v(" "),(_vm.featured && _vm.showFeaturedAudioElements)?_c('div',{staticClass:"w-full transition-opacity mt-4",class:_vm.waveformRendered ? 'opacity-100' : 'opacity-0'},[_c('Waveform',{attrs:{"video":"","static":"","collapsed":false,"sermon":_vm.sermon,"dark":_vm.$colorMode.value === 'dark'}})],1):_vm._e()]):_vm._e(),_vm._v(" "),(_vm.isFeaturedAd && _vm.campaignID && _vm.sermon)?_c('SiteAdTrackerImage',{attrs:{"campaign-i-d":_vm.campaignID}}):_vm._e(),_vm._v(" "),_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }