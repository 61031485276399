import { ActionTree, GetterTree, MutationTree } from 'vuex'
import {
  AutoplayNextState,
  PlayerCastPlatform,
  PlayerMediaType,
  PlayerResumeState,
} from '~/assets/ts/enums'
import {
  GetLocalStorage,
  SetLocalStorage,
} from '~/assets/ts/utils/localstorage'

const SettingsKey = 'player.settings'

interface PlayerSettings {
  volume: number
  muted: boolean
  autoplayNext: boolean
  autoplayNextState: AutoplayNextState
  resumeState: PlayerResumeState
  speed: number
  quality: number
  subtitles: string | undefined
  casting: PlayerCastPlatform
  chromecastPaused: boolean
  preferredMediaType: PlayerMediaType
}

const PlayerDefaults = {
  volume: 100,
  muted: false,
  autoplayNext: true,
  autoplayNextState: AutoplayNextState.Forward,
  resumeState: PlayerResumeState.Ask,
  speed: 1,
  quality: -1,
  subtitles: undefined as string | undefined,
  casting: PlayerCastPlatform.None as PlayerCastPlatform,
  chromecastPaused: false,
  preferredMediaType: PlayerMediaType.Video as PlayerMediaType,
} as PlayerSettings

function Set(settings: PlayerSettings) {
  SetLocalStorage(SettingsKey, settings)
}

export const state = () => ({
  settings: PlayerDefaults,
})

export type PlayerState = ReturnType<typeof state>

export const mutations: MutationTree<PlayerState> = {
  SET_PLAYER_VOLUME: (state, volume: number) => {
    state.settings.volume = volume
    Set(state.settings)
  },
  SET_PLAYER_SUBTITLES: (state, name: string | undefined) => {
    state.settings.subtitles = name
    Set(state.settings)
  },
  SET_PLAYER_MUTED: (state, muted: boolean) => {
    state.settings.muted = muted
    Set(state.settings)
  },
  SET_PLAYER_AUTOPLAY_NEXT: (state, autoplayNext: boolean) => {
    state.settings.autoplayNext = autoplayNext
    Set(state.settings)
  },
  SET_PLAYER_AUTOPLAY_NEXT_STATE: (
    state,
    autoplayNextState: AutoplayNextState
  ) => {
    state.settings.autoplayNextState = autoplayNextState
    Set(state.settings)
  },
  SET_PLAYER_SPEED: (state, speed: number) => {
    state.settings.speed = speed
    Set(state.settings)
  },
  SET_PLAYER_QUALITY: (state, quality: number) => {
    state.settings.quality = quality
    Set(state.settings)
  },
  SET_PLAYER_CASTING: (state, casting: PlayerCastPlatform) => {
    state.settings.casting = casting
    Set(state.settings)
  },
  SET_CHROMECAST_PAUSED: (state, paused: boolean) => {
    state.settings.chromecastPaused = paused
    Set(state.settings)
  },
  SET_PREFERRED_MEDIA_TYPE: (state, type: PlayerMediaType) => {
    state.settings.preferredMediaType = type
    Set(state.settings)
  },
  SET_RESUME_STATE: (state, resumeState: PlayerResumeState) => {
    state.settings.resumeState = resumeState
    Set(state.settings)
  },
  INITIALIZE: (state) => {
    state.settings = GetLocalStorage(SettingsKey, PlayerDefaults)
  },
}

export const getters: GetterTree<PlayerState, PlayerState> = {
  volume: (state) => state.settings.volume,
  subtitles: (state) => state.settings.subtitles,
  muted: (state) => state.settings.muted,
  autoplayNext: (state) =>
    state.settings.autoplayNextState !== AutoplayNextState.Off,
  autoplayNextReverse: (state) =>
    state.settings.autoplayNextState === AutoplayNextState.Reverse,
  autoplayNextForward: (state) =>
    state.settings.autoplayNextState === AutoplayNextState.Forward,
  autoplayNextState: (state) => state.settings.autoplayNextState,
  resumeState: (state) => state.settings.resumeState,
  speed: (state) => state.settings.speed,
  quality: (state) => state.settings.quality,
  casting: (state) => state.settings.casting,
  chromecastPaused: (state) => state.settings.chromecastPaused,
  preferredMediaType: (state) => state.settings.preferredMediaType,
}

export const actions: ActionTree<PlayerState, PlayerState> = {
  nuxtClientInit({ commit }) {
    commit('INITIALIZE')
  },
}
