import { Context } from '@nuxt/types'
import { User } from '~/models/users/user'
import { siteUserPageUrl } from '~/assets/ts/utils/urls'

export default function (context: Context) {
  const user = context.$auth.user
  if (!user || (!new User(user).translator && !new User(user).admin)) {
    context.redirect(siteUserPageUrl('login'), {
      next: context.route.path,
      ...context.route.query,
    })
  }
}
