import { ActionTree, GetterTree, MutationTree } from 'vuex'
import {
  GetLocalStorage,
  SetLocalStorage,
} from '~/assets/ts/utils/localstorage'

const MyChurchID = 'my-church.broadcaster-id'

export const state = () => ({
  myChurchBroadcasterID: '',
})

export type LocalState = ReturnType<typeof state>
export const mutations: MutationTree<LocalState> = {
  SET_MY_CHURCH_BROADCASTER_ID: (state, broadcaster: string) => {
    state.myChurchBroadcasterID = SetLocalStorage(MyChurchID, broadcaster)
  },
  INITIALIZE: (state) => {
    state.myChurchBroadcasterID = GetLocalStorage(MyChurchID, '')
  },
}

export const getters: GetterTree<LocalState, LocalState> = {
  myChurchBroadcasterID: (state) => state.myChurchBroadcasterID,
}

export const actions: ActionTree<LocalState, LocalState> = {
  nuxtClientInit({ commit }) {
    commit('INITIALIZE')
  },
  setMyChurchBroadcasterID({ commit }, broadcasterID: string) {
    commit('SET_MY_CHURCH_BROADCASTER_ID', broadcasterID)
  },
}
