import { Context } from '@nuxt/types'
import { siteSpeakerUrl } from '~/assets/ts/utils/urls'

export default async function ({ params, store, error, redirect }: Context) {
  const speakerID = params.speaker || params.speakerID
  let validID = !!speakerID
  if (speakerID) {
    await store.dispatch('speakers/fetchSpeaker', { speakerID })
    validID = !!store.getters['speakers/speaker'](speakerID)

    /* Check if the "speakerID" is actually a speaker name. If it is, redirect to the page with the speaker ID set */
    if (!validID) {
      const speakerByName = store.getters['speakers/speakerByName'](speakerID)
      if (speakerByName) {
        redirect(siteSpeakerUrl(speakerByName.speakerID))
      }
    }
  }
  if (!validID) {
    error({
      statusCode: 404,
      message: 'Speaker not found',
    })
  }
}
