import { Context } from '@nuxt/types'
import { promotionRootUrl } from '~/assets/ts/utils/urls'
import { HelpPage } from '~/assets/ts/utils/promotions'
import { getStrValuesFromEnum } from '~/assets/ts/enums'

export default function (context: Context) {
  const page = context.route.params.helpPage
  if (getStrValuesFromEnum(HelpPage).includes(page)) return
  context.redirect(promotionRootUrl)
}
