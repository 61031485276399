
import Vue, { PropType } from 'vue'
import { TranslateResult } from 'vue-i18n'
import SiteLoadingIndicator from '~/components/site/LoadingIndicator.vue'

export default Vue.extend({
  name: 'ScrollList',
  components: { SiteLoadingIndicator },
  props: {
    /**
     * This uses the container itself for the scrolling events, rather than the page body.
     * This is primarily used for things like modals.
     * */
    scrollSelf: {
      type: Boolean,
    },
    noIndicator: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
    },
    duplicationProtection: {
      type: Boolean,
      default: true,
    },
    parentType: {
      type: String,
      default: 'ul',
    },
    classes: {
      type: String,
      default: '',
    },
    loadingClasses: {
      type: String,
      default: '',
    },
    buffer: {
      type: Number,
      default: 700,
    },
    maxListLength: {
      type: Number,
      default: 500,
    },
    list: {
      type: Array,
      default() {
        return []
      },
    },
    endOfList: {
      type: Boolean,
      required: true,
    },
    noResultsText: {
      type: String as PropType<TranslateResult>,
      default(): TranslateResult {
        return this.$t('No Results')
      },
    },
    endOfListText: {
      type: String as PropType<TranslateResult>,
      default(): TranslateResult {
        return this.$t("That's Everything!")
      },
    },
  },
  data() {
    return {
      scrolled: false,
      pending: false,
      scrollTimeout: 0,
      fetching: false,
      originalListLength: undefined as undefined | number,
    }
  },
  fetch() {
    // This is here to keep pages from duplicating their calls when navigating around the site.
    // If the page uses `v-if` on the ScrollList, this code will cause an issue.
    if (this.$isClient && this.duplicationProtection) {
      this.fetching = true
    }
  },
  computed: {
    nonPlaceholderList(): any[] {
      return this.list?.filter((i) => i !== undefined) ?? []
    },
    // This checks to see if our list has been modified/scrolled at all.
    // If not then we don't want to show the end of list text.
    canShowEndText(): boolean {
      if (!this.originalListLength) return false
      return this.originalListLength !== this.nonPlaceholderList.length
    },
    noResults(): boolean {
      if (!this.endOfList) return false
      return (
        this.list.filter((item) => {
          return item !== undefined
        }).length < 1
      )
    },
    scrollElement(): Window | Element {
      return this.scrollSelf ? this.$el : window
    },
    nonScrollBuffer(): number {
      return this.buffer * 0.5
    },
  },
  watch: {
    list() {
      this.pending = false
      this.fetching = false
      // if the list has been updated with real items,
      // check to see if we need to fetch more because we have more window to fill
      if (this.list.filter((i) => i !== undefined).length) {
        this.checkNonScrolling()
        this.scrolled = true
      }
    },
  },
  destroyed() {
    this.scrollElement.removeEventListener('scroll', this.checkScrolling)
    window.clearTimeout(this.scrollTimeout)
  },
  mounted() {
    this.scrollElement.addEventListener('scroll', this.checkScrolling)
    this.checkNonScrolling()
  },
  methods: {
    bottomOfWindow(buffer: number): boolean {
      if (this.scrollSelf) {
        const el = this.scrollElement as Element
        const height = el.scrollHeight - el.clientHeight - buffer
        return el.scrollTop >= height
      } else {
        const max = Math.max(
          window.scrollY,
          document.documentElement.scrollTop,
          document.body.scrollTop
        )
        return (
          max + window.innerHeight >=
          document.documentElement.offsetHeight - buffer
        )
      }
    },
    checkNonScrolling() {
      this.checkList(this.nonScrollBuffer)
    },
    checkScrolling() {
      if (this.disabled) return
      this.checkList(this.buffer)
    },
    checkList(buffer: number) {
      if (this.originalListLength === undefined) {
        // Set it to the length of the original list of non-placeholders
        this.originalListLength = this.nonPlaceholderList.length
      }
      if (this.endOfList) return
      if (this.list.length >= this.maxListLength) return
      if (this.pending) return
      if (this.fetching) return
      window.clearTimeout(this.scrollTimeout)
      this.scrollTimeout = window.setTimeout(() => {
        if (this.bottomOfWindow(buffer ?? this.buffer)) {
          this.pending = true
          this.$emit('fetch')
        }
      }, 100)
    },
  },
})
