import { LatLngLiteral } from 'leaflet'
import {
  ApiCacheTTL,
  ApiClient,
  ApiPaginationResult,
} from '~/apiclient/apiclient'
import { BroadcasterSoloSite } from '~/models/broadcaster'
import { DisplayUserApi } from '~/models/usercomment'
import { SermonApi, SermonApiEventType } from '~/apiclient/apisermons'
import { SpeakerApi } from '~/apiclient/apispeakers'
import { SocialAccountLabel } from '~/apiclient/apifilters'
import { SeriesApi } from '~/apiclient/apiseries'

export interface ServiceTimesApi {
  startTime: string
  title: string | null
  serviceTypeID: number | null
  dayOfWeek: number
}

export interface SocialAccountApi {
  label: SocialAccountLabel
  url: string
}

export const BroadcasterAttendanceOptions = [100, 250, 500, 1000, 2000, 3000]

export function BroadcasterAttendanceDisplay(count: number | undefined) {
  switch (count) {
    case 100: {
      return '1-100'
    }
    case 250: {
      return '100-250'
    }
    case 500: {
      return '250-500'
    }
    case 1000: {
      return `500-${(1000).toLocaleString()}`
    }
    case 2000: {
      return `${(1000).toLocaleString()}-${(2000).toLocaleString()}`
    }
    case 3000: {
      return `${(2000).toLocaleString()}+`
    }
    default: {
      return ''
    }
  }
}

export enum BroadcasterDonationMethod {
  Cornerstone = 'cornerstone',
  Paypal = 'paypal',
  Stripe = 'stripe',
  Mail = 'mail',
  Other = 'other',
}

export interface BroadcasterDonationOption {
  method: BroadcasterDonationMethod
  label?: string | null
  email?: string | null
  url?: string | null
}

export interface BroadcasterApi {
  type: string
  lite_type?: string
  broadcasterID: string
  displayName: string
  shortName: string
  location: string
  country: string
  countryISO: string
  churchSize: number
  minister: string
  facebookUsername?: string
  twitterUsername?: string
  homePageURL?: string | null
  latitude: number
  longitude: number
  latLng: LatLngLiteral
  imageURL: string
  imageURLResizable: string
  joined: number
  numberOfSermons?: string
  albumArtURL: string
  bibleVersion?: string
  aboutUs?: string
  address?: string
  addressMail?: string
  serviceTimes?: string
  serviceTimesArePreformatted: boolean
  serviceTimesList?: ServiceTimesApi[]
  denomination?: string
  canWebcast: boolean
  webcastInProgress: boolean
  phone?: string
  listenLineNumber?: string
  vacantPulpit: boolean
  welcomeVideoID?: string | null
  idCode: string
  email: string
  languageCode: string
  rssFeedAtomURL: string
  rssFeedURL: string
  followed: boolean
  soloSiteDisabled: boolean
  soloSite: BroadcasterSoloSite
  categories: number
  primarySpeaker: SpeakerApi
  primarySpeakers?: SpeakerApi[] | number[]
  socialAccounts?: SocialAccountApi[] | null
  lite: boolean
  disabled: boolean
  bannerImageURL?: string
  groups?: string[]
  donatable?: boolean
  donationOptions?: BroadcasterDonationOption[] | null
}

/** In the future this could have more options */
export enum BroadcasterInclude {
  /** Adds service times, groups, social accounts, etc */
  All = 'all',
}

export interface GetBroadcasterOptions {
  lite?: boolean
  bypassCache?: boolean
  cacheTTL?: ApiCacheTTL
  /** Adds specified options to the returned broadcaster object. */
  include?: BroadcasterInclude
}

export async function getBroadcaster(
  this: ApiClient,
  broadcasterID: string,
  params: GetBroadcasterOptions = {}
): Promise<BroadcasterApi> {
  params.lite = params.lite ?? true
  const { data } = await this.$axios.get(`node/broadcasters/${broadcasterID}`, {
    params,
  })
  return data
}

export class BroadcasterSeriesRequestOptions {
  page = 1
  pageSize = 25
  sort_by?: string
}

export async function getAllBroadcasterSeries(
  this: ApiClient,
  broadcasterID: string,
  options: Partial<BroadcasterSeriesRequestOptions>
): Promise<ApiPaginationResult<SeriesApi>> {
  const { data } = await this.$axios.get(
    `node/broadcasters/${broadcasterID}/series`,
    {
      params: { ...options },
    }
  )

  return data
}

export interface apiBroadcasterGroup {
  broadcasterCount: number
  name: string
}

export async function getBroadcasterGroups(
  this: ApiClient
): Promise<apiBroadcasterGroup[]> {
  const { data } = await this.$axios.get(`node/discover/legacy_groups/`)
  return data.results
}

export enum BroadcasterFilterOptions {
  NewestSermonUpload = 'newest-sermons-upload',
  NewestMetadataUpdate = 'newest-metadata-update',
}

export type BroadcasterFieldOption = 'joined'

export class BroadcasterRequestOptions {
  page = 1
  pageSize = 1
  searchKeyword?: string
  featuredOnly = false
  newestFirst = false
  sortBy?: BroadcasterFilterOptions
  displayNameStartsWithLetter?: string
  group?: string
  onlySchool = false
  onlySeminary = false
  onlyPodcast = false
  onlyInternational = false
  vacantPulpit = false
  country?: string
  state?: string
  // Takes an array of field names that will be populated on the broadcaster lite objects
  include?: BroadcasterFieldOption[]
}

export async function getBroadcasterList(
  this: ApiClient,
  options: Partial<BroadcasterRequestOptions>
): Promise<ApiPaginationResult<BroadcasterApi>> {
  const { data } = await this.$axios.get(`node/discover/broadcasters`, {
    params: {
      ...options,
    },
  })

  return data
}

export interface StateApi {
  name: string
  count: number
}

export interface IsoState extends StateApi {
  iso: string
}

export interface CountryApi {
  name: string
  displayName?: string
  count: number
}

export interface IsoCountry extends CountryApi {
  iso: string
}

export interface BroadcasterLocationsApi {
  international: Record<string, CountryApi>
  usa: Record<string, StateApi>
}

export async function getBroadcasterLocations(
  this: ApiClient
): Promise<BroadcasterLocationsApi> {
  const { data } = await this.$axios.get(`node/discover/broadcaster_locations`)
  return data
}

export enum BroadcasterUserRoles {
  Admin = 2,
  Uploader = 3,
}

export interface BroadcasterUserRoleApi {
  userID: number
  roleID: BroadcasterUserRoles
  displayUser?: DisplayUserApi
}
export interface BroadcasterEmailsApi {
  mainEmail: string
  publicEmail: string
  encodingEmail: string
  ordersEmail: string
  storeEmail: string
  eventsEmail: string
  pastorEmail: string
}

export interface BroadcasterPrivateDataApi {
  bannerImageID?: number
  contacts: BroadcasterEmailsApi
  disableInsiderNewsletter: boolean
  disablePowerclipNewsletter: boolean
  disableWeeklyNewsletter: boolean
  dropboxFolder?: string
  ftpFolder?: string
  includePrimarySpeakerSermons: boolean
  streamTokenID?: string
  webcastIsPublic: boolean
  webcastKilled: boolean
  includeEmailInPodcast: boolean
  autoTranscribe: boolean
}

export interface BroadcasterPatchParams
  extends BroadcasterPrivateDataApi,
    BroadcasterApi {
  userRoles?: BroadcasterUserRoleApi[]
  isChurch?: boolean
  isSchool?: boolean
  isSeminary?: boolean
  isRadioBroadcast?: boolean
  /** used exclusively to remove a profile image */
  image?: string | null
}

export async function patchBroadcaster(
  this: ApiClient,
  broadcasterID: string,
  params: Partial<BroadcasterPatchParams>
): Promise<void> {
  await this.$axios.patch(`node/broadcasters/${broadcasterID}`, params)
}

export async function broadcasterUserRoles(
  this: ApiClient,
  broadcasterID: string
): Promise<BroadcasterUserRoleApi[]> {
  const { data } = await this.$axios.get(
    `node/broadcasters/${broadcasterID}/user_roles`
  )
  return data
}

export interface BroadcasterUserInvitesApi {
  email: string
  roleIDs: BroadcasterUserRoles[]
}

export async function broadcasterUserInvites(
  this: ApiClient,
  broadcasterID: string
): Promise<BroadcasterUserInvitesApi[]> {
  const { data } = await this.$axios.get(
    `node/broadcasters/${broadcasterID}/user_invites`
  )
  return data
}

export async function sendBroadcasterUserInvite(
  this: ApiClient,
  broadcasterID: string,
  params: BroadcasterUserInvitesApi
): Promise<BroadcasterUserInvitesApi[]> {
  const { data } = await this.$axios.post(
    `node/broadcasters/${broadcasterID}/user_invites/`,
    params
  )
  console.log('sendBroadcasterUserInvite', data)
  return data
}

export async function resendBroadcasterUserInvite(
  this: ApiClient,
  broadcasterID: string,
  email: string
): Promise<BroadcasterUserInvitesApi[]> {
  const { data } = await this.$axios.post(
    `node/broadcasters/${broadcasterID}/user_invites/${email}/resend`
  )
  console.log('resendBroadcasterUserInvite', data)
  return data
}

export async function deleteBroadcasterUserInvite(
  this: ApiClient,
  broadcasterID: string,
  email: string
): Promise<BroadcasterUserInvitesApi[]> {
  const { data } = await this.$axios.delete(
    `node/broadcasters/${broadcasterID}/user_invites/${email}`
  )
  console.log('deleteBroadcasterUserInvite', data)
  return data
}

export enum BroadcasterHighlightedSermonsSortOrder {
  Preached = 'preached',
  Popular = 'popular',
  Added = 'added',
  NewestPicks = 'picklistnewest',
  RandomPicks = 'picklistrandom',
  NewestClips = 'clipsnewest',
  RandomClips = 'clipsrandom',
  Default = 'added',
}

export enum BroadcasterHighlightedAudioInputTypes {
  RandomSermon = 'randomsource',
  NewestSermon = 'newest',
  RandomPick = 'randompick',
  NewestPick = 'newestpick',
  SermonID = 'sermon_id',
  EventType = 'event_type',
}

export interface BroadcasterHighlightedSermonsParams {
  sortOrder?: BroadcasterHighlightedSermonsSortOrder
  audioType?: BroadcasterHighlightedAudioInputTypes | null
  audioSermonID?: string | null
  audioEventType?: SermonApiEventType | null
}

export interface BroadcasterHighlightedSermonsApi {
  sortOrder: BroadcasterHighlightedSermonsSortOrder
  sortTitle: string
  audioSermon: SermonApi | null
  audioTitle: string | null
  audioType: BroadcasterHighlightedAudioInputTypes | null
  audioEventType: SermonApiEventType | null
}

export async function broadcasterHighlightedSermons(
  this: ApiClient,
  broadcasterID: string,
  bypassCache = false
): Promise<BroadcasterHighlightedSermonsApi> {
  const params = {} as Record<any, any>
  /** TODO: remove bypassCache once cacheTTL is fully supported via the API */
  if (bypassCache) {
    params.bypassCache = true
    params.cacheTTL = 0
  }
  const { data } = await this.$axios.get(
    `node/broadcasters/${broadcasterID}/highlighted_sermons`,
    { params }
  )
  return data
}

export async function patchBroadcasterHighlightedSermons(
  this: ApiClient,
  broadcasterID: string,
  params: BroadcasterHighlightedSermonsParams
): Promise<BroadcasterHighlightedSermonsApi> {
  const { data } = await this.$axios.patch(
    `node/broadcasters/${broadcasterID}/highlighted_sermons`,
    params
  )
  return data
}

export async function broadcasterPrivateData(
  this: ApiClient,
  broadcasterID: string
): Promise<BroadcasterPrivateDataApi> {
  const { data } = await this.$axios.get(
    `node/broadcasters/${broadcasterID}/private_data`
  )
  return data
}

export interface BroadcasterBannerApi {
  id: number
  title: string
  url: string
  tags: string
}

export async function broadcasterBannerGallery(
  this: ApiClient
): Promise<ApiPaginationResult<BroadcasterBannerApi>> {
  const { data } = await this.$axios.get('node/images/background/broadcaster')
  return data
}

export async function getBroadcasterBannerUploadUrl(
  this: ApiClient,
  broadcasterID: string
): Promise<string> {
  const upload = await this.$axios.post('/media/images/broadcaster_banner/', {
    broadcasterID,
  })
  return upload.data.uploadURL
}

export async function getBroadcasterImageUploadUrl(
  this: ApiClient,
  broadcasterID: string
): Promise<string> {
  const upload = await this.$axios.post('/media/images/broadcaster_profile/', {
    broadcasterID,
  })
  return upload.data.uploadURL
}

export interface SermonCountsApi {
  count: number
  speaker: string
  type: 'sermon_counts'
}

export async function speakerSermonCountsForBroadcaster(
  this: ApiClient,
  broadcasterID: string
): Promise<ApiPaginationResult<SermonCountsApi>> {
  const { data } = await this.$axios.get(
    `node/broadcasters/${broadcasterID}/speakers/sermon_counts`,
    {
      params: {
        includeUnpublished: true,
      },
    }
  )
  return data
}

export async function speakersForBroadcasters(
  this: ApiClient,
  broadcasterID: string
): Promise<ApiPaginationResult<SpeakerApi>> {
  const { data } = await this.$axios.get('node/speakers', {
    params: {
      broadcasterID,
      includeUnpublished: true,
    },
  })
  return data
}

export interface ApiKeyApi {
  type: 'api_key'
  key: string
  apiType: string
}

export async function getBroadcasterApiKey(
  this: ApiClient,
  broadcasterID: string
): Promise<ApiKeyApi> {
  const { data } = await this.$axios.get(
    `/node/broadcasters/${broadcasterID}/keyinfo`
  )
  return data
}
export interface BroadcasterNoticesApi {
  type: 'notice'
  id: number
  timestamp: number
  title: string
  body: string
  disabled: boolean
  broadcaster: BroadcasterApi
}

export interface BroadcasterNoticesParams {
  page?: number
  pageSize?: number
}

export async function getBroadcasterNotices(
  this: ApiClient,
  broadcasterID: string,
  params: BroadcasterNoticesParams = {
    pageSize: 10,
  }
): Promise<ApiPaginationResult<BroadcasterNoticesApi>> {
  const { data } = await this.$axios.get(
    `/node/broadcasters/${broadcasterID}/notices`,
    { params }
  )
  return data
}

// We shouldn't actually need this
// export interface BroadcasterOptionsItemApi {
//   value: 'true' | 'false'
//   comments?: string
// }
//
// export interface BroadcasterOptionsApi {
//   broadcaster_id: string
//   options: Record<'auto-transcribe', BroadcasterOptionsItemApi>
// }
//
// export async function getBroadcasterOptions(
//   this: ApiClient,
//   broadcasterID: string
// ): Promise<BroadcasterOptionsApi> {
//   const { data } = await this.$axios.get(
//     `/node/broadcasters/${broadcasterID}/options`
//   )
//   return data
// }
