import { RouteConfig } from 'vue-router'
import { RewriteConfig, RewriteKey, RewriteTypes } from './rewritetypes'

// Strip out path prefixes from matching routes for custom domains
export function matchingRoutes(
  rewriteType: RewriteKey,
  routes: RouteConfig[]
): RouteConfig[] {
  const config: RewriteConfig = RewriteTypes[rewriteType]
  if (!config) {
    console.log(`Error: Unimplemented rewrite type "${rewriteType}"`)
    return routes
  }

  // noinspection UnnecessaryLocalVariableJS
  const filteredRoutes = routes
    // Include only routes whose names start with the specified routeName
    .filter((r: RouteConfig) => (r.name as string).startsWith(config.routeName))
    .map((r: RouteConfig) => {
      // Remove the specified path from the route path
      const newRoute = { ...r } // modify a copy of original route
      newRoute.path = newRoute.path
        .replace(`${config.path}?`, config.path)
        .replace(`${config.path}/`, '/')
        .replace(config.path, '/')
      return newRoute
    })
  return filteredRoutes
}
