
import Vue, { PropType } from 'vue'
import { TranslateResult } from 'vue-i18n'
import CustomDropdown, { CustomDropdownProps } from './CustomDropdown.vue'
import KeydownEvent from '~/models/generic/KeydownEvent'
import { SaIconsType } from '~/assets/ts/types/icons'
import InlineIcon from '~/components/_general/InlineIcon.vue'
import { DropdownNullDisplay } from '~/components/_general/Dropdown.vue'

import SaImage from '~/components/_general/SaImage.vue'
import KeydownObserver from '~/components/_general/KeydownObserver.vue'

export interface FancyDropdownOption {
  value: string
  title: TranslateResult
  subtitle?: TranslateResult
  imageURL?: string
  roundImage?: boolean
}

export function NullableFancyDropdownOptions(
  options: FancyDropdownOption[],
  nullValue: any = ''
) {
  return [
    { value: nullValue, title: DropdownNullDisplay },
    ...options,
  ] as FancyDropdownOption[]
}

export default Vue.extend({
  name: 'FancyDropdown',
  components: { KeydownObserver, SaImage, CustomDropdown, InlineIcon },
  model: {
    prop: 'currentValue',
    event: 'change',
  },
  props: {
    ...CustomDropdownProps,
    // must be a translated string that includes the {selection} parameter
    selectionText: {
      type: String as PropType<TranslateResult>,
      default: '',
      validator(value: TranslateResult) {
        if (!value) return true
        return value.toString().includes('{selection}')
      },
    },
    customText: {
      type: String as PropType<TranslateResult>,
      default: '',
    },
    currentValue: {
      type: String,
      default: undefined,
    },
    options: {
      type: Array as PropType<FancyDropdownOption[]>,
      required: true,
    },
    icon: {
      type: String as PropType<SaIconsType>,
      default: '',
    },
    imageWidth: {
      type: Number,
      default: 96,
    },
  },
  data() {
    return {
      opened: false,
    }
  },
  computed: {
    currentIndex(): number {
      return this.options.findIndex((o) => o.value === this.currentValue)
    },
    currentOption(): FancyDropdownOption | undefined {
      return this.options.find((o) => o.value === this.currentValue)
    },
    currentTitle(): TranslateResult {
      return this.currentOption?.title ?? ''
    },
    title(): TranslateResult {
      if (this.customText) return this.customText
      return this.$t(this.selectionText.toString(), {
        selection: this.currentTitle,
      })
    },
  },
  destroyed() {
    this.$off('open', this.open)
  },
  mounted() {
    this.$on('open', this.open)
  },
  methods: {
    keydown(key: KeydownEvent) {
      if (key.Escape) {
        this.close()
      } else if (key.ArrowUp) {
        this.focusSibling(key.event)
      } else if (key.ArrowDown) {
        this.focusSibling(key.event, true)
      }
    },
    focusSibling(event: KeyboardEvent, next = false) {
      event.preventDefault()
      const options = this.$refs.options as (HTMLElement | undefined)[]
      let el = event.target as HTMLElement | undefined
      let selectNext = true
      if (options && options.length && !options.includes(el)) {
        if (this.currentIndex === -1) {
          selectNext = false
          el = options[0]
        } else {
          el = options[this.currentIndex]
        }
      }
      if (el && selectNext) {
        el = (next ? el.nextElementSibling : el.previousElementSibling) as
          | HTMLElement
          | undefined
      }
      if (!el) return
      el.focus()
    },
    change(option: FancyDropdownOption) {
      this.$emit('change', option.value)
      this.close()
    },
    close() {
      const dropdown = this.$refs.dropdown as Vue
      dropdown.$emit('close')
    },
    open() {
      const dropdown = this.$refs.dropdown as Vue
      dropdown.$emit('open')
    },
    openedEvent(open: boolean) {
      this.opened = open
      this.$emit(open ? 'opened' : 'closed')
    },
  },
})
