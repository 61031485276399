
import Vue, { PropType } from 'vue'
import { enumNumToStr, TailwindBreakpoint } from '~/assets/ts/enums'

export default Vue.extend({
  name: 'NavigationTab',
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    breakpoint: {
      type: Number as PropType<TailwindBreakpoint> | undefined,
      default: undefined,
    },
    inactiveColor: {
      type: String,
      default: 'text-current',
    },
    activeColor: {
      type: String,
      default: 'text-theme-blue',
    },
  },
  computed: {
    breakpointString(): string {
      if (!this.breakpoint) return 'no-break'
      return `break-${enumNumToStr(TailwindBreakpoint, this.breakpoint)}`
    },
  },
})
