import Vue from 'vue'
import { DateFormat } from '~/assets/ts/enums'
import { localizeDateTime } from '~/assets/ts/utils/date'

export default () => {
  function localize(value: Date | undefined, format: DateFormat) {
    if (!value) return ''
    return localizeDateTime(value, format)
  }

  Vue.filter('date', (value: Date | undefined) =>
    localize(value, DateFormat.Date)
  )
  Vue.filter('time', (value: Date | undefined) =>
    localize(value, DateFormat.Time)
  )
  Vue.filter('dateTime', (value: Date | undefined) =>
    localize(value, DateFormat.DateTime)
  )
  Vue.filter('shortDate', (value: Date | undefined) =>
    localize(value, DateFormat.ShortDate)
  )
  Vue.filter('shortDateTime', (value: Date | undefined) =>
    localize(value, DateFormat.ShortDateTime)
  )
  Vue.filter('monthDayDate', (value: Date | undefined) =>
    localize(value, DateFormat.MonthDayDate)
  )
}
