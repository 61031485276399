import { Context } from '@nuxt/types'
import Vue from 'vue'
import { Route } from 'vue-router'

export type AnyContext = Vue | Context

export function GetContextRoute(context: AnyContext) {
  const c = context as any
  return (c.$route ?? c.route) as Route | undefined
}
