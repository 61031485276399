
import Vue from 'vue'
import { marked } from 'marked'
import {
  MarkdownClasses,
  MarkdownProps,
  SetupMarkdown,
} from '~/assets/ts/markdown/renderer'

export default Vue.extend({
  name: 'MarkdownElement',
  props: {
    ...MarkdownProps,
  },
  computed: {
    text(): string {
      SetupMarkdown(this.$props)
      return marked(this.body.toString())
    },
    fullClasses(): string {
      return MarkdownClasses(this.$props)
    },
  },
})
