import https from 'https'
import http from 'http'
import { Context } from '@nuxt/types'
import axios, { AxiosRequestConfig } from 'axios'
import { stringify } from 'qs'
import { DateFormat } from '~/assets/ts/enums'
import { localizeDateTime } from '~/assets/ts/utils/date'

const httpAgent = new http.Agent({ keepAlive: true })
const httpsAgent = new https.Agent({ keepAlive: true })

export default function ({ $axios, isDev, route, store }: Context) {
  const client = process.browser
  if (!$axios.defaults.httpAgent) {
    $axios.defaults.httpAgent = httpAgent
  }
  if (!$axios.defaults.httpsAgent) {
    $axios.defaults.httpsAgent = httpsAgent
  }

  $axios.defaults.paramsSerializer = (params) => {
    return stringify(params, { arrayFormat: 'repeat' })
  }

  const apibranch = route.query.apibranch
  if (apibranch) {
    // Override API URL
    const overrideAPIURL = `https://api-${apibranch}.review.sermonaudio.com/v2`
    console.log(`API URL Override detected: now using ${overrideAPIURL}`)
    $axios.setBaseURL(overrideAPIURL)
  }

  const apiKey = client ? store.getters.apiKey : process.env.SAWEB_API_KEY

  // If it's a dev environment, the server, or "debug=true" is passed in to the url, then we show these api logs
  const showLogs = isDev || route.query.debug || !client
  $axios.onRequest((config) => {
    config.headers.common = {
      ...config.headers.common,
      timestamp: new Date().getTime(),
      'X-API-Key': apiKey,
    }
    if (!showLogs) return
    console.log(
      `${timestamp()} ${config.method?.toUpperCase()} ${
        config.baseURL
      }/${fullUrl(config)}`
    )
  })
  if (!showLogs) return
  $axios.onResponse((response) => {
    const config = response.config
    const time = ms(config)
    const log = `%c${ms(config)}ms`
    const logStyle = 'font-weight: bold'
    if (time > 500) {
      console.warn(log, logStyle, fullUrl(config))
    } else {
      console.log(log, logStyle, fullUrl(config))
    }
  })
  $axios.onError((error) => {
    const config = error.config
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      if (error.response.status === 401 && !apiKey) {
        error.message =
          'Something went wrong while retrieving this page. Please try again.'
      }
      console.error(
        `${timestamp()} (${ms(config)}ms) Response ${fullUrl(
          config
        )} failed with status ${error.response.status} ${
          error.response.statusText
        }`,
        error.response.data ?? ''
      )
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.error(
        `${timestamp()} Request ${fullUrl(
          config
        )} received no response. Details: \n${error.request}`
      )
    } else {
      // Something happened in setting up the request that triggered an Error
      console.error(
        `${timestamp()} Request ${fullUrl(config)} failed with error: ${
          error.message
        }`
      )
    }
    // console.log(error.config)
  })
}

// any is a temporary override because the package is broken
// https://github.com/axios/axios/issues/5494
function ms(config: AxiosRequestConfig | any) {
  const timestamp = config.headers?.timestamp as number | undefined
  return new Date().getTime() - (timestamp ?? 0)
}

// any is a temporary override because the package is broken
// https://github.com/axios/axios/issues/5494
function fullUrl(config: AxiosRequestConfig | any): string {
  if (!axios || !config) return 'Unknown Url'
  return axios.getUri(config)
}

function timestamp() {
  return localizeDateTime(new Date(), DateFormat.DetailedTime)
}
