
import Vue, { PropType } from 'vue'
import { QsPair, qsPairAsObj } from '~/assets/ts/utils/params'

export default Vue.extend({
  name: 'SaLink',
  props: {
    button: {
      type: Boolean,
    },
    relative: {
      type: Boolean,
    },
    to: {
      type: String,
      default: '',
    },
    hash: {
      type: String,
      default: '',
    },
    query: {
      type: Array as PropType<QsPair[]>,
      default: () => [],
    },
    prefetch: {
      type: Boolean,
    },
  },
  computed: {
    relativePath(): string {
      // there are instances where it ends up with urls like /es//my-url, and
      // I'm not entirely certain why, but this should fix any instance of that
      return `${this.$route.path}${this.to}`.replace('//', '/')
    },
    absolutePath(): string {
      return this.localePath(this.to)
    },
    path(): any {
      const qs = qsPairAsObj(this.query)
      const query = this.relative ? { ...this.$route.query, ...qs } : qs
      if (this.relative) {
        this.query.forEach((q) => {
          if (!q.value) {
            delete query[q.key]
          }
        })
      }
      return {
        query,
        path: this.relative ? this.relativePath : this.absolutePath,
        hash: this.hash ? `#${this.hash}` : '',
      }
    },
  },
})
