import { waitOneFrame } from '~/assets/ts/utils/misc'

// These are page parameters that are treated like tabs and should keep the page
// from scrolling to the top when they're clicked
// We may want to unify these at some point instead of creating this bespoke array
const tabParams = ['tab', 'feedType', 'following', 'adType']

// https://v3.router.vuejs.org/guide/advanced/scroll-behavior.html#async-scrolling
export default async function (to, from, savedPosition) {
  await waitOneFrame()

  // we're going back, so restore the saved position
  if (savedPosition) {
    return savedPosition
  }
  // smooth scroll to our hash
  if (to.hash) {
    const el = document.querySelector(to.hash)
    if (el) {
      const offset = el.dataset.hashOffset ? parseInt(el.dataset.hashOffset) : 0
      return {
        selector: to.hash,
        behavior: 'smooth',
        offset: { x: 0, y: offset },
      }
    }
  }

  // TODO: look into if there's a meta condition we can provide instead
  // https://github.com/vuejs/vue-router/blob/dev/examples/scroll-behavior/app.js

  // skip scroll top when clicking on a page tab
  if (to.name === from.name) {
    // Navigated to the same route, so we need to check for tabs
    const params = Object.keys(to.params)
    if (params.length > 0 && params.some((p) => tabParams.includes(p))) {
      // our new page has a tab parameter
      const match = params.every((p) => {
        if (tabParams.includes(p)) return true // Ignore
        return to.params[p] === from.params[p] // Compare values for all other keys
      })
      if (match) {
        // our parameters (except the tab) match, so we skip the scroll to the top
        return false
      }
    }
  }

  // iOS safari has a bug in Single Tab mode that causes the page to frequently
  // not scroll completely to the top
  // Adding a short delay and a negative offset fixes it, but is a subpar solution
  // if (window.$nuxt.$device.isIos) {
  //   await wait(150)
  //   return { x: 0, y: -50 }
  // }

  return { x: 0, y: 0 } // Go to the top of the page
}
