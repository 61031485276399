
import Vue, { PropType } from 'vue'
import { AdType, UserAdApi } from '~/apiclient/apiads'
import SiteButton from '~/components/site/Button.vue'
import InlineIcon from '~/components/_general/InlineIcon.vue'
import SitePromotionalAdStatus from '~/components/site/promotional/AdStatus.vue'
import { AdStatus, GetAdStatus } from '~/assets/ts/utils/promotions'
import { AdPage, promotionUrl } from '~/assets/ts/utils/urls'
import LoadingElement from '~/components/_general/LoadingElement.vue'
import SaIcon from '~/components/_general/SaIcon.vue'
import { abbreviateNumber } from '~/assets/ts/utils/math'
import SitePromotionalAdListPreview from '~/components/site/promotional/AdListPreview.vue'
import SitePromotionalPurchasedBy from '~/components/site/promotional/PurchasedBy.vue'

export default Vue.extend({
  name: 'SitePromotionalAdListElement',
  components: {
    SitePromotionalPurchasedBy,
    SitePromotionalAdListPreview,
    SaIcon,
    LoadingElement,
    SitePromotionalAdStatus,
    InlineIcon,
    SiteButton,
  },
  props: {
    ad: {
      type: Object as PropType<UserAdApi>,
      default: undefined,
    },
    adType: {
      type: String as PropType<AdType>,
      default: undefined,
    },
    showCost: {
      type: Boolean,
    },
    dateOnly: {
      type: Boolean,
    },
  },
  computed: {
    SiteButton() {
      return SiteButton
    },
    hasStats(): boolean {
      return !!this.ad && !this.scheduled
    },
    scheduled(): boolean {
      return this.adStatus === 'scheduled'
    },
    adStatus(): AdStatus {
      return GetAdStatus(this.ad)
    },
    editLink(): string {
      return promotionUrl(this.adType, AdPage.Edit, this.ad?.campaignID)
    },
    statsLink(): string {
      return promotionUrl(this.adType, AdPage.AdStats, this.ad?.campaignID)
    },
    cost(): string {
      if (!this.showCost || !this.ad) return ''
      return parseInt(this.ad.price).toString()
    },
    impressions(): string {
      return this.formatNumber(this.ad?.totalImpressions, '---')
    },
    clicks(): string {
      return this.formatNumber(this.ad?.totalClicks, '0')
    },
  },
  methods: {
    formatNumber(value: number | undefined, placeholder: string): string {
      return value
        ? abbreviateNumber(value, value >= 100000 ? 0 : 1)
        : placeholder
    },
  },
})
