import { render, staticRenderFns } from "./PurchasedBy.vue?vue&type=template&id=3712a498&scoped=true&"
import script from "./PurchasedBy.vue?vue&type=script&lang=ts&"
export * from "./PurchasedBy.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3712a498",
  null
  
)

export default component.exports