import { Context } from '@nuxt/types'
import {
  BroadcasterSignIn,
  GetBroadcasterSignIn,
} from '~/assets/ts/utils/broadcaster'
import { SelectBroadcaster } from '~/assets/ts/utils/dashboard'
import {
  AssembleCookie,
  LinkedBroadcasterCookie,
} from '~/assets/ts/utils/cookies'

export default function (context: Context) {
  const info = GetBroadcasterSignIn(context)
  const broadcasterID = info?.broadcasterID ?? ''
  if (info.signIn === BroadcasterSignIn.Dashboard) {
    if (!process.server) {
      SelectBroadcaster(broadcasterID, '')
    } else {
      context.res.setHeader('Set-Cookie', [
        AssembleCookie(LinkedBroadcasterCookie, broadcasterID),
      ])
    }
  }
  const page = decodeURIComponent(context.route.path)
  const url = info.url.split('?')[0]
  const match = url === page || url.includes(page) || page.includes(url)
  if (!match) return context.redirect(info.url)
}
