
import Vue from 'vue'
import CustomScroll from '~/components/_general/CustomScroll.vue'
import FocusVisibleElement from '~/components/_general/FocusVisibleElement.vue'

export const CustomDropdownProps = {
  clickable: {
    type: Boolean,
    default: true,
  },
  animate: {
    type: Boolean,
  },
  up: {
    type: Boolean,
  },
  contentClasses: {
    type: String,
    default: '',
  },
  targetClasses: {
    type: String,
    default: '',
  },
  useTheme: {
    type: Boolean,
    default: true,
  },
  checkFocus: {
    type: Boolean,
  },
  left: {
    type: Boolean,
  },
  hover: {
    type: Boolean,
  },
  clickaway: {
    type: Boolean,
    default: true,
  },
  scrollaway: {
    type: Boolean,
    default: true,
  },
}

export default Vue.extend({
  name: 'CustomDropdown',
  components: { CustomScroll },
  props: {
    ...CustomDropdownProps,
  },
  data() {
    return {
      isActive: false,
      opened: false,
      leftPos: 0,
    }
  },
  computed: {
    componentType(): Record<any, any> | string {
      return this.checkFocus ? FocusVisibleElement : 'button'
    },
    leftAlign(): boolean {
      return this.left || this.leftPos < 0
    },
    clickableTargetClasses(): string {
      let classes = this.clickable ? 'cursor-pointer' : 'pointer-events-none'
      if (this.targetClasses) {
        classes += ' ' + this.targetClasses
      }
      return classes
    },
    useHover(): boolean {
      return this.hover && !this.$device.isMobileOrTablet
    },
  },
  destroyed() {
    this.$off('close', this.closeDropdown)
    this.$off('open', this.openDropdown)
    this.$eventBus.$off('closeDropdowns', this.closeDropdown)
    if (!this.scrollaway) return
    window.removeEventListener('scroll', this.onScroll)
  },
  mounted() {
    this.$on('close', this.closeDropdown)
    this.$on('open', this.openDropdown)
    this.$eventBus.$on('closeDropdowns', this.closeDropdown)
    if (!this.scrollaway) return
    window.addEventListener('scroll', this.onScroll)
  },
  methods: {
    onScroll() {
      if (this.$device.isMobileOrTablet) return
      this.closeDropdown()
    },
    openDropdown() {
      if (this.isActive) return
      this.leftPos = 0
      this.opened = true
      // Timeout is to keep the clickaway event from interfering
      setTimeout(() => {
        this.$emit('opened')
        this.isActive = true
        const el = (this.$refs.dropdown as any).$el as HTMLElement
        if (el) {
          this.leftPos = el.getBoundingClientRect().left
        }
      }, 1)
    },
    click() {
      if (this.useHover) return
      this.openDropdown()
    },
    closeDropdown() {
      if (!this.isActive) return
      this.isActive = false
      this.$emit('closed')
    },
    closeEvent() {
      if (this.useHover) return
      this.closeDropdown()
    },
    clickawayEvent() {
      if (!this.clickaway) return
      this.closeEvent()
    },
    hoverEnter() {
      if (!this.useHover) return
      this.openDropdown()
    },
    hoverExit() {
      if (!this.useHover) return
      this.closeDropdown()
    },
  },
})
