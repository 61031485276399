import { PropType } from 'vue'
import { TranslateResult } from 'vue-i18n'
import { marked } from 'marked'
import { validUrlFormat } from '~/assets/ts/utils/validation'
import { MarkedPlayerExtension } from '~/assets/ts/markdown/player'
import {
  CustomImageRenderer,
  MarkdownImageTokenizer,
} from '~/assets/ts/markdown/image'

export const MarkedExtensions = {
  extensions: [MarkdownImageTokenizer, MarkedPlayerExtension],
}

function plaintext(options = {} as Record<any, any>): marked.Renderer {
  const r = new marked.Renderer()
  const whitespace = options.spaces ? ' ' : '\n'
  r.code = function (code, _lang, _escaped) {
    return whitespace + whitespace + code + whitespace + whitespace
  }
  r.blockquote = function (quote) {
    return '\t' + quote + whitespace
  }
  r.html = function (html) {
    return html
  }
  r.heading = function (text, _level, _raw) {
    return text
  }
  r.hr = function () {
    return whitespace + whitespace
  }
  r.list = function (body, _ordered) {
    return body
  }
  r.listitem = function (text) {
    return '\t' + text + whitespace
  }
  r.paragraph = function (text) {
    return whitespace + text + whitespace
  }
  r.table = function (header, body) {
    return whitespace + header + whitespace + body + whitespace
  }
  r.tablerow = function (content) {
    return content + whitespace
  }
  r.tablecell = function (content, _flags) {
    return content + '\t'
  }
  r.strong = function (text) {
    return text
  }
  r.em = function (text) {
    return text
  }
  r.codespan = function (text) {
    return text
  }
  r.br = function () {
    return whitespace + whitespace
  }
  r.del = function (text) {
    return text
  }
  r.link = function (_href, _title, text) {
    return text
  }
  r.image = function (_href, _title, text) {
    return text
  }
  r.text = function (text) {
    return text
  }
  return r
}

// Used to convert HTML symbols to regular characters like &#39; (')
// List over characters: https://ascii.cl/htmlcodes.htm
// This implementation is taken from https://stackoverflow.com/a/7394814
// not currently needed because only a few items are actually broken
// function parseHtmlEntities(str: string) {
//   return str.replace(/&#([0-9]{1,3});/gi, function (match, numStr) {
//     const num = parseInt(numStr, 10) // read num as normal number
//     return String.fromCharCode(num)
//   })
// }

const brokenPlaintextEntities = {
  amp: '&',
  '#39': "'",
  quot: '"',
  lt: '<',
  gt: '>',
} as Record<string, string>

export function FixPlaintextEntities(text: string) {
  return text.replace(/&(#39|amp|quot|lt|gt);/g, (_match, entity) => {
    return brokenPlaintextEntities[entity]
  })
}

export function stripMarkdown(markdownText: string | undefined): string {
  if (!markdownText) return ''
  marked.use({
    renderer: plaintext({ spaces: false }),
    ...MarkedExtensions,
  })
  return FixPlaintextEntities(marked(markdownText))
}

export const MarkdownProps = {
  body: {
    type: String as PropType<TranslateResult>,
    default: '',
  },
  plaintext: {
    type: Boolean,
  },
  smallText: {
    type: Boolean,
  },
  /** Controls whether image captions are shown and also allows for specifying image sizes */
  imageCaptions: {
    type: Boolean,
    default: true,
  },
  stylizeLists: {
    type: Boolean,
    default: true,
  },
  listGaps: {
    type: Boolean,
  },
  links: {
    type: Boolean,
    default: true,
  },
  coloredLinks: {
    type: Boolean,
  },
  players: {
    type: Boolean,
  },
  bold: {
    type: Boolean,
    default: true,
  },
  classes: {
    type: String,
    default: '',
  },
}

export interface MarkdownOptions {
  plaintext: boolean
  links: boolean
  bold: boolean
  /** Controls whether image captions are shown and also allows for specifying image sizes */
  imageCaptions: boolean
  stylizeLists: boolean
  listGaps: boolean
  coloredLinks: boolean
  classes: string
  smallText: boolean
  players: boolean
}

export function MarkdownClasses(options: Partial<MarkdownOptions> = {}) {
  return `markdown break-words${options.stylizeLists ? ' list-styles' : ''}${
    options.listGaps ? ' list-gaps' : ''
  }${options.coloredLinks ? ' colored-links' : ''}${
    options.smallText ? ' text-sm' : ''
  } ${options.classes}`
}

// https://github.com/markedjs/marked
// https://marked.js.org/using_advanced
export function SetupMarkdown(options: Partial<MarkdownOptions> = {}) {
  marked.use({
    breaks: true,
    smartLists: true,
    xhtml: true,
    renderer: MarkdownRenderer(options),
  })
  marked.use(MarkedExtensions)
}

function MarkdownRenderer(options: Partial<MarkdownOptions> = {}) {
  if (options.plaintext) return plaintext()

  const renderer = new marked.Renderer()
  if (options.links) {
    const markedLinkifyIt = require('marked-linkify-it/lib/index.umd')
    marked.use(markedLinkifyIt({}, {}))
    renderer.link = (href, title, text) => {
      if (!href || !validUrlFormat(href)) return text
      title = title ? `title="${title}" ` : ''
      return `<a href="${href}" target="_blank" ${title}>${text}</a>`
    }
  } else {
    renderer.link = (_href, _title, text) => {
      return text ?? ''
    }
  }
  if (!options.bold) {
    renderer.strong = function (text: string) {
      return text
    }
  }
  if (options.imageCaptions) {
    renderer.image = (href, title, text) => {
      return CustomImageRenderer({ href, title, text })
    }
  }
  return renderer
}
