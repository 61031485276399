
import Vue, { PropType } from 'vue'
import { TranslateResult } from 'vue-i18n'
import ScrollList from '~/components/_general/ScrollList.vue'
import { AdOptions, AdType, AdTypeApi, UserAdApi } from '~/apiclient/apiads'
import { Broadcaster } from '~/models/broadcaster'
import { AdPage, promotionUrl } from '~/assets/ts/utils/urls'
import {
  AdStatus,
  GetAdStatus,
  GetAdTypeApiList,
  GetAdTypeTitle,
} from '~/assets/ts/utils/promotions'
import { DateString } from '~/assets/ts/types/date-string'
import { getUndefinedArray } from '~/assets/ts/utils/lists'
import AdListElement from '~/components/site/promotional/AdListElement.vue'
import { ValuesMatch } from '~/assets/ts/utils/validation'

export const AdListProps = {
  adType: {
    type: String as PropType<AdType>,
    default: undefined,
  },
  pageSize: {
    type: Number,
    default: 15,
  },
  placeholderCount: {
    type: Number,
    default: 5,
  },
  scrollable: {
    type: Boolean,
    default: true,
  },
  wrapperClasses: {
    type: String,
    default: 'max-sm:flex-col site-nav:max-xl:flex-col',
  },
  slotParentClasses: {
    type: String,
    default: 'w-72 max-sm:w-full site-nav:max-xl:w-full',
  },
  adParentClasses: {
    type: String,
    default: 'w-64 max-sm:w-full site-nav:max-xl:w-full xl:!w-80',
  },
  includeFuture: {
    type: Boolean,
    default: true,
  },
  showCost: {
    type: Boolean,
  },
  dateOnly: {
    type: Boolean,
  },
  dateBegin: {
    type: String as PropType<DateString>,
    default: undefined,
  },
  dateEnd: {
    type: String as PropType<DateString>,
    default: undefined,
  },
}

export default Vue.extend({
  name: 'SitePromotionalAdList',
  components: {
    AdListElement,
    ScrollList,
  },
  props: {
    ...AdListProps,
  },
  data() {
    return {
      pageNum: 1,
      endOfList: false,
      totalAds: undefined as number | undefined,
      apiAds: undefined as UserAdApi[] | undefined,
    }
  },
  async fetch() {
    await this.getAds()
  },
  computed: {
    ads(): (UserAdApi | undefined)[] {
      if (this.apiAds === undefined)
        return getUndefinedArray(this.placeholderCount)
      return this.apiAds
    },
    broadcaster(): Broadcaster | undefined {
      const broadcaster = this.$store.getters['broadcasters/promoBroadcaster']
      return broadcaster ? new Broadcaster(broadcaster) : undefined
    },
    adTypes(): AdTypeApi[] | undefined {
      return GetAdTypeApiList(this.adType)
    },
    endOfScrollableList(): boolean {
      return this.scrollable
        ? this.endOfList
        : this.apiAds !== undefined || this.endOfList
    },
    noResultsText(): TranslateResult {
      const adTypes = GetAdTypeTitle(this, this.adType, true)
      if (this.dateBegin) {
        return this.$t('No {adTypes} found in this date range', { adTypes })
      }
      return this.$t('No {adTypes} found', { adTypes })
    },
  },
  watch: {
    broadcaster: {
      handler(newValue: Broadcaster, oldValue: Broadcaster) {
        if (ValuesMatch(newValue, oldValue)) return
        this.resetAds()
      },
    },
    async dateBegin() {
      await this.resetAds()
    },
    async dateEnd() {
      await this.resetAds()
    },
  },
  methods: {
    adStatus(ad: UserAdApi | undefined): AdStatus {
      return GetAdStatus(ad)
    },
    editLink(adID: string) {
      return promotionUrl(this.adType, AdPage.Edit, adID)
    },
    async resetAds() {
      this.apiAds = undefined
      this.totalAds = undefined
      this.pageNum = 1
      this.endOfList = false
      await this.getAds()
    },
    async getAds() {
      if (!this.scrollable && this.apiAds !== undefined) return
      const options = {
        page: this.pageNum,
        pageSize: this.pageSize,
        adType: this.adTypes,
        broadcasterID: this.broadcaster?.id,
        includeFuture: this.includeFuture,
        dateBegin: this.dateBegin ?? undefined,
        dateEnd: this.dateEnd ?? undefined,
      } as Partial<AdOptions>
      const data = await this.$apiClient.getAds(options)
      this.totalAds = data.totalCount ?? 0

      if (!data.results.length) {
        this.endOfList = true
        return
      }
      const ads = this.apiAds ?? []
      data.results.forEach((s: UserAdApi) => {
        ads.push(s)
      })
      this.apiAds = ads
      this.pageNum++
    },
  },
})
