// Custom router implementation to handle custom domains
// Inspiration: https://nubisoft.io/blog/how-to-multiple-subdomains-nuxt-js/

import Router, { RouteConfig, RouterOptions } from 'vue-router'
import { Context } from '@nuxt/types'
import { matchingRoutes } from '~/router/rewriteutil'
import { RewriteKey } from '~/router/rewritetypes'

export function createRouter(
  context: Context,
  createDefaultRouter: any, // can't for the life of me figure out what the type for this actually is
  routerOptions: RouterOptions,
  _config: any
) {
  const options = routerOptions || createDefaultRouter(context).options

  let routes = options.routes as RouteConfig[]
  if (context) {
    // Server side - must rewrite routes for a custom domain now
    const configType = context.req.headers['x-sa-config-type'] as RewriteKey
    if (configType) {
      routes = matchingRoutes(configType, routes)
    }
  } else {
    // Client side - defer route rewriting until server state is available
    routes = [] // we can only add routes later - not remove
  }

  return new Router({
    ...options,
    _saorigroutes: options.routes, // save routes for later use by client
    routes,
  } as RouterOptions)
}
