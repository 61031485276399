import { render, staticRenderFns } from "./SermonMetadata.vue?vue&type=template&id=55a52270&scoped=true&"
import script from "./SermonMetadata.vue?vue&type=script&lang=ts&"
export * from "./SermonMetadata.vue?vue&type=script&lang=ts&"
import style0 from "./SermonMetadata.vue?vue&type=style&index=0&id=55a52270&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "55a52270",
  null
  
)

export default component.exports