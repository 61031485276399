import { Context } from '@nuxt/types'
import {
  broadcasterShareUrl,
  siteBroadcasterUrl,
  siteSeriesUrl,
} from '~/assets/ts/utils/urls'

interface RouteHandlers {
  path: string
  handler: (
    redirect: (status: number, location: string) => void,
    match: RegExpMatchArray | null
  ) => void
}
const wildcard = '[^\\/]+'

// a list of incoming urls and handlers for redirecting them
const catchAll = [
  {
    path: siteBroadcasterUrl(wildcard, `series/${wildcard}`),
    handler: (redirect, match) => {
      if (!match) return
      redirect(301, siteSeriesUrl(match[3]))
    },
  },
  {
    path: broadcasterShareUrl(wildcard),
    handler: (redirect, match) => {
      if (!match) return
      redirect(301, siteBroadcasterUrl(match[1]))
    },
  },
] as RouteHandlers[]

const wildcardRegex = new RegExp(wildcard, 'g')
export default async function ({ route, redirect, $apiClient }: Context) {
  // TODO: This is disabled because for some reason right now /vault always redirects
  // to /vault/ which causes a redirect loop. We need to figure this out eventually.
  // Once we do, switch trailingSlash to false in nuxt.config.js
  // This makes sure we aren't ending with a trailing slash
  // const newPath = RemoveTrailingSlash(route.path)
  // if (newPath !== route.path) {
  //   redirect(301, newPath)
  //   return
  // }

  catchAll.forEach((r) => {
    const regex = new RegExp(r.path, 'i')
    if (regex.test(route.path)) {
      const match = route.path.match(wildcardRegex)
      r.handler(redirect, match)
    }
  })

  // not a valid route
  if (route.name === null) {
    const url = `https://www.sermonaudio.com${route.fullPath}`
    const map = await $apiClient.mapPath(url)
    if (map) {
      redirect(map.status, map.url)
    }
  }
}
