import { TranslateResult } from 'vue-i18n'
import { truncateString } from '~/assets/ts/utils/strings'
import { stripMarkdown } from '~/assets/ts/markdown/renderer'

export function markdownMetadataDescription(
  markdownDescription: string | undefined
): string | undefined {
  if (!markdownDescription) return undefined
  return truncateString(stripMarkdown(markdownDescription), 150)
}

export const descriptions = (description: TranslateResult) => {
  return [
    { hid: 'description', name: 'description', content: description },
    {
      hid: 'twitter:description',
      name: 'twitter:description',
      content: description,
    },
    { hid: 'og:description', property: 'og:description', content: description },
  ]
}
export const titles = (pageTitle: TranslateResult) => {
  return [
    { hid: 'twitter:title', name: 'twitter:title', content: pageTitle },
    { hid: 'og:title', property: 'og:title', content: pageTitle },
    {
      hid: 'apple-mobile-web-app-title',
      property: 'apple-mobile-web-app-title',
      content: pageTitle,
    },
    {
      hid: 'application-name',
      property: 'application-name',
      content: pageTitle,
    },
    {
      // TODO: make this a value that can be passed in
      hid: 'og:site_name',
      property: 'og:site_name',
      content: 'SermonAudio',
    },
  ]
}

export const images = (imagePath: string, isExternalImage = false) => {
  if (!imagePath) return []
  if (!isExternalImage) {
    imagePath = `${process.env.SAWEB_BASE_URL || ''}${imagePath}`
  }
  return [
    { hid: 'twitter:image', name: 'twitter:image', content: imagePath },
    { hid: 'twitter:image:src', name: 'twitter:image:src', content: imagePath },
    { hid: 'og:image', property: 'og:image', content: imagePath },
    { hid: 'og:image:url', property: 'og:image:url', content: imagePath },
    {
      hid: 'og:image:secure_url',
      property: 'og:image:secure_url',
      content: imagePath,
    },
  ]
}

function favicon(folder: string, file: string) {
  return `${process.env.SAWEB_BASE_URL || ''}/favicons/${folder}/${file}`
}

type faviconsType = {
  link: Record<any, any>[]
  meta: Record<any, any>[]
}
export const favicons = (folder: string, accentColor = '') => {
  return {
    link: [
      {
        rel: 'alternate icon',
        hid: 'icon-ico',
        // disabled on 2/20/23 because it was forcing firefox to use the ico instead of the svg when sizes was set to 16x16
        // type: 'image/x-icon',
        // switched from "any" to "16x16" on 2/20/23 because it was forcing chrome to use the ico instead of the svg
        // https://stackoverflow.com/questions/63878618/why-does-chrome-request-both-svg-and-ico-favicons
        sizes: '16x16',
        href: favicon(folder, 'favicon.ico'),
      },
      {
        rel: 'icon',
        hid: 'icon-16',
        sizes: '16x16',
        type: 'image/png',
        href: favicon(folder, 'favicon-16x16.png'),
      },
      {
        rel: 'icon',
        hid: 'icon-32',
        type: 'image/png',
        sizes: '32x32',
        href: favicon(folder, 'favicon-32x32.png'),
      },
      {
        hid: 'manifest',
        rel: 'manifest',
        crossorigin: 'use-credentials',
        href: favicon(folder, 'manifest.webmanifest'),
      },
      {
        hid: 'mask-icon',
        rel: 'mask-icon',
        color: accentColor,
        href: favicon(folder, 'safari-pinned-tab.svg'),
      },
      {
        rel: 'apple-touch-icon',
        hid: 'apple-touch-icon',
        sizes: '180x180',
        href: favicon(folder, 'apple-touch-icon.png'),
      },
      {
        hid: 'svg-icon',
        rel: 'icon',
        type: 'image/svg+xml',
        href: favicon(folder, 'favicon.svg'),
      },
    ],
    meta: [
      {
        hid: 'msapplication-TileColor',
        name: 'msapplication-TileColor',
        content: accentColor,
      },
      {
        hid: 'theme-color',
        name: 'theme-color',
        content: accentColor,
      },
    ],
  } as faviconsType
}

export type HeadOptions = PartialRecord<
  | 'title'
  | 'description'
  | 'favicons'
  | 'images'
  | 'htmlAttrs'
  | 'bodyAttrs'
  | 'link'
  | 'meta',
  any
>

// TODO: The following required properties are missing: og:url, fb:app_id
// TODO: Add canonical URLs
export const coreMetadata = (nuxtContext: Record<any, any>) => {
  const i18nHead = nuxtContext.$nuxtI18nHead({ addSeoAttributes: false })
  return {
    bodyAttrs: {},
    htmlAttrs: {
      ...i18nHead.htmlAttrs,
    },
    meta: [
      { charset: 'utf-8' },
      {
        name: 'viewport',
        content:
          'width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no',
      },
      {
        hid: 'twitter:card',
        name: 'twitter:card',
        content: 'summary_large_image',
      },
      {
        hid: 'al:ios:app_name',
        name: 'al:ios:app_name',
        content: 'SermonAudio',
      },
      {
        hid: 'al:android:app_name',
        name: 'al:android:app_name',
        content: 'SermonAudio',
      },
      {
        hid: 'apple-itunes-app',
        name: 'apple-itunes-app',
        content: 'app-id=1577357355',
      },
      {
        hid: 'al:ios:app_store_id',
        name: 'al:ios:app_store_id',
        content: '1577357355',
      },
      {
        hid: 'al:android:package',
        name: 'al:android:package',
        content: 'com.sermonaudio.MainApp',
      },
      {
        hid: 'og:type',
        property: 'og:type',
        content: 'website',
      },
      {
        hid: 'mobile-web-app-capable',
        name: 'mobile-web-app-capable',
        content: 'yes',
      },
      ...i18nHead.meta,
    ],
    link: i18nHead.link,
  } as HeadOptions
}

export const metadata = (
  options?: HeadOptions,
  nuxtContext?: Record<any, any>
) => {
  const m = nuxtContext
    ? coreMetadata(nuxtContext)
    : ({ meta: [], link: [], bodyAttrs: {}, htmlAttrs: {} } as HeadOptions)
  if (!options) return m

  if (options.title) {
    m.title = options.title
    m.meta = [...m.meta, ...titles(options.title)]
  }
  if (options.description) {
    m.meta = [...m.meta, ...descriptions(options.description)]
  }
  if (options.favicons) {
    m.link = [...m.link, ...options.favicons.link]
    m.meta = [...m.meta, ...options.favicons.meta]
  }
  if (options.images) {
    m.meta = [...m.meta, ...options.images]
  }
  if (options.bodyAttrs) {
    const bodyAttrs = options.bodyAttrs as Record<string, any>
    Object.keys(bodyAttrs).forEach((key) => {
      const current = m.bodyAttrs[key]
      const added = bodyAttrs[key]
      m.bodyAttrs[key] = current ? `${current} ${added}` : added
    })
  }
  if (options.htmlAttrs) {
    const htmlAttrs = options.htmlAttrs as Record<string, any>
    Object.keys(htmlAttrs).forEach((key) => {
      const current = m.htmlAttrs[key]
      const added = htmlAttrs[key]
      m.htmlAttrs[key] = current ? `${current} ${added}` : added
    })
  }
  return m
}
