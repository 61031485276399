import { LatLngLiteral } from 'leaflet'
import { Speaker } from '~/models/speaker'
import { apiTimestampToJsDate } from '~/assets/ts/utils/date'
import {
  boolOrUndefined,
  displayUrl,
  resizableUrl,
} from '~/assets/ts/utils/misc'
import { siteWebcastUrl } from '~/assets/ts/utils/urls'
import {
  BroadcasterAttendanceDisplay,
  BroadcasterDonationOption,
  ServiceTimesApi,
  SocialAccountApi,
} from '~/apiclient/apibroadcasters'
import { SpeakerApi } from '~/apiclient/apispeakers'

export const BroadcasterImageAspectRatio = 97 / 145

export class BroadcasterSoloSite {
  createDate: Date
  domain: string
  baseUrl: string
  id: number
  status: string
  updateDate: Date
  constructor(solo: any) {
    this.createDate = new Date(solo.create_date * 1000)
    this.domain = solo.domain
    this.baseUrl = `https://${solo.domain}/`
    this.id = solo.solo_id
    this.status = solo.status
    this.updateDate = new Date(solo.update_date * 1000)
  }
}

export interface BroadcasterCategories {
  church: boolean
  school: boolean
  seminary: boolean
  radioBroadcast: boolean
}
export function GetBroadcasterCategoryNumber(
  categories: BroadcasterCategories
): number {
  let categoryNumber = 0
  if (categories.church) categoryNumber |= 1
  if (categories.school) categoryNumber |= 2
  if (categories.seminary) categoryNumber |= 4
  if (categories.radioBroadcast) categoryNumber |= 8
  return categoryNumber
}

export function GetBroadcasterCategories(categories: number) {
  return {
    church: !!(categories & 1),
    school: !!(categories & 2),
    seminary: !!(categories & 4),
    radioBroadcast: !!(categories & 8),
  }
}

export class Broadcaster {
  id: string
  displayName: string
  shortName: string
  location: string
  country: string
  countryISO: string
  churchSize?: number
  minister: string
  facebookUsername: string | null
  twitterUsername: string | null
  homePageURL: string | null
  latitude: number
  longitude: number
  latLng: LatLngLiteral
  imageURL: string
  imageURLResizable: string
  joined?: Date
  numberOfSermons: string | null
  albumArtURL: string
  bibleVersion: string | null
  aboutUs: string | null
  /** Physical Address */
  address: string | null
  mailingAddress?: string
  serviceTimeList?: ServiceTimesApi[]
  serviceTimeNotes?: string
  denomination: string | null
  canWebcast: boolean
  webcastInProgress: boolean
  phone: string | null
  listenLineNumber: string | null
  vacantPulpit: boolean
  welcomeVideoID: string
  idCode: string
  languageCode: string
  rssFeedAtomURL: string
  rssFeedURL: string
  following?: boolean
  soloSiteDisabled: boolean
  soloSite?: BroadcasterSoloSite
  categories: BroadcasterCategories
  primarySpeakers?: Speaker[]
  lite: boolean
  disabled: boolean
  donatable: boolean
  donationOptions: BroadcasterDonationOption[]
  bannerImageURL: string
  socialAccounts?: SocialAccountApi[]

  constructor(broadcaster: Record<string, any>) {
    const type = broadcaster.type || broadcaster.lite_type
    this.lite = !type || type.includes('lite') || type === 'true'
    this.disabled = broadcaster.disabled
    this.donatable = broadcaster.donatable ?? false
    this.donationOptions = broadcaster.donationOptions || []
    this.id = broadcaster.broadcasterID
    this.displayName = broadcaster.displayName
    this.shortName = broadcaster.shortName
    this.location = broadcaster.location
    this.country = broadcaster.country
    this.countryISO = broadcaster.countryISOCode
    this.churchSize = broadcaster.churchSize || undefined
    this.minister = broadcaster.minister || ''
    this.facebookUsername = broadcaster.facebookUsername
    this.twitterUsername = broadcaster.twitterUsername
    this.homePageURL = broadcaster.homePageURL
    this.latitude = broadcaster.latitude
    this.longitude = broadcaster.longitude
    this.latLng = {
      lat: this.latitude,
      lng: this.longitude,
    }
    this.imageURL = broadcaster.imageURL
    this.imageURLResizable = broadcaster.imageURLResizable
    this.joined = broadcaster.joined
      ? apiTimestampToJsDate(broadcaster.joined)
      : undefined
    this.numberOfSermons = broadcaster.numberOfSermons?.toLocaleString()
    this.albumArtURL = broadcaster.albumArtURL
    this.bibleVersion = broadcaster.bibleVersion ?? undefined
    this.aboutUs = broadcaster.aboutUs
    this.address = broadcaster.address
    this.mailingAddress = broadcaster.addressMail
    this.serviceTimeNotes = broadcaster.serviceTimes || undefined
    this.serviceTimeList = broadcaster.serviceTimesList || undefined
    this.denomination = broadcaster.denomination ?? undefined
    this.canWebcast = broadcaster.canWebcast
    this.webcastInProgress = broadcaster.webcastInProgress
    this.phone = broadcaster.phone
    this.listenLineNumber = broadcaster.listenLineNumber
    this.vacantPulpit = broadcaster.vacantPulpit
    this.welcomeVideoID = broadcaster.welcomeVideoID
    this.idCode = broadcaster.idCode
    this.categories = broadcaster.categories
    this.languageCode = broadcaster.languageCode
    this.rssFeedAtomURL = broadcaster.rssFeedAtomURL
    this.rssFeedURL = broadcaster.rssFeedURL
    this.soloSiteDisabled = broadcaster.soloSiteDisabled
    this.soloSite = broadcaster.soloSite
      ? new BroadcasterSoloSite(broadcaster.soloSite)
      : undefined
    this.categories = GetBroadcasterCategories(broadcaster.categories)
    this.following = boolOrUndefined(broadcaster.followed)

    const speakers = broadcaster.primarySpeakers as SpeakerApi[] | null
    if (speakers?.length && speakers[0].displayName) {
      this.primarySpeakers = speakers.map(
        (s: Record<string, any>) => new Speaker({ ...s, lite: true })
      )
    } else {
      this.primarySpeakers = undefined
    }
    this.bannerImageURL = broadcaster.bannerImageURL || ''
    this.socialAccounts = broadcaster.socialAccounts || undefined
  }

  get donationAddress(): string {
    return this.mailingAddress || this.address || ''
  }

  get hasServiceTimes(): boolean {
    return !!(this.serviceTimeNotes || this.serviceTimeList?.length)
  }

  // TODO: remove this in favor of using the full list
  get primarySpeaker(): Speaker | undefined {
    if (!this.primarySpeakers) return undefined
    return this.primarySpeakers[0]
  }

  albumArt(size: number): string | undefined {
    if (!this.albumArtURL) return undefined
    return this.albumArtURL.replaceAll(/{size}/g, size.toString())
  }

  imageResizable(width: number): string | undefined {
    const url = this.imageURLResizable
    if (!url) return undefined
    const height = Math.round(width * BroadcasterImageAspectRatio)
    return resizableUrl(url, width, height)
  }

  get homePageDisplayUrl(): string {
    if (!this.homePageURL) return ''
    return displayUrl(this.homePageURL)
  }

  get SaUrl(): string {
    return `https://www.sermonaudio.com/${this.id}`
  }

  get SoloUrl(): string {
    return this.soloSite
      ? this.soloSite.baseUrl
      : `https://sermonaudio.com/solo/${this.id}/`
  }

  get Url(): string {
    return this.soloSiteDisabled ? this.SaUrl : this.SoloUrl
  }

  get churchSizeRange(): string {
    return BroadcasterAttendanceDisplay(this.churchSize)
  }

  get shareTitle(): string {
    return this.displayName
  }

  openWebcastPopup() {
    window.open(
      `${siteWebcastUrl(this.id)}?autoplay=1&popout=1`,
      '_blank',
      'toolbar=no,menubar=no,titlebar=no,status=no,scrollbars=no,resizable=yes,left=0,top=0,width=768,height=720'
    )
  }

  matchesSearchTerm(searchTerm: string): boolean {
    const search = searchTerm.toLowerCase()
    if (this.id.includes(search)) return true
    if (this.displayName.toLowerCase().includes(search)) return true
    if (this.denomination?.toLowerCase().includes(search)) return true
    if (this.location.toLowerCase().includes(search)) return true
    if (this.primarySpeakers) {
      if (this.primarySpeakers.find((s) => s.matchesSearchTerm(search)))
        return true
    }
    if (this.primarySpeaker?.matchesSearchTerm(search)) return true
    return this.minister.toLowerCase().includes(search)
  }
}
