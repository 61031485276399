import { ApiClient, ApiPaginationResult } from '~/apiclient/apiclient'
import { DisplayUserApi } from '~/models/usercomment'
import { DateString } from '~/assets/ts/types/date-string'
import { SermonApi } from '~/apiclient/apisermons'

// Technically 'ad ad-full, ad-local, ad-partner, ad-sponsor, blog, event, full, news, sermon, sermon-featured, store, hero, spotlight, featuredsermon, minifeature'
export type AdTypeApi =
  | 'ad'
  | 'ad-sponsor'
  | 'sermon'
  | 'sermon-featured'
  | 'spotlight'
  | 'hero'

export enum AdType {
  FeaturedSermon = 'featured',
  Graphic = 'graphical',
  Text = 'text',
  Hero = 'hero',
  Spotlight = 'spotlight',
}

export interface AdImage {
  imageURL: string
  imageURLWebp: string
}

export interface DisplayAdApi {
  adType: AdTypeApi
  campaignID: string
  title: string
  description: string
  url: string
  images: AdImage[]
  sermon?: SermonApi
  countdownTo?: number
  seq?: number
  begin?: string
  end?: string
  featuredContentType?: string
  renderHint?: string
  actionText?: string
  seriesID?: string
  highlightColor?: string
  featureType?: string
  data?: Record<any, any>
}

export interface DateParams {
  /** Always Inclusive */
  dateBegin: DateString
  /** Always Exclusive */
  dateEnd: DateString
}

export interface UserAdApi extends DisplayAdApi, DateParams {
  user: DisplayUserApi
  price: string
  totalClicks?: number
  totalImpressions?: number
}

export interface CouponCountsApi {
  text: number
  sermon: number
}

export interface AdTypeConfigApi {
  cost: string
  maxDays: number
  earliestDate: DateString
  latestDate: DateString
  blockedDates?: DateString[]
  newsletterMinDaysForExposure: number
}

export interface AdGlobalConfigApi {
  minifeature: AdTypeConfigApi
  featuredsermon: AdTypeConfigApi
  sponsorfeature: AdTypeConfigApi
  newsletterSubscriberCount: number
  titleMaxLen: number
  textMaxLen: number
}

export type LocationStats = Record<string, Record<string, number>>

export interface AdStatsAggregateApi {
  totalImpressions: number
  totalClicks: number
  deviceTypes: {
    desktop?: number
    mobile?: number
    tablet?: number
  }
  locationStats: LocationStats
}

export interface AdStatsOptions extends Partial<DateParams> {
  adType?: AdTypeApi[]
  campaignID?: string
  broadcasterID?: string
}

export interface AdOptions {
  page?: number
  pageSize?: number
  adType: AdTypeApi[]
  broadcasterID?: string
  includeFuture?: boolean
  dateBegin?: string
  dateEnd?: string
}

export async function getAd(this: ApiClient, adID: string): Promise<UserAdApi> {
  const { data } = await this.$axios.get(`node/ads/manage/campaigns/${adID}`)
  return data
}

export async function getAds(
  this: ApiClient,
  options: Partial<AdOptions>
): Promise<ApiPaginationResult<UserAdApi>> {
  const { data } = await this.$axios.get('node/ads/manage/campaigns', {
    params: options,
  })
  return data
}

export async function getCouponsAvailable(
  this: ApiClient,
  broadcasterID: string
): Promise<CouponCountsApi> {
  const { data } = await this.$axios.get(
    `node/broadcasters/${broadcasterID}/ad_coupons_available`
  )
  return data
}

export interface AdConfigParams {
  sermonID?: string
  adType?: AdTypeApi
}

export async function getAdConfig(
  this: ApiClient,
  params: AdConfigParams = {}
): Promise<AdGlobalConfigApi> {
  const { data } = await this.$axios.get('node/ads/manage/config', {
    params,
  })
  return data
}

export async function getAdStatsAggregate(
  this: ApiClient,
  options: Partial<AdStatsOptions> = {}
): Promise<AdStatsAggregateApi> {
  const { data } = await this.$axios.get(
    `node/ads/manage/campaigns/stats/aggregate`,
    {
      params: options,
    }
  )
  return data
}

export interface AdMetadataParams {
  sermonID?: number | string
  title?: string
  description?: string
  url?: string
}

export interface AdCreateParams extends AdMetadataParams, DateParams {
  adType: AdTypeApi
  billToBroadcasterID?: string
  paymentMethod?: string
  testCharge?: boolean
  paymentAmount?: number
}

export enum AdCreateError {
  /** Pick one of paymentMethod or billToBroadcasterID. Both can't be specified. */
  PaymentMismatch = 'AD_PAYMENT_MISMATCH',
  /** Invalid ad type */
  InvalidType = 'AD_INVALID_TYPE',
  /** sermonID required */
  NoSermonID = 'AD_NO_SERMONID',
  /** Broadcaster disabled */
  DisabledBroadcaster = 'AD_DISABLED_BROADCASTER',
  /** All three of title, description, url must be provided for ad type */
  MissingMetadata = 'AD_MISSING_METADATA',
  /** Costs do not match */
  CostMismatch = 'AD_COST_MISMATCH',
  /** Credit card purchases must include payment method */
  NoPaymentMethod = 'AD_NO_PAYMENT_METHOD',
  /** No broadcaster ID specified */
  NoBroadcasterID = 'AD_NO_BROADCASTER_ID_FOR_BILL',
  /** Unsupported payment type */
  UnsupportedPaymentType = 'AD_UNSUPPORTED_PAYMENT_TYPE',
  /** Title length exceeds maximum */
  LongTitle = 'AD_TITLE_TOO_LONG',
  /** Text length exceeds maximum */
  LongText = 'AD_TEXT_TOO_LONG',
  /** Ad exceeds maximum allowed days. */
  MaxDaysExceeded = 'AD_MAX_DAYS_EXCEEDED',
  /** Another sermon is already featured on that date */
  InvalidFeaturedSermonDate = 'AD_INVALID_DATE_FEATURED_SERMON',
  /** That sermon is already featured during one of those dates */
  InvalidTextSermonDates = 'AD_INVALID_DATES_FOR_SERMON',
  /** Invalid dates: out of range */
  InvalidDates = 'AD_INVALID_DATES_OUT_OF_RANGE',
}

export async function createAd(this: ApiClient, createParams: AdCreateParams) {
  const { data } = await this.$axios.post(
    `node/ads/manage/campaigns`,
    createParams
  )
  return data as UserAdApi
}

export async function updateAd(
  this: ApiClient,
  campaignID: string,
  updateParams: Partial<AdMetadataParams>
) {
  await this.$axios.patch(
    `node/ads/manage/campaigns/${campaignID}`,
    updateParams
  )
}

export async function getAdUploadUrl(
  this: ApiClient,
  campaignID: string
): Promise<string> {
  const upload = await this.$axios.post('media/images/sponsor_ad', {
    campaignID,
  })
  return upload.data.uploadURL
}

export async function getDisplayAds(this: ApiClient): Promise<DisplayAdApi[]> {
  const { data } = await this.$axios.get('node/ads/display/campaigns/today-all')
  return data.results as DisplayAdApi[]
}
