// TODO: make this inclusive
import Vue from 'vue'
import { boolOrUndefined } from '~/assets/ts/utils/misc'
import { SiteUserRequiredModal } from '~/assets/ts/utils/site'

export type LanguageCode3 = 'eng' | string

export interface CaptionLanguages {
  alpha_2: string[]
  alpha_3: LanguageCode3[]
}

export type VideoCodecType = string
export type AudioCodecType = string

export enum MediaType {
  MP3 = 'mp3',
  AAC = 'aac',
  MP4 = 'mp4',
  PDF = 'pdf',
  Word = 'doc',
  Transcript = 'transcript',
  Jpeg = 'jpg',
  OriginalAudio = 'orig-audio',
  OriginalVideo = 'orig-video',
  Caption = 'caption',
}

export class Media {
  type: MediaType
  live: boolean
  language: string
  streamURL?: string
  eventStreamURL?: string
  downloadURL?: string
  bitrate?: number
  duration?: number
  audioCodec?: AudioCodecType
  videoCodec?: VideoCodecType
  thumbnailImageURL?: string
  adaptive: boolean
  fileSizeBytes?: number
  autoGenerated?: boolean
  sermonID: string

  constructor(media: Record<string, any>, sermonID: string) {
    this.type = media.mediaType
    this.live = media.live
    this.adaptive = !!media.adaptiveBitrate
    this.language = media.language
    this.sermonID = sermonID

    this.fileSizeBytes = media.fileSizeBytes ? media.fileSizeBytes : undefined
    this.streamURL = media.streamURL ? media.streamURL : undefined
    this.eventStreamURL = media.eventStreamURL
      ? media.eventStreamURL
      : undefined
    this.downloadURL = media.downloadURL ? media.downloadURL : undefined
    this.bitrate = media.bitrate ? media.bitrate : undefined
    this.duration = media.duration ? media.duration : undefined
    this.audioCodec = media.audioCodec ? media.audioCodec : undefined
    this.videoCodec = media.videoCodec ? media.videoCodec : undefined
    this.thumbnailImageURL = media.thumbnailImageURL
      ? media.thumbnailImageURL
      : undefined
    this.autoGenerated = boolOrUndefined(media.autoGenerated)
  }

  get transcriptApiUrl(): string {
    if (this.type !== MediaType.Caption) return ''
    return `/node/sermons/${this.sermonID}/${this.language}/transcript`
  }

  async downloadTranscript(context: Vue) {
    if (context.$users.loggedIn) {
      await context.$apiClient.downloadFromApi(
        this.transcriptApiUrl,
        `${this.sermonID}-${this.language}-transcript.txt`
      )
    } else {
      SiteUserRequiredModal(context, context.$t('Download Transcript'))
    }
  }
}

export class MediaSet {
  text: [Media]
  audio: [Media]
  video: [Media]
  caption: [Media]

  constructor(mediaSet: any, sermonID: string) {
    this.caption = mediaSet.caption
      ? mediaSet.caption.map((c: any) => new Media(c, sermonID))
      : []
    this.text = mediaSet.text.map((t: any) => new Media(t, sermonID))
    this.audio = mediaSet.audio.map((a: any) => new Media(a, sermonID))
    this.video = mediaSet.video.map((v: any) => new Media(v, sermonID))
  }

  /**
   * @private
   */
  getVideoTitle(videoResolution: string): string {
    if (process.client) {
      return window.$nuxt
        .$t('{videoResolution} Video', { videoResolution })
        .toString()
    } else {
      return `${videoResolution} Video`
    }
  }

  getDownloadList(context: Vue): MediaDownload[] {
    const list = [] as MediaDownload[]
    if (this.video1080p) {
      list.push(
        new MediaDownload(
          this.getVideoTitle('1080p'),
          this.video1080p.downloadURL
        )
      )
    }
    if (this.video720p) {
      list.push(
        new MediaDownload(
          this.getVideoTitle('720p'),
          this.video720p.downloadURL
        )
      )
    }
    if (this.video360p) {
      list.push(
        new MediaDownload(
          this.getVideoTitle('360p'),
          this.video360p.downloadURL
        )
      )
    }
    if (this.audioHigh) {
      list.push(
        new MediaDownload(
          context.$t('HQ Audio').toString(),
          this.audioHigh.downloadURL
        )
      )
    }
    if (this.audioLow) {
      list.push(
        new MediaDownload(
          context.$t('Audio').toString(),
          this.audioLow.downloadURL
        )
      )
    }
    if (this.pdf) {
      list.push(new MediaDownload('PDF', this.pdf.downloadURL))
    }

    if (this.caption.length > 0) {
      const caption = this.caption[0]
      let title = context.$t('Raw Transcript')
      if (caption.autoGenerated) title += ` (${context.$t('Auto')})`
      list.push(new MediaDownload(title.toString(), undefined, caption))
    }
    return list
  }

  highestVideo(includeH265 = true): Media | undefined {
    if (!this.video.length) return
    if (includeH265) return this.video[0]
    return this.video.filter((quality) => quality.videoCodec !== 'h265')[0]
  }

  // TODO: set this up with a valid set of languages
  getTranscript(languageCode3: LanguageCode3 | undefined): Media | undefined {
    if (!this.caption?.length) return undefined
    if (!languageCode3) {
      return this.caption[0]
    }
    return (
      this.caption.find((c) =>
        c.downloadURL?.includes(`-${languageCode3}.srt`)
      ) ?? this.caption[0]
    )
  }

  get videoAdaptive(): Media | undefined {
    return this.video.find((quality) => quality.adaptive)
  }

  get video1080p(): Media | undefined {
    return this.video.find((quality) => quality.videoCodec === 'h265')
  }

  get video720p(): Media | undefined {
    const lowerQualities = this.video.filter(
      (quality) => quality.videoCodec !== 'h265' && !quality.adaptive
    )
    if (lowerQualities.length > 1) return lowerQualities[0]
  }

  get video360p(): Media | undefined {
    const lowerQualities = this.video.filter(
      (quality) => quality.videoCodec !== 'h265' && !quality.adaptive
    )
    return lowerQualities[lowerQualities.length - 1]
  }

  get highestAudio(): Media | undefined {
    return this.audioHigh || this.audioLow
  }

  get audioHigh(): Media | undefined {
    return this.audio.length > 1 ? this.audio[0] : undefined
  }

  get audioLow(): Media | undefined {
    return this.audio[this.audio.length - 1]
  }

  get pdf(): Media | undefined {
    return this.text.find((t) => t.downloadURL?.includes('.pdf'))
  }
}

export class MediaDownload {
  title: string
  url?: string
  media?: Media

  constructor(title: string, url?: string, media?: Media) {
    this.title = title
    this.url = url
    this.media = media
  }
}
