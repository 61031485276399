import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _12ee7daa = () => interopDefault(import('../pages/activate.vue' /* webpackChunkName: "pages/activate" */))
const _4671f594 = () => interopDefault(import('../pages/articles-of-faith.vue' /* webpackChunkName: "pages/articles-of-faith" */))
const _70c9f600 = () => interopDefault(import('../pages/broadcasters/index.vue' /* webpackChunkName: "pages/broadcasters/index" */))
const _6e3661ac = () => interopDefault(import('../pages/comments.vue' /* webpackChunkName: "pages/comments" */))
const _34e3f925 = () => interopDefault(import('../pages/daily-devotional.vue' /* webpackChunkName: "pages/daily-devotional" */))
const _7cd40ce2 = () => interopDefault(import('../pages/dashboard/index.vue' /* webpackChunkName: "pages/dashboard/index" */))
const _bf4be96c = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _512757d0 = () => interopDefault(import('../pages/hymnal/index.vue' /* webpackChunkName: "pages/hymnal/index" */))
const _bb2eb214 = () => interopDefault(import('../pages/library/index.vue' /* webpackChunkName: "pages/library/index" */))
const _4fef928b = () => interopDefault(import('../pages/mychurch.vue' /* webpackChunkName: "pages/mychurch" */))
const _5018e94e = () => interopDefault(import('../pages/news/index.vue' /* webpackChunkName: "pages/news/index" */))
const _36f3c54f = () => interopDefault(import('../pages/privacy-policy.vue' /* webpackChunkName: "pages/privacy-policy" */))
const _4851ce6b = () => interopDefault(import('../pages/promotions/index.vue' /* webpackChunkName: "pages/promotions/index" */))
const _4d27d82e = () => interopDefault(import('../pages/psalter/index.vue' /* webpackChunkName: "pages/psalter/index" */))
const _d2b18c1a = () => interopDefault(import('../pages/radio.vue' /* webpackChunkName: "pages/radio" */))
const _1cba93b6 = () => interopDefault(import('../pages/release-notes/index.vue' /* webpackChunkName: "pages/release-notes/index" */))
const _16a63760 = () => interopDefault(import('../pages/search.vue' /* webpackChunkName: "pages/search" */))
const _7559d7f2 = () => interopDefault(import('../pages/series/index.vue' /* webpackChunkName: "pages/series/index" */))
const _17a0eafc = () => interopDefault(import('../pages/sermons/index.vue' /* webpackChunkName: "pages/sermons/index" */))
const _52792dad = () => interopDefault(import('../pages/sitemap.vue' /* webpackChunkName: "pages/sitemap" */))
const _78ca418f = () => interopDefault(import('../pages/speakers/index.vue' /* webpackChunkName: "pages/speakers/index" */))
const _7067e4aa = () => interopDefault(import('../pages/vault.vue' /* webpackChunkName: "pages/vault" */))
const _6aaa407b = () => interopDefault(import('../pages/webcasts/index.vue' /* webpackChunkName: "pages/webcasts/index" */))
const _0caf89d2 = () => interopDefault(import('../pages/admin/api.vue' /* webpackChunkName: "pages/admin/api" */))
const _7283484d = () => interopDefault(import('../pages/admin/classic-embeds.vue' /* webpackChunkName: "pages/admin/classic-embeds" */))
const _65765827 = () => interopDefault(import('../pages/admin/metadata.vue' /* webpackChunkName: "pages/admin/metadata" */))
const _517b62cc = () => interopDefault(import('../pages/admin/speakers.vue' /* webpackChunkName: "pages/admin/speakers" */))
const _59df70fa = () => interopDefault(import('../pages/broadcaster/linking-users.vue' /* webpackChunkName: "pages/broadcaster/linking-users" */))
const _2ecdac35 = () => interopDefault(import('../pages/broadcaster/select.vue' /* webpackChunkName: "pages/broadcaster/select" */))
const _05b164cc = () => interopDefault(import('../pages/broadcaster/sign-in.vue' /* webpackChunkName: "pages/broadcaster/sign-in" */))
const _1a47bb42 = () => interopDefault(import('../pages/broadcaster/sign-up.vue' /* webpackChunkName: "pages/broadcaster/sign-up" */))
const _ef664520 = () => interopDefault(import('../pages/broadcasters/featured.vue' /* webpackChunkName: "pages/broadcasters/featured" */))
const _3cb3b620 = () => interopDefault(import('../pages/broadcasters/group/index.vue' /* webpackChunkName: "pages/broadcasters/group/index" */))
const _460fd288 = () => interopDefault(import('../pages/broadcasters/international.vue' /* webpackChunkName: "pages/broadcasters/international" */))
const _3888a8b2 = () => interopDefault(import('../pages/broadcasters/podcast.vue' /* webpackChunkName: "pages/broadcasters/podcast" */))
const _07b8b794 = () => interopDefault(import('../pages/broadcasters/school.vue' /* webpackChunkName: "pages/broadcasters/school" */))
const _8490e02c = () => interopDefault(import('../pages/broadcasters/seminary.vue' /* webpackChunkName: "pages/broadcasters/seminary" */))
const _b654e29e = () => interopDefault(import('../pages/broadcasters/usa.vue' /* webpackChunkName: "pages/broadcasters/usa" */))
const _799441de = () => interopDefault(import('../pages/broadcasters/vacant.vue' /* webpackChunkName: "pages/broadcasters/vacant" */))
const _ddff98da = () => interopDefault(import('../pages/dashboard/articles/index.vue' /* webpackChunkName: "pages/dashboard/articles/index" */))
const _5dd50fb2 = () => interopDefault(import('../pages/demo/asyncdata.vue' /* webpackChunkName: "pages/demo/asyncdata" */))
const _abedff96 = () => interopDefault(import('../pages/demo/audio-embeds.vue' /* webpackChunkName: "pages/demo/audio-embeds" */))
const _fa06d88c = () => interopDefault(import('../pages/demo/broadcaster.vue' /* webpackChunkName: "pages/demo/broadcaster" */))
const _0bfe0aea = () => interopDefault(import('../pages/demo/broadcaster-locations.vue' /* webpackChunkName: "pages/demo/broadcaster-locations" */))
const _4fae7ae6 = () => interopDefault(import('../pages/demo/buttons.vue' /* webpackChunkName: "pages/demo/buttons" */))
const _26d5acbc = () => interopDefault(import('../pages/demo/cornerstone.vue' /* webpackChunkName: "pages/demo/cornerstone" */))
const _0178d6de = () => interopDefault(import('../pages/demo/dates.vue' /* webpackChunkName: "pages/demo/dates" */))
const _7596630c = () => interopDefault(import('../pages/demo/device.vue' /* webpackChunkName: "pages/demo/device" */))
const _52a4ac18 = () => interopDefault(import('../pages/demo/legacy-urls.vue' /* webpackChunkName: "pages/demo/legacy-urls" */))
const _0ce85b3b = () => interopDefault(import('../pages/demo/md.vue' /* webpackChunkName: "pages/demo/md" */))
const _d9ed378c = () => interopDefault(import('../pages/demo/mde.vue' /* webpackChunkName: "pages/demo/mde" */))
const _1472ef44 = () => interopDefault(import('../pages/demo/s3.vue' /* webpackChunkName: "pages/demo/s3" */))
const _3dce7199 = () => interopDefault(import('../pages/demo/search-pills.vue' /* webpackChunkName: "pages/demo/search-pills" */))
const _7a2cb61b = () => interopDefault(import('../pages/demo/series.vue' /* webpackChunkName: "pages/demo/series" */))
const _5e8fa3d0 = () => interopDefault(import('../pages/demo/sermon.vue' /* webpackChunkName: "pages/demo/sermon" */))
const _a5a417ea = () => interopDefault(import('../pages/demo/speaker.vue' /* webpackChunkName: "pages/demo/speaker" */))
const _6900f740 = () => interopDefault(import('../pages/demo/speaker-picker.vue' /* webpackChunkName: "pages/demo/speaker-picker" */))
const _3aab1e70 = () => interopDefault(import('../pages/demo/video-embeds.vue' /* webpackChunkName: "pages/demo/video-embeds" */))
const _6ff6cca1 = () => interopDefault(import('../pages/library/bookmarks.vue' /* webpackChunkName: "pages/library/bookmarks" */))
const _5fa7d300 = () => interopDefault(import('../pages/library/comments.vue' /* webpackChunkName: "pages/library/comments" */))
const _a2928290 = () => interopDefault(import('../pages/library/history.vue' /* webpackChunkName: "pages/library/history" */))
const _5f623b2a = () => interopDefault(import('../pages/rewrite/psalter/index.vue' /* webpackChunkName: "pages/rewrite/psalter/index" */))
const _50e36392 = () => interopDefault(import('../pages/series/picks.vue' /* webpackChunkName: "pages/series/picks" */))
const _64c40302 = () => interopDefault(import('../pages/series/podcasts.vue' /* webpackChunkName: "pages/series/podcasts" */))
const _20d64eeb = () => interopDefault(import('../pages/series/recent.vue' /* webpackChunkName: "pages/series/recent" */))
const _ac2f59c2 = () => interopDefault(import('../pages/sermons/categories/index.vue' /* webpackChunkName: "pages/sermons/categories/index" */))
const _745ecf5a = () => interopDefault(import('../pages/sermons/clips.vue' /* webpackChunkName: "pages/sermons/clips" */))
const _681d7c31 = () => interopDefault(import('../pages/sermons/date/index.vue' /* webpackChunkName: "pages/sermons/date/index" */))
const _5fe950ae = () => interopDefault(import('../pages/sermons/featured.vue' /* webpackChunkName: "pages/sermons/featured" */))
const _4beac38e = () => interopDefault(import('../pages/sermons/languages/index.vue' /* webpackChunkName: "pages/sermons/languages/index" */))
const _714574a0 = () => interopDefault(import('../pages/sermons/scripture/index.vue' /* webpackChunkName: "pages/sermons/scripture/index" */))
const _66663175 = () => interopDefault(import('../pages/sermons/staff-picks.vue' /* webpackChunkName: "pages/sermons/staff-picks" */))
const _176e7027 = () => interopDefault(import('../pages/sermons/topics/index.vue' /* webpackChunkName: "pages/sermons/topics/index" */))
const _38c3a1ad = () => interopDefault(import('../pages/sermons/transcripts.vue' /* webpackChunkName: "pages/sermons/transcripts" */))
const _08765cd8 = () => interopDefault(import('../pages/sermons/videos.vue' /* webpackChunkName: "pages/sermons/videos" */))
const _d2fdc598 = () => interopDefault(import('../pages/speakers/choice.vue' /* webpackChunkName: "pages/speakers/choice" */))
const _55d73c0f = () => interopDefault(import('../pages/speakers/classic.vue' /* webpackChunkName: "pages/speakers/classic" */))
const _9f75bafe = () => interopDefault(import('../pages/speakers/featured.vue' /* webpackChunkName: "pages/speakers/featured" */))
const _4a746441 = () => interopDefault(import('../pages/speakers/puritans.vue' /* webpackChunkName: "pages/speakers/puritans" */))
const _49399981 = () => interopDefault(import('../pages/user/about.vue' /* webpackChunkName: "pages/user/about" */))
const _3299175d = () => interopDefault(import('../pages/user/login.vue' /* webpackChunkName: "pages/user/login" */))
const _6d9adcc6 = () => interopDefault(import('../pages/user/logout.vue' /* webpackChunkName: "pages/user/logout" */))
const _269a0d15 = () => interopDefault(import('../pages/user/reset-password.vue' /* webpackChunkName: "pages/user/reset-password" */))
const _695ab5df = () => interopDefault(import('../pages/user/settings.vue' /* webpackChunkName: "pages/user/settings" */))
const _4dc24035 = () => interopDefault(import('../pages/user/verify.vue' /* webpackChunkName: "pages/user/verify" */))
const _1319d1d8 = () => interopDefault(import('../pages/video-wall/admin.vue' /* webpackChunkName: "pages/video-wall/admin" */))
const _d246a2a8 = () => interopDefault(import('../pages/video-wall/qr.vue' /* webpackChunkName: "pages/video-wall/qr" */))
const _54da10fc = () => interopDefault(import('../pages/webcasts/recent.vue' /* webpackChunkName: "pages/webcasts/recent" */))
const _8e303aa4 = () => interopDefault(import('../pages/dashboard/account/customization.vue' /* webpackChunkName: "pages/dashboard/account/customization" */))
const _b01caf08 = () => interopDefault(import('../pages/dashboard/account/donations.vue' /* webpackChunkName: "pages/dashboard/account/donations" */))
const _6353ca69 = () => interopDefault(import('../pages/dashboard/account/email-settings.vue' /* webpackChunkName: "pages/dashboard/account/email-settings" */))
const _5bb8fe5b = () => interopDefault(import('../pages/dashboard/account/managers.vue' /* webpackChunkName: "pages/dashboard/account/managers" */))
const _3157ad98 = () => interopDefault(import('../pages/dashboard/account/settings.vue' /* webpackChunkName: "pages/dashboard/account/settings" */))
const _3c962d1e = () => interopDefault(import('../pages/dashboard/django/articles/index.vue' /* webpackChunkName: "pages/dashboard/django/articles/index" */))
const _45933172 = () => interopDefault(import('../pages/dashboard/django/customization.vue' /* webpackChunkName: "pages/dashboard/django/customization" */))
const _6efaf940 = () => interopDefault(import('../pages/dashboard/django/donations.vue' /* webpackChunkName: "pages/dashboard/django/donations" */))
const _6c125425 = () => interopDefault(import('../pages/dashboard/django/email-settings.vue' /* webpackChunkName: "pages/dashboard/django/email-settings" */))
const _51a08517 = () => interopDefault(import('../pages/dashboard/django/managers.vue' /* webpackChunkName: "pages/dashboard/django/managers" */))
const _273f3454 = () => interopDefault(import('../pages/dashboard/django/settings.vue' /* webpackChunkName: "pages/dashboard/django/settings" */))
const _127bbd85 = () => interopDefault(import('../pages/dashboard/embed/link-user.vue' /* webpackChunkName: "pages/dashboard/embed/link-user" */))
const _73465e64 = () => interopDefault(import('../pages/dashboard/embed/payment-method-input.vue' /* webpackChunkName: "pages/dashboard/embed/payment-method-input" */))
const _3b84a19a = () => interopDefault(import('../pages/dashboard/embed/user-dropdown.vue' /* webpackChunkName: "pages/dashboard/embed/user-dropdown" */))
const _08602154 = () => interopDefault(import('../pages/embed/classic/examples.vue' /* webpackChunkName: "pages/embed/classic/examples" */))
const _12584357 = () => interopDefault(import('../pages/sermons/date/50s.vue' /* webpackChunkName: "pages/sermons/date/50s" */))
const _473e7e98 = () => interopDefault(import('../pages/sermons/date/60s.vue' /* webpackChunkName: "pages/sermons/date/60s" */))
const _7c24b9d9 = () => interopDefault(import('../pages/sermons/date/70s.vue' /* webpackChunkName: "pages/sermons/date/70s" */))
const _4081ec36 = () => interopDefault(import('../pages/sermons/date/week.vue' /* webpackChunkName: "pages/sermons/date/week" */))
const _56caa800 = () => interopDefault(import('../pages/embed/classic/player/l/_broadcasterID.vue' /* webpackChunkName: "pages/embed/classic/player/l/_broadcasterID" */))
const _716b6a7e = () => interopDefault(import('../pages/player/sermon/legacy/video/_sermon.vue' /* webpackChunkName: "pages/player/sermon/legacy/video/_sermon" */))
const _774fca03 = () => interopDefault(import('../pages/dashboard/django/articles/_articleID.vue' /* webpackChunkName: "pages/dashboard/django/articles/_articleID" */))
const _40aa2952 = () => interopDefault(import('../pages/player/sermon/legacy/_sermon.vue' /* webpackChunkName: "pages/player/sermon/legacy/_sermon" */))
const _23cca990 = () => interopDefault(import('../pages/promotions/stats/ad/_adId.vue' /* webpackChunkName: "pages/promotions/stats/ad/_adId" */))
const _02b08957 = () => interopDefault(import('../pages/promotions/stats/all/_adType.vue' /* webpackChunkName: "pages/promotions/stats/all/_adType" */))
const _07d07bec = () => interopDefault(import('../pages/rewrite/psalter/search/_keyword.vue' /* webpackChunkName: "pages/rewrite/psalter/search/_keyword" */))
const _e5514dee = () => interopDefault(import('../pages/embed/classic/player/_mediaType/_sermonID.vue' /* webpackChunkName: "pages/embed/classic/player/_mediaType/_sermonID" */))
const _0ff2c3de = () => interopDefault(import('../pages/embed/classic/player/_mediaType/_category/_categoryValue/_filter/_filterValue.vue' /* webpackChunkName: "pages/embed/classic/player/_mediaType/_category/_categoryValue/_filter/_filterValue" */))
const _dea67fa0 = () => interopDefault(import('../pages/broadcasters/alphabetical/_letter.vue' /* webpackChunkName: "pages/broadcasters/alphabetical/_letter" */))
const _165e621c = () => interopDefault(import('../pages/broadcasters/country/_country.vue' /* webpackChunkName: "pages/broadcasters/country/_country" */))
const _27c29b55 = () => interopDefault(import('../pages/broadcasters/group/_group/index.vue' /* webpackChunkName: "pages/broadcasters/group/_group/index" */))
const _5b5cf3bc = () => interopDefault(import('../pages/broadcasters/search/_search.vue' /* webpackChunkName: "pages/broadcasters/search/_search" */))
const _052bbbdc = () => interopDefault(import('../pages/broadcasters/state/_state.vue' /* webpackChunkName: "pages/broadcasters/state/_state" */))
const _1f878521 = () => interopDefault(import('../pages/dashboard/articles/_articleID.vue' /* webpackChunkName: "pages/dashboard/articles/_articleID" */))
const _1468bf77 = () => interopDefault(import('../pages/hymnal/search/_keyword.vue' /* webpackChunkName: "pages/hymnal/search/_keyword" */))
const _24ad13dc = () => interopDefault(import('../pages/library/following/_following.vue' /* webpackChunkName: "pages/library/following/_following" */))
const _d48a9f80 = () => interopDefault(import('../pages/promotions/create/_adType.vue' /* webpackChunkName: "pages/promotions/create/_adType" */))
const _0bb4586f = () => interopDefault(import('../pages/promotions/edit/_adId.vue' /* webpackChunkName: "pages/promotions/edit/_adId" */))
const _4cc3d4b8 = () => interopDefault(import('../pages/promotions/help/_helpPage.vue' /* webpackChunkName: "pages/promotions/help/_helpPage" */))
const _cc4846ae = () => interopDefault(import('../pages/promotions/orders/_adType.vue' /* webpackChunkName: "pages/promotions/orders/_adType" */))
const _3fc74d45 = () => interopDefault(import('../pages/promotions/stats/_adType.vue' /* webpackChunkName: "pages/promotions/stats/_adType" */))
const _d792bfe6 = () => interopDefault(import('../pages/psalter/search/_keyword.vue' /* webpackChunkName: "pages/psalter/search/_keyword" */))
const _342df156 = () => interopDefault(import('../pages/rewrite/psalter/_psalmID/index.vue' /* webpackChunkName: "pages/rewrite/psalter/_psalmID/index" */))
const _32e83920 = () => interopDefault(import('../pages/series/search/_search.vue' /* webpackChunkName: "pages/series/search/_search" */))
const _c2200eac = () => interopDefault(import('../pages/sermons/categories/_category.vue' /* webpackChunkName: "pages/sermons/categories/_category" */))
const _0a3fb2f3 = () => interopDefault(import('../pages/sermons/languages/_language.vue' /* webpackChunkName: "pages/sermons/languages/_language" */))
const _6adeb4ab = () => interopDefault(import('../pages/sermons/topics/_topic.vue' /* webpackChunkName: "pages/sermons/topics/_topic" */))
const _a7ce187e = () => interopDefault(import('../pages/speakers/alphabetical/_letter.vue' /* webpackChunkName: "pages/speakers/alphabetical/_letter" */))
const _9ce80b1a = () => interopDefault(import('../pages/speakers/search/_search.vue' /* webpackChunkName: "pages/speakers/search/_search" */))
const _b6304b54 = () => interopDefault(import('../pages/video-wall/broadcaster/_broadcasterID.vue' /* webpackChunkName: "pages/video-wall/broadcaster/_broadcasterID" */))
const _eb99b74a = () => interopDefault(import('../pages/video-wall/group/_group.vue' /* webpackChunkName: "pages/video-wall/group/_group" */))
const _1b714ec8 = () => interopDefault(import('../pages/rewrite/psalter/_psalmID/fullscreen.vue' /* webpackChunkName: "pages/rewrite/psalter/_psalmID/fullscreen" */))
const _7f334fd2 = () => interopDefault(import('../pages/sermons/date/_year/_month/_day.vue' /* webpackChunkName: "pages/sermons/date/_year/_month/_day" */))
const _5c44cfee = () => interopDefault(import('../pages/sermons/scripture/_book/_chapter/_verse.vue' /* webpackChunkName: "pages/sermons/scripture/_book/_chapter/_verse" */))
const _47ad48ff = () => interopDefault(import('../pages/events/_id/index.vue' /* webpackChunkName: "pages/events/_id/index" */))
const _ecabca44 = () => interopDefault(import('../pages/feed/_feedType.vue' /* webpackChunkName: "pages/feed/_feedType" */))
const _9445da00 = () => interopDefault(import('../pages/give/_broadcasterID.vue' /* webpackChunkName: "pages/give/_broadcasterID" */))
const _8dc459b0 = () => interopDefault(import('../pages/hashtag/_hashtag.vue' /* webpackChunkName: "pages/hashtag/_hashtag" */))
const _1a62e016 = () => interopDefault(import('../pages/hymnal/_hymnID/index.vue' /* webpackChunkName: "pages/hymnal/_hymnID/index" */))
const _4e624806 = () => interopDefault(import('../pages/news/_slug.vue' /* webpackChunkName: "pages/news/_slug" */))
const _8d76461c = () => interopDefault(import('../pages/psalter/_psalmID/index.vue' /* webpackChunkName: "pages/psalter/_psalmID/index" */))
const _1b03f26e = () => interopDefault(import('../pages/release-notes/_slug.vue' /* webpackChunkName: "pages/release-notes/_slug" */))
const _e8844a5e = () => interopDefault(import('../pages/series/_seriesID.vue' /* webpackChunkName: "pages/series/_seriesID" */))
const _797d7371 = () => interopDefault(import('../pages/webcasts/_broadcasterID.vue' /* webpackChunkName: "pages/webcasts/_broadcasterID" */))
const _859f9d62 = () => interopDefault(import('../pages/broadcasters/_broadcasterID/disabled.vue' /* webpackChunkName: "pages/broadcasters/_broadcasterID/disabled" */))
const _c338a614 = () => interopDefault(import('../pages/events/_id/registration-confirmation.vue' /* webpackChunkName: "pages/events/_id/registration-confirmation" */))
const _79c82a28 = () => interopDefault(import('../pages/hymnal/_hymnID/fullscreen.vue' /* webpackChunkName: "pages/hymnal/_hymnID/fullscreen" */))
const _a5c9196a = () => interopDefault(import('../pages/psalter/_psalmID/fullscreen.vue' /* webpackChunkName: "pages/psalter/_psalmID/fullscreen" */))
const _6606a6d8 = () => interopDefault(import('../pages/sermons/_sermonID/globalize.vue' /* webpackChunkName: "pages/sermons/_sermonID/globalize" */))
const _7a283a39 = () => interopDefault(import('../pages/article/_broadcasterID/_slug/index.vue' /* webpackChunkName: "pages/article/_broadcasterID/_slug/index" */))
const _ce4d03ae = () => interopDefault(import('../pages/broadcasters/_broadcasterID/_tab.vue' /* webpackChunkName: "pages/broadcasters/_broadcasterID/_tab" */))
const _94394270 = () => interopDefault(import('../pages/sermons/_sermonID/_mediaType.vue' /* webpackChunkName: "pages/sermons/_sermonID/_mediaType" */))
const _49aa6302 = () => interopDefault(import('../pages/speakers/_speaker/_tab.vue' /* webpackChunkName: "pages/speakers/_speaker/_tab" */))
const _3f2e5e94 = () => interopDefault(import('../pages/article/_broadcasterID/_slug/print.vue' /* webpackChunkName: "pages/article/_broadcasterID/_slug/print" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/activate",
    component: _12ee7daa,
    name: "activate___en"
  }, {
    path: "/articles-of-faith",
    component: _4671f594,
    name: "articles-of-faith___en"
  }, {
    path: "/broadcasters",
    component: _70c9f600,
    name: "broadcasters___en"
  }, {
    path: "/comments",
    component: _6e3661ac,
    name: "comments___en"
  }, {
    path: "/daily-devotional",
    component: _34e3f925,
    name: "daily-devotional___en"
  }, {
    path: "/dashboard",
    component: _7cd40ce2,
    name: "dashboard___en"
  }, {
    path: "/de",
    component: _bf4be96c,
    name: "index___de"
  }, {
    path: "/es",
    component: _bf4be96c,
    name: "index___es"
  }, {
    path: "/fr",
    component: _bf4be96c,
    name: "index___fr"
  }, {
    path: "/gb",
    component: _bf4be96c,
    name: "index___gb"
  }, {
    path: "/hi",
    component: _bf4be96c,
    name: "index___hi"
  }, {
    path: "/hymnal",
    component: _512757d0,
    name: "hymnal___en"
  }, {
    path: "/it",
    component: _bf4be96c,
    name: "index___it"
  }, {
    path: "/km",
    component: _bf4be96c,
    name: "index___km"
  }, {
    path: "/ko",
    component: _bf4be96c,
    name: "index___ko"
  }, {
    path: "/library",
    component: _bb2eb214,
    name: "library___en"
  }, {
    path: "/mychurch",
    component: _4fef928b,
    name: "mychurch___en"
  }, {
    path: "/news",
    component: _5018e94e,
    name: "news___en"
  }, {
    path: "/privacy-policy",
    component: _36f3c54f,
    name: "privacy-policy___en"
  }, {
    path: "/promotions",
    component: _4851ce6b,
    name: "promotions___en"
  }, {
    path: "/psalter",
    component: _4d27d82e,
    name: "psalter___en"
  }, {
    path: "/pt",
    component: _bf4be96c,
    name: "index___pt"
  }, {
    path: "/radio",
    component: _d2b18c1a,
    name: "radio___en"
  }, {
    path: "/release-notes",
    component: _1cba93b6,
    name: "release-notes___en"
  }, {
    path: "/search",
    component: _16a63760,
    name: "search___en"
  }, {
    path: "/series",
    component: _7559d7f2,
    name: "series___en"
  }, {
    path: "/sermons",
    component: _17a0eafc,
    name: "sermons___en"
  }, {
    path: "/sitemap",
    component: _52792dad,
    name: "sitemap___en"
  }, {
    path: "/speakers",
    component: _78ca418f,
    name: "speakers___en"
  }, {
    path: "/vault",
    component: _7067e4aa,
    name: "vault___en"
  }, {
    path: "/webcasts",
    component: _6aaa407b,
    name: "webcasts___en"
  }, {
    path: "/zh",
    component: _bf4be96c,
    name: "index___zh"
  }, {
    path: "/admin/api",
    component: _0caf89d2,
    name: "admin-api___en"
  }, {
    path: "/admin/classic-embeds",
    component: _7283484d,
    name: "admin-classic-embeds___en"
  }, {
    path: "/admin/metadata",
    component: _65765827,
    name: "admin-metadata___en"
  }, {
    path: "/admin/speakers",
    component: _517b62cc,
    name: "admin-speakers___en"
  }, {
    path: "/broadcaster/linking-users",
    component: _59df70fa,
    name: "broadcaster-linking-users___en"
  }, {
    path: "/broadcaster/select",
    component: _2ecdac35,
    name: "broadcaster-select___en"
  }, {
    path: "/broadcaster/sign-in",
    component: _05b164cc,
    name: "broadcaster-sign-in___en"
  }, {
    path: "/broadcaster/sign-up",
    component: _1a47bb42,
    name: "broadcaster-sign-up___en"
  }, {
    path: "/broadcasters/featured",
    component: _ef664520,
    name: "broadcasters-featured___en"
  }, {
    path: "/broadcasters/group",
    component: _3cb3b620,
    name: "broadcasters-group___en"
  }, {
    path: "/broadcasters/international",
    component: _460fd288,
    name: "broadcasters-international___en"
  }, {
    path: "/broadcasters/podcast",
    component: _3888a8b2,
    name: "broadcasters-podcast___en"
  }, {
    path: "/broadcasters/school",
    component: _07b8b794,
    name: "broadcasters-school___en"
  }, {
    path: "/broadcasters/seminary",
    component: _8490e02c,
    name: "broadcasters-seminary___en"
  }, {
    path: "/broadcasters/usa",
    component: _b654e29e,
    name: "broadcasters-usa___en"
  }, {
    path: "/broadcasters/vacant",
    component: _799441de,
    name: "broadcasters-vacant___en"
  }, {
    path: "/dashboard/articles",
    component: _ddff98da,
    name: "dashboard-articles___en"
  }, {
    path: "/de/activate",
    component: _12ee7daa,
    name: "activate___de"
  }, {
    path: "/de/articles-of-faith",
    component: _4671f594,
    name: "articles-of-faith___de"
  }, {
    path: "/de/broadcasters",
    component: _70c9f600,
    name: "broadcasters___de"
  }, {
    path: "/de/comments",
    component: _6e3661ac,
    name: "comments___de"
  }, {
    path: "/de/daily-devotional",
    component: _34e3f925,
    name: "daily-devotional___de"
  }, {
    path: "/de/dashboard",
    component: _7cd40ce2,
    name: "dashboard___de"
  }, {
    path: "/de/hymnal",
    component: _512757d0,
    name: "hymnal___de"
  }, {
    path: "/de/library",
    component: _bb2eb214,
    name: "library___de"
  }, {
    path: "/de/mychurch",
    component: _4fef928b,
    name: "mychurch___de"
  }, {
    path: "/de/news",
    component: _5018e94e,
    name: "news___de"
  }, {
    path: "/de/privacy-policy",
    component: _36f3c54f,
    name: "privacy-policy___de"
  }, {
    path: "/de/promotions",
    component: _4851ce6b,
    name: "promotions___de"
  }, {
    path: "/de/psalter",
    component: _4d27d82e,
    name: "psalter___de"
  }, {
    path: "/de/radio",
    component: _d2b18c1a,
    name: "radio___de"
  }, {
    path: "/de/release-notes",
    component: _1cba93b6,
    name: "release-notes___de"
  }, {
    path: "/de/search",
    component: _16a63760,
    name: "search___de"
  }, {
    path: "/de/series",
    component: _7559d7f2,
    name: "series___de"
  }, {
    path: "/de/sermons",
    component: _17a0eafc,
    name: "sermons___de"
  }, {
    path: "/de/sitemap",
    component: _52792dad,
    name: "sitemap___de"
  }, {
    path: "/de/speakers",
    component: _78ca418f,
    name: "speakers___de"
  }, {
    path: "/de/vault",
    component: _7067e4aa,
    name: "vault___de"
  }, {
    path: "/de/webcasts",
    component: _6aaa407b,
    name: "webcasts___de"
  }, {
    path: "/demo/asyncdata",
    component: _5dd50fb2,
    name: "demo-asyncdata___en"
  }, {
    path: "/demo/audio-embeds",
    component: _abedff96,
    name: "demo-audio-embeds___en"
  }, {
    path: "/demo/broadcaster",
    component: _fa06d88c,
    name: "demo-broadcaster___en"
  }, {
    path: "/demo/broadcaster-locations",
    component: _0bfe0aea,
    name: "demo-broadcaster-locations___en"
  }, {
    path: "/demo/buttons",
    component: _4fae7ae6,
    name: "demo-buttons___en"
  }, {
    path: "/demo/cornerstone",
    component: _26d5acbc,
    name: "demo-cornerstone___en"
  }, {
    path: "/demo/dates",
    component: _0178d6de,
    name: "demo-dates___en"
  }, {
    path: "/demo/device",
    component: _7596630c,
    name: "demo-device___en"
  }, {
    path: "/demo/legacy-urls",
    component: _52a4ac18,
    name: "demo-legacy-urls___en"
  }, {
    path: "/demo/md",
    component: _0ce85b3b,
    name: "demo-md___en"
  }, {
    path: "/demo/mde",
    component: _d9ed378c,
    name: "demo-mde___en"
  }, {
    path: "/demo/s3",
    component: _1472ef44,
    name: "demo-s3___en"
  }, {
    path: "/demo/search-pills",
    component: _3dce7199,
    name: "demo-search-pills___en"
  }, {
    path: "/demo/series",
    component: _7a2cb61b,
    name: "demo-series___en"
  }, {
    path: "/demo/sermon",
    component: _5e8fa3d0,
    name: "demo-sermon___en"
  }, {
    path: "/demo/speaker",
    component: _a5a417ea,
    name: "demo-speaker___en"
  }, {
    path: "/demo/speaker-picker",
    component: _6900f740,
    name: "demo-speaker-picker___en"
  }, {
    path: "/demo/video-embeds",
    component: _3aab1e70,
    name: "demo-video-embeds___en"
  }, {
    path: "/es/activate",
    component: _12ee7daa,
    name: "activate___es"
  }, {
    path: "/es/articles-of-faith",
    component: _4671f594,
    name: "articles-of-faith___es"
  }, {
    path: "/es/broadcasters",
    component: _70c9f600,
    name: "broadcasters___es"
  }, {
    path: "/es/comments",
    component: _6e3661ac,
    name: "comments___es"
  }, {
    path: "/es/daily-devotional",
    component: _34e3f925,
    name: "daily-devotional___es"
  }, {
    path: "/es/dashboard",
    component: _7cd40ce2,
    name: "dashboard___es"
  }, {
    path: "/es/hymnal",
    component: _512757d0,
    name: "hymnal___es"
  }, {
    path: "/es/library",
    component: _bb2eb214,
    name: "library___es"
  }, {
    path: "/es/mychurch",
    component: _4fef928b,
    name: "mychurch___es"
  }, {
    path: "/es/news",
    component: _5018e94e,
    name: "news___es"
  }, {
    path: "/es/privacy-policy",
    component: _36f3c54f,
    name: "privacy-policy___es"
  }, {
    path: "/es/promotions",
    component: _4851ce6b,
    name: "promotions___es"
  }, {
    path: "/es/psalter",
    component: _4d27d82e,
    name: "psalter___es"
  }, {
    path: "/es/radio",
    component: _d2b18c1a,
    name: "radio___es"
  }, {
    path: "/es/release-notes",
    component: _1cba93b6,
    name: "release-notes___es"
  }, {
    path: "/es/search",
    component: _16a63760,
    name: "search___es"
  }, {
    path: "/es/series",
    component: _7559d7f2,
    name: "series___es"
  }, {
    path: "/es/sermons",
    component: _17a0eafc,
    name: "sermons___es"
  }, {
    path: "/es/sitemap",
    component: _52792dad,
    name: "sitemap___es"
  }, {
    path: "/es/speakers",
    component: _78ca418f,
    name: "speakers___es"
  }, {
    path: "/es/vault",
    component: _7067e4aa,
    name: "vault___es"
  }, {
    path: "/es/webcasts",
    component: _6aaa407b,
    name: "webcasts___es"
  }, {
    path: "/fr/activate",
    component: _12ee7daa,
    name: "activate___fr"
  }, {
    path: "/fr/articles-of-faith",
    component: _4671f594,
    name: "articles-of-faith___fr"
  }, {
    path: "/fr/broadcasters",
    component: _70c9f600,
    name: "broadcasters___fr"
  }, {
    path: "/fr/comments",
    component: _6e3661ac,
    name: "comments___fr"
  }, {
    path: "/fr/daily-devotional",
    component: _34e3f925,
    name: "daily-devotional___fr"
  }, {
    path: "/fr/dashboard",
    component: _7cd40ce2,
    name: "dashboard___fr"
  }, {
    path: "/fr/hymnal",
    component: _512757d0,
    name: "hymnal___fr"
  }, {
    path: "/fr/library",
    component: _bb2eb214,
    name: "library___fr"
  }, {
    path: "/fr/mychurch",
    component: _4fef928b,
    name: "mychurch___fr"
  }, {
    path: "/fr/news",
    component: _5018e94e,
    name: "news___fr"
  }, {
    path: "/fr/privacy-policy",
    component: _36f3c54f,
    name: "privacy-policy___fr"
  }, {
    path: "/fr/promotions",
    component: _4851ce6b,
    name: "promotions___fr"
  }, {
    path: "/fr/psalter",
    component: _4d27d82e,
    name: "psalter___fr"
  }, {
    path: "/fr/radio",
    component: _d2b18c1a,
    name: "radio___fr"
  }, {
    path: "/fr/release-notes",
    component: _1cba93b6,
    name: "release-notes___fr"
  }, {
    path: "/fr/search",
    component: _16a63760,
    name: "search___fr"
  }, {
    path: "/fr/series",
    component: _7559d7f2,
    name: "series___fr"
  }, {
    path: "/fr/sermons",
    component: _17a0eafc,
    name: "sermons___fr"
  }, {
    path: "/fr/sitemap",
    component: _52792dad,
    name: "sitemap___fr"
  }, {
    path: "/fr/speakers",
    component: _78ca418f,
    name: "speakers___fr"
  }, {
    path: "/fr/vault",
    component: _7067e4aa,
    name: "vault___fr"
  }, {
    path: "/fr/webcasts",
    component: _6aaa407b,
    name: "webcasts___fr"
  }, {
    path: "/gb/activate",
    component: _12ee7daa,
    name: "activate___gb"
  }, {
    path: "/gb/articles-of-faith",
    component: _4671f594,
    name: "articles-of-faith___gb"
  }, {
    path: "/gb/broadcasters",
    component: _70c9f600,
    name: "broadcasters___gb"
  }, {
    path: "/gb/comments",
    component: _6e3661ac,
    name: "comments___gb"
  }, {
    path: "/gb/daily-devotional",
    component: _34e3f925,
    name: "daily-devotional___gb"
  }, {
    path: "/gb/dashboard",
    component: _7cd40ce2,
    name: "dashboard___gb"
  }, {
    path: "/gb/hymnal",
    component: _512757d0,
    name: "hymnal___gb"
  }, {
    path: "/gb/library",
    component: _bb2eb214,
    name: "library___gb"
  }, {
    path: "/gb/mychurch",
    component: _4fef928b,
    name: "mychurch___gb"
  }, {
    path: "/gb/news",
    component: _5018e94e,
    name: "news___gb"
  }, {
    path: "/gb/privacy-policy",
    component: _36f3c54f,
    name: "privacy-policy___gb"
  }, {
    path: "/gb/promotions",
    component: _4851ce6b,
    name: "promotions___gb"
  }, {
    path: "/gb/psalter",
    component: _4d27d82e,
    name: "psalter___gb"
  }, {
    path: "/gb/radio",
    component: _d2b18c1a,
    name: "radio___gb"
  }, {
    path: "/gb/release-notes",
    component: _1cba93b6,
    name: "release-notes___gb"
  }, {
    path: "/gb/search",
    component: _16a63760,
    name: "search___gb"
  }, {
    path: "/gb/series",
    component: _7559d7f2,
    name: "series___gb"
  }, {
    path: "/gb/sermons",
    component: _17a0eafc,
    name: "sermons___gb"
  }, {
    path: "/gb/sitemap",
    component: _52792dad,
    name: "sitemap___gb"
  }, {
    path: "/gb/speakers",
    component: _78ca418f,
    name: "speakers___gb"
  }, {
    path: "/gb/vault",
    component: _7067e4aa,
    name: "vault___gb"
  }, {
    path: "/gb/webcasts",
    component: _6aaa407b,
    name: "webcasts___gb"
  }, {
    path: "/hi/activate",
    component: _12ee7daa,
    name: "activate___hi"
  }, {
    path: "/hi/articles-of-faith",
    component: _4671f594,
    name: "articles-of-faith___hi"
  }, {
    path: "/hi/broadcasters",
    component: _70c9f600,
    name: "broadcasters___hi"
  }, {
    path: "/hi/comments",
    component: _6e3661ac,
    name: "comments___hi"
  }, {
    path: "/hi/daily-devotional",
    component: _34e3f925,
    name: "daily-devotional___hi"
  }, {
    path: "/hi/dashboard",
    component: _7cd40ce2,
    name: "dashboard___hi"
  }, {
    path: "/hi/hymnal",
    component: _512757d0,
    name: "hymnal___hi"
  }, {
    path: "/hi/library",
    component: _bb2eb214,
    name: "library___hi"
  }, {
    path: "/hi/mychurch",
    component: _4fef928b,
    name: "mychurch___hi"
  }, {
    path: "/hi/news",
    component: _5018e94e,
    name: "news___hi"
  }, {
    path: "/hi/privacy-policy",
    component: _36f3c54f,
    name: "privacy-policy___hi"
  }, {
    path: "/hi/promotions",
    component: _4851ce6b,
    name: "promotions___hi"
  }, {
    path: "/hi/psalter",
    component: _4d27d82e,
    name: "psalter___hi"
  }, {
    path: "/hi/radio",
    component: _d2b18c1a,
    name: "radio___hi"
  }, {
    path: "/hi/release-notes",
    component: _1cba93b6,
    name: "release-notes___hi"
  }, {
    path: "/hi/search",
    component: _16a63760,
    name: "search___hi"
  }, {
    path: "/hi/series",
    component: _7559d7f2,
    name: "series___hi"
  }, {
    path: "/hi/sermons",
    component: _17a0eafc,
    name: "sermons___hi"
  }, {
    path: "/hi/sitemap",
    component: _52792dad,
    name: "sitemap___hi"
  }, {
    path: "/hi/speakers",
    component: _78ca418f,
    name: "speakers___hi"
  }, {
    path: "/hi/vault",
    component: _7067e4aa,
    name: "vault___hi"
  }, {
    path: "/hi/webcasts",
    component: _6aaa407b,
    name: "webcasts___hi"
  }, {
    path: "/it/activate",
    component: _12ee7daa,
    name: "activate___it"
  }, {
    path: "/it/articles-of-faith",
    component: _4671f594,
    name: "articles-of-faith___it"
  }, {
    path: "/it/broadcasters",
    component: _70c9f600,
    name: "broadcasters___it"
  }, {
    path: "/it/comments",
    component: _6e3661ac,
    name: "comments___it"
  }, {
    path: "/it/daily-devotional",
    component: _34e3f925,
    name: "daily-devotional___it"
  }, {
    path: "/it/dashboard",
    component: _7cd40ce2,
    name: "dashboard___it"
  }, {
    path: "/it/hymnal",
    component: _512757d0,
    name: "hymnal___it"
  }, {
    path: "/it/library",
    component: _bb2eb214,
    name: "library___it"
  }, {
    path: "/it/mychurch",
    component: _4fef928b,
    name: "mychurch___it"
  }, {
    path: "/it/news",
    component: _5018e94e,
    name: "news___it"
  }, {
    path: "/it/privacy-policy",
    component: _36f3c54f,
    name: "privacy-policy___it"
  }, {
    path: "/it/promotions",
    component: _4851ce6b,
    name: "promotions___it"
  }, {
    path: "/it/psalter",
    component: _4d27d82e,
    name: "psalter___it"
  }, {
    path: "/it/radio",
    component: _d2b18c1a,
    name: "radio___it"
  }, {
    path: "/it/release-notes",
    component: _1cba93b6,
    name: "release-notes___it"
  }, {
    path: "/it/search",
    component: _16a63760,
    name: "search___it"
  }, {
    path: "/it/series",
    component: _7559d7f2,
    name: "series___it"
  }, {
    path: "/it/sermons",
    component: _17a0eafc,
    name: "sermons___it"
  }, {
    path: "/it/sitemap",
    component: _52792dad,
    name: "sitemap___it"
  }, {
    path: "/it/speakers",
    component: _78ca418f,
    name: "speakers___it"
  }, {
    path: "/it/vault",
    component: _7067e4aa,
    name: "vault___it"
  }, {
    path: "/it/webcasts",
    component: _6aaa407b,
    name: "webcasts___it"
  }, {
    path: "/km/activate",
    component: _12ee7daa,
    name: "activate___km"
  }, {
    path: "/km/articles-of-faith",
    component: _4671f594,
    name: "articles-of-faith___km"
  }, {
    path: "/km/broadcasters",
    component: _70c9f600,
    name: "broadcasters___km"
  }, {
    path: "/km/comments",
    component: _6e3661ac,
    name: "comments___km"
  }, {
    path: "/km/daily-devotional",
    component: _34e3f925,
    name: "daily-devotional___km"
  }, {
    path: "/km/dashboard",
    component: _7cd40ce2,
    name: "dashboard___km"
  }, {
    path: "/km/hymnal",
    component: _512757d0,
    name: "hymnal___km"
  }, {
    path: "/km/library",
    component: _bb2eb214,
    name: "library___km"
  }, {
    path: "/km/mychurch",
    component: _4fef928b,
    name: "mychurch___km"
  }, {
    path: "/km/news",
    component: _5018e94e,
    name: "news___km"
  }, {
    path: "/km/privacy-policy",
    component: _36f3c54f,
    name: "privacy-policy___km"
  }, {
    path: "/km/promotions",
    component: _4851ce6b,
    name: "promotions___km"
  }, {
    path: "/km/psalter",
    component: _4d27d82e,
    name: "psalter___km"
  }, {
    path: "/km/radio",
    component: _d2b18c1a,
    name: "radio___km"
  }, {
    path: "/km/release-notes",
    component: _1cba93b6,
    name: "release-notes___km"
  }, {
    path: "/km/search",
    component: _16a63760,
    name: "search___km"
  }, {
    path: "/km/series",
    component: _7559d7f2,
    name: "series___km"
  }, {
    path: "/km/sermons",
    component: _17a0eafc,
    name: "sermons___km"
  }, {
    path: "/km/sitemap",
    component: _52792dad,
    name: "sitemap___km"
  }, {
    path: "/km/speakers",
    component: _78ca418f,
    name: "speakers___km"
  }, {
    path: "/km/vault",
    component: _7067e4aa,
    name: "vault___km"
  }, {
    path: "/km/webcasts",
    component: _6aaa407b,
    name: "webcasts___km"
  }, {
    path: "/ko/activate",
    component: _12ee7daa,
    name: "activate___ko"
  }, {
    path: "/ko/articles-of-faith",
    component: _4671f594,
    name: "articles-of-faith___ko"
  }, {
    path: "/ko/broadcasters",
    component: _70c9f600,
    name: "broadcasters___ko"
  }, {
    path: "/ko/comments",
    component: _6e3661ac,
    name: "comments___ko"
  }, {
    path: "/ko/daily-devotional",
    component: _34e3f925,
    name: "daily-devotional___ko"
  }, {
    path: "/ko/dashboard",
    component: _7cd40ce2,
    name: "dashboard___ko"
  }, {
    path: "/ko/hymnal",
    component: _512757d0,
    name: "hymnal___ko"
  }, {
    path: "/ko/library",
    component: _bb2eb214,
    name: "library___ko"
  }, {
    path: "/ko/mychurch",
    component: _4fef928b,
    name: "mychurch___ko"
  }, {
    path: "/ko/news",
    component: _5018e94e,
    name: "news___ko"
  }, {
    path: "/ko/privacy-policy",
    component: _36f3c54f,
    name: "privacy-policy___ko"
  }, {
    path: "/ko/promotions",
    component: _4851ce6b,
    name: "promotions___ko"
  }, {
    path: "/ko/psalter",
    component: _4d27d82e,
    name: "psalter___ko"
  }, {
    path: "/ko/radio",
    component: _d2b18c1a,
    name: "radio___ko"
  }, {
    path: "/ko/release-notes",
    component: _1cba93b6,
    name: "release-notes___ko"
  }, {
    path: "/ko/search",
    component: _16a63760,
    name: "search___ko"
  }, {
    path: "/ko/series",
    component: _7559d7f2,
    name: "series___ko"
  }, {
    path: "/ko/sermons",
    component: _17a0eafc,
    name: "sermons___ko"
  }, {
    path: "/ko/sitemap",
    component: _52792dad,
    name: "sitemap___ko"
  }, {
    path: "/ko/speakers",
    component: _78ca418f,
    name: "speakers___ko"
  }, {
    path: "/ko/vault",
    component: _7067e4aa,
    name: "vault___ko"
  }, {
    path: "/ko/webcasts",
    component: _6aaa407b,
    name: "webcasts___ko"
  }, {
    path: "/library/bookmarks",
    component: _6ff6cca1,
    name: "library-bookmarks___en"
  }, {
    path: "/library/comments",
    component: _5fa7d300,
    name: "library-comments___en"
  }, {
    path: "/library/history",
    component: _a2928290,
    name: "library-history___en"
  }, {
    path: "/pt/activate",
    component: _12ee7daa,
    name: "activate___pt"
  }, {
    path: "/pt/articles-of-faith",
    component: _4671f594,
    name: "articles-of-faith___pt"
  }, {
    path: "/pt/broadcasters",
    component: _70c9f600,
    name: "broadcasters___pt"
  }, {
    path: "/pt/comments",
    component: _6e3661ac,
    name: "comments___pt"
  }, {
    path: "/pt/daily-devotional",
    component: _34e3f925,
    name: "daily-devotional___pt"
  }, {
    path: "/pt/dashboard",
    component: _7cd40ce2,
    name: "dashboard___pt"
  }, {
    path: "/pt/hymnal",
    component: _512757d0,
    name: "hymnal___pt"
  }, {
    path: "/pt/library",
    component: _bb2eb214,
    name: "library___pt"
  }, {
    path: "/pt/mychurch",
    component: _4fef928b,
    name: "mychurch___pt"
  }, {
    path: "/pt/news",
    component: _5018e94e,
    name: "news___pt"
  }, {
    path: "/pt/privacy-policy",
    component: _36f3c54f,
    name: "privacy-policy___pt"
  }, {
    path: "/pt/promotions",
    component: _4851ce6b,
    name: "promotions___pt"
  }, {
    path: "/pt/psalter",
    component: _4d27d82e,
    name: "psalter___pt"
  }, {
    path: "/pt/radio",
    component: _d2b18c1a,
    name: "radio___pt"
  }, {
    path: "/pt/release-notes",
    component: _1cba93b6,
    name: "release-notes___pt"
  }, {
    path: "/pt/search",
    component: _16a63760,
    name: "search___pt"
  }, {
    path: "/pt/series",
    component: _7559d7f2,
    name: "series___pt"
  }, {
    path: "/pt/sermons",
    component: _17a0eafc,
    name: "sermons___pt"
  }, {
    path: "/pt/sitemap",
    component: _52792dad,
    name: "sitemap___pt"
  }, {
    path: "/pt/speakers",
    component: _78ca418f,
    name: "speakers___pt"
  }, {
    path: "/pt/vault",
    component: _7067e4aa,
    name: "vault___pt"
  }, {
    path: "/pt/webcasts",
    component: _6aaa407b,
    name: "webcasts___pt"
  }, {
    path: "/rewrite/psalter",
    component: _5f623b2a,
    name: "rewrite-psalter___en"
  }, {
    path: "/series/picks",
    component: _50e36392,
    name: "series-picks___en"
  }, {
    path: "/series/podcasts",
    component: _64c40302,
    name: "series-podcasts___en"
  }, {
    path: "/series/recent",
    component: _20d64eeb,
    name: "series-recent___en"
  }, {
    path: "/sermons/categories",
    component: _ac2f59c2,
    name: "sermons-categories___en"
  }, {
    path: "/sermons/clips",
    component: _745ecf5a,
    name: "sermons-clips___en"
  }, {
    path: "/sermons/date",
    component: _681d7c31,
    name: "sermons-date___en"
  }, {
    path: "/sermons/featured",
    component: _5fe950ae,
    name: "sermons-featured___en"
  }, {
    path: "/sermons/languages",
    component: _4beac38e,
    name: "sermons-languages___en"
  }, {
    path: "/sermons/scripture",
    component: _714574a0,
    name: "sermons-scripture___en"
  }, {
    path: "/sermons/staff-picks",
    component: _66663175,
    name: "sermons-staff-picks___en"
  }, {
    path: "/sermons/topics",
    component: _176e7027,
    name: "sermons-topics___en"
  }, {
    path: "/sermons/transcripts",
    component: _38c3a1ad,
    name: "sermons-transcripts___en"
  }, {
    path: "/sermons/videos",
    component: _08765cd8,
    name: "sermons-videos___en"
  }, {
    path: "/speakers/choice",
    component: _d2fdc598,
    name: "speakers-choice___en"
  }, {
    path: "/speakers/classic",
    component: _55d73c0f,
    name: "speakers-classic___en"
  }, {
    path: "/speakers/featured",
    component: _9f75bafe,
    name: "speakers-featured___en"
  }, {
    path: "/speakers/puritans",
    component: _4a746441,
    name: "speakers-puritans___en"
  }, {
    path: "/user/about",
    component: _49399981,
    name: "user-about___en"
  }, {
    path: "/user/login",
    component: _3299175d,
    name: "user-login___en"
  }, {
    path: "/user/logout",
    component: _6d9adcc6,
    name: "user-logout___en"
  }, {
    path: "/user/reset-password",
    component: _269a0d15,
    name: "user-reset-password___en"
  }, {
    path: "/user/settings",
    component: _695ab5df,
    name: "user-settings___en"
  }, {
    path: "/user/verify",
    component: _4dc24035,
    name: "user-verify___en"
  }, {
    path: "/video-wall/admin",
    component: _1319d1d8,
    name: "video-wall-admin___en"
  }, {
    path: "/video-wall/qr",
    component: _d246a2a8,
    name: "video-wall-qr___en"
  }, {
    path: "/webcasts/recent",
    component: _54da10fc,
    name: "webcasts-recent___en"
  }, {
    path: "/zh/activate",
    component: _12ee7daa,
    name: "activate___zh"
  }, {
    path: "/zh/articles-of-faith",
    component: _4671f594,
    name: "articles-of-faith___zh"
  }, {
    path: "/zh/broadcasters",
    component: _70c9f600,
    name: "broadcasters___zh"
  }, {
    path: "/zh/comments",
    component: _6e3661ac,
    name: "comments___zh"
  }, {
    path: "/zh/daily-devotional",
    component: _34e3f925,
    name: "daily-devotional___zh"
  }, {
    path: "/zh/dashboard",
    component: _7cd40ce2,
    name: "dashboard___zh"
  }, {
    path: "/zh/hymnal",
    component: _512757d0,
    name: "hymnal___zh"
  }, {
    path: "/zh/library",
    component: _bb2eb214,
    name: "library___zh"
  }, {
    path: "/zh/mychurch",
    component: _4fef928b,
    name: "mychurch___zh"
  }, {
    path: "/zh/news",
    component: _5018e94e,
    name: "news___zh"
  }, {
    path: "/zh/privacy-policy",
    component: _36f3c54f,
    name: "privacy-policy___zh"
  }, {
    path: "/zh/promotions",
    component: _4851ce6b,
    name: "promotions___zh"
  }, {
    path: "/zh/psalter",
    component: _4d27d82e,
    name: "psalter___zh"
  }, {
    path: "/zh/radio",
    component: _d2b18c1a,
    name: "radio___zh"
  }, {
    path: "/zh/release-notes",
    component: _1cba93b6,
    name: "release-notes___zh"
  }, {
    path: "/zh/search",
    component: _16a63760,
    name: "search___zh"
  }, {
    path: "/zh/series",
    component: _7559d7f2,
    name: "series___zh"
  }, {
    path: "/zh/sermons",
    component: _17a0eafc,
    name: "sermons___zh"
  }, {
    path: "/zh/sitemap",
    component: _52792dad,
    name: "sitemap___zh"
  }, {
    path: "/zh/speakers",
    component: _78ca418f,
    name: "speakers___zh"
  }, {
    path: "/zh/vault",
    component: _7067e4aa,
    name: "vault___zh"
  }, {
    path: "/zh/webcasts",
    component: _6aaa407b,
    name: "webcasts___zh"
  }, {
    path: "/dashboard/account/customization",
    component: _8e303aa4,
    name: "dashboard-account-customization___en"
  }, {
    path: "/dashboard/account/donations",
    component: _b01caf08,
    name: "dashboard-account-donations___en"
  }, {
    path: "/dashboard/account/email-settings",
    component: _6353ca69,
    name: "dashboard-account-email-settings___en"
  }, {
    path: "/dashboard/account/managers",
    component: _5bb8fe5b,
    name: "dashboard-account-managers___en"
  }, {
    path: "/dashboard/account/settings",
    component: _3157ad98,
    name: "dashboard-account-settings___en"
  }, {
    path: "/dashboard/django/articles",
    component: _3c962d1e,
    name: "dashboard-django-articles___en"
  }, {
    path: "/dashboard/django/customization",
    component: _45933172,
    name: "dashboard-django-customization___en"
  }, {
    path: "/dashboard/django/donations",
    component: _6efaf940,
    name: "dashboard-django-donations___en"
  }, {
    path: "/dashboard/django/email-settings",
    component: _6c125425,
    name: "dashboard-django-email-settings___en"
  }, {
    path: "/dashboard/django/managers",
    component: _51a08517,
    name: "dashboard-django-managers___en"
  }, {
    path: "/dashboard/django/settings",
    component: _273f3454,
    name: "dashboard-django-settings___en"
  }, {
    path: "/dashboard/embed/link-user",
    component: _127bbd85,
    name: "dashboard-embed-link-user___en"
  }, {
    path: "/dashboard/embed/payment-method-input",
    component: _73465e64,
    name: "dashboard-embed-payment-method-input___en"
  }, {
    path: "/dashboard/embed/user-dropdown",
    component: _3b84a19a,
    name: "dashboard-embed-user-dropdown___en"
  }, {
    path: "/de/admin/api",
    component: _0caf89d2,
    name: "admin-api___de"
  }, {
    path: "/de/admin/classic-embeds",
    component: _7283484d,
    name: "admin-classic-embeds___de"
  }, {
    path: "/de/admin/metadata",
    component: _65765827,
    name: "admin-metadata___de"
  }, {
    path: "/de/admin/speakers",
    component: _517b62cc,
    name: "admin-speakers___de"
  }, {
    path: "/de/broadcaster/linking-users",
    component: _59df70fa,
    name: "broadcaster-linking-users___de"
  }, {
    path: "/de/broadcaster/select",
    component: _2ecdac35,
    name: "broadcaster-select___de"
  }, {
    path: "/de/broadcaster/sign-in",
    component: _05b164cc,
    name: "broadcaster-sign-in___de"
  }, {
    path: "/de/broadcaster/sign-up",
    component: _1a47bb42,
    name: "broadcaster-sign-up___de"
  }, {
    path: "/de/broadcasters/featured",
    component: _ef664520,
    name: "broadcasters-featured___de"
  }, {
    path: "/de/broadcasters/group",
    component: _3cb3b620,
    name: "broadcasters-group___de"
  }, {
    path: "/de/broadcasters/international",
    component: _460fd288,
    name: "broadcasters-international___de"
  }, {
    path: "/de/broadcasters/podcast",
    component: _3888a8b2,
    name: "broadcasters-podcast___de"
  }, {
    path: "/de/broadcasters/school",
    component: _07b8b794,
    name: "broadcasters-school___de"
  }, {
    path: "/de/broadcasters/seminary",
    component: _8490e02c,
    name: "broadcasters-seminary___de"
  }, {
    path: "/de/broadcasters/usa",
    component: _b654e29e,
    name: "broadcasters-usa___de"
  }, {
    path: "/de/broadcasters/vacant",
    component: _799441de,
    name: "broadcasters-vacant___de"
  }, {
    path: "/de/dashboard/articles",
    component: _ddff98da,
    name: "dashboard-articles___de"
  }, {
    path: "/de/demo/asyncdata",
    component: _5dd50fb2,
    name: "demo-asyncdata___de"
  }, {
    path: "/de/demo/audio-embeds",
    component: _abedff96,
    name: "demo-audio-embeds___de"
  }, {
    path: "/de/demo/broadcaster",
    component: _fa06d88c,
    name: "demo-broadcaster___de"
  }, {
    path: "/de/demo/broadcaster-locations",
    component: _0bfe0aea,
    name: "demo-broadcaster-locations___de"
  }, {
    path: "/de/demo/buttons",
    component: _4fae7ae6,
    name: "demo-buttons___de"
  }, {
    path: "/de/demo/cornerstone",
    component: _26d5acbc,
    name: "demo-cornerstone___de"
  }, {
    path: "/de/demo/dates",
    component: _0178d6de,
    name: "demo-dates___de"
  }, {
    path: "/de/demo/device",
    component: _7596630c,
    name: "demo-device___de"
  }, {
    path: "/de/demo/legacy-urls",
    component: _52a4ac18,
    name: "demo-legacy-urls___de"
  }, {
    path: "/de/demo/md",
    component: _0ce85b3b,
    name: "demo-md___de"
  }, {
    path: "/de/demo/mde",
    component: _d9ed378c,
    name: "demo-mde___de"
  }, {
    path: "/de/demo/s3",
    component: _1472ef44,
    name: "demo-s3___de"
  }, {
    path: "/de/demo/search-pills",
    component: _3dce7199,
    name: "demo-search-pills___de"
  }, {
    path: "/de/demo/series",
    component: _7a2cb61b,
    name: "demo-series___de"
  }, {
    path: "/de/demo/sermon",
    component: _5e8fa3d0,
    name: "demo-sermon___de"
  }, {
    path: "/de/demo/speaker",
    component: _a5a417ea,
    name: "demo-speaker___de"
  }, {
    path: "/de/demo/speaker-picker",
    component: _6900f740,
    name: "demo-speaker-picker___de"
  }, {
    path: "/de/demo/video-embeds",
    component: _3aab1e70,
    name: "demo-video-embeds___de"
  }, {
    path: "/de/library/bookmarks",
    component: _6ff6cca1,
    name: "library-bookmarks___de"
  }, {
    path: "/de/library/comments",
    component: _5fa7d300,
    name: "library-comments___de"
  }, {
    path: "/de/library/history",
    component: _a2928290,
    name: "library-history___de"
  }, {
    path: "/de/rewrite/psalter",
    component: _5f623b2a,
    name: "rewrite-psalter___de"
  }, {
    path: "/de/series/picks",
    component: _50e36392,
    name: "series-picks___de"
  }, {
    path: "/de/series/podcasts",
    component: _64c40302,
    name: "series-podcasts___de"
  }, {
    path: "/de/series/recent",
    component: _20d64eeb,
    name: "series-recent___de"
  }, {
    path: "/de/sermons/categories",
    component: _ac2f59c2,
    name: "sermons-categories___de"
  }, {
    path: "/de/sermons/clips",
    component: _745ecf5a,
    name: "sermons-clips___de"
  }, {
    path: "/de/sermons/date",
    component: _681d7c31,
    name: "sermons-date___de"
  }, {
    path: "/de/sermons/featured",
    component: _5fe950ae,
    name: "sermons-featured___de"
  }, {
    path: "/de/sermons/languages",
    component: _4beac38e,
    name: "sermons-languages___de"
  }, {
    path: "/de/sermons/scripture",
    component: _714574a0,
    name: "sermons-scripture___de"
  }, {
    path: "/de/sermons/staff-picks",
    component: _66663175,
    name: "sermons-staff-picks___de"
  }, {
    path: "/de/sermons/topics",
    component: _176e7027,
    name: "sermons-topics___de"
  }, {
    path: "/de/sermons/transcripts",
    component: _38c3a1ad,
    name: "sermons-transcripts___de"
  }, {
    path: "/de/sermons/videos",
    component: _08765cd8,
    name: "sermons-videos___de"
  }, {
    path: "/de/speakers/choice",
    component: _d2fdc598,
    name: "speakers-choice___de"
  }, {
    path: "/de/speakers/classic",
    component: _55d73c0f,
    name: "speakers-classic___de"
  }, {
    path: "/de/speakers/featured",
    component: _9f75bafe,
    name: "speakers-featured___de"
  }, {
    path: "/de/speakers/puritans",
    component: _4a746441,
    name: "speakers-puritans___de"
  }, {
    path: "/de/user/about",
    component: _49399981,
    name: "user-about___de"
  }, {
    path: "/de/user/login",
    component: _3299175d,
    name: "user-login___de"
  }, {
    path: "/de/user/logout",
    component: _6d9adcc6,
    name: "user-logout___de"
  }, {
    path: "/de/user/reset-password",
    component: _269a0d15,
    name: "user-reset-password___de"
  }, {
    path: "/de/user/settings",
    component: _695ab5df,
    name: "user-settings___de"
  }, {
    path: "/de/user/verify",
    component: _4dc24035,
    name: "user-verify___de"
  }, {
    path: "/de/video-wall/admin",
    component: _1319d1d8,
    name: "video-wall-admin___de"
  }, {
    path: "/de/video-wall/qr",
    component: _d246a2a8,
    name: "video-wall-qr___de"
  }, {
    path: "/de/webcasts/recent",
    component: _54da10fc,
    name: "webcasts-recent___de"
  }, {
    path: "/embed/classic/examples",
    component: _08602154,
    name: "embed-classic-examples___en"
  }, {
    path: "/es/admin/api",
    component: _0caf89d2,
    name: "admin-api___es"
  }, {
    path: "/es/admin/classic-embeds",
    component: _7283484d,
    name: "admin-classic-embeds___es"
  }, {
    path: "/es/admin/metadata",
    component: _65765827,
    name: "admin-metadata___es"
  }, {
    path: "/es/admin/speakers",
    component: _517b62cc,
    name: "admin-speakers___es"
  }, {
    path: "/es/broadcaster/linking-users",
    component: _59df70fa,
    name: "broadcaster-linking-users___es"
  }, {
    path: "/es/broadcaster/select",
    component: _2ecdac35,
    name: "broadcaster-select___es"
  }, {
    path: "/es/broadcaster/sign-in",
    component: _05b164cc,
    name: "broadcaster-sign-in___es"
  }, {
    path: "/es/broadcaster/sign-up",
    component: _1a47bb42,
    name: "broadcaster-sign-up___es"
  }, {
    path: "/es/broadcasters/featured",
    component: _ef664520,
    name: "broadcasters-featured___es"
  }, {
    path: "/es/broadcasters/group",
    component: _3cb3b620,
    name: "broadcasters-group___es"
  }, {
    path: "/es/broadcasters/international",
    component: _460fd288,
    name: "broadcasters-international___es"
  }, {
    path: "/es/broadcasters/podcast",
    component: _3888a8b2,
    name: "broadcasters-podcast___es"
  }, {
    path: "/es/broadcasters/school",
    component: _07b8b794,
    name: "broadcasters-school___es"
  }, {
    path: "/es/broadcasters/seminary",
    component: _8490e02c,
    name: "broadcasters-seminary___es"
  }, {
    path: "/es/broadcasters/usa",
    component: _b654e29e,
    name: "broadcasters-usa___es"
  }, {
    path: "/es/broadcasters/vacant",
    component: _799441de,
    name: "broadcasters-vacant___es"
  }, {
    path: "/es/dashboard/articles",
    component: _ddff98da,
    name: "dashboard-articles___es"
  }, {
    path: "/es/demo/asyncdata",
    component: _5dd50fb2,
    name: "demo-asyncdata___es"
  }, {
    path: "/es/demo/audio-embeds",
    component: _abedff96,
    name: "demo-audio-embeds___es"
  }, {
    path: "/es/demo/broadcaster",
    component: _fa06d88c,
    name: "demo-broadcaster___es"
  }, {
    path: "/es/demo/broadcaster-locations",
    component: _0bfe0aea,
    name: "demo-broadcaster-locations___es"
  }, {
    path: "/es/demo/buttons",
    component: _4fae7ae6,
    name: "demo-buttons___es"
  }, {
    path: "/es/demo/cornerstone",
    component: _26d5acbc,
    name: "demo-cornerstone___es"
  }, {
    path: "/es/demo/dates",
    component: _0178d6de,
    name: "demo-dates___es"
  }, {
    path: "/es/demo/device",
    component: _7596630c,
    name: "demo-device___es"
  }, {
    path: "/es/demo/legacy-urls",
    component: _52a4ac18,
    name: "demo-legacy-urls___es"
  }, {
    path: "/es/demo/md",
    component: _0ce85b3b,
    name: "demo-md___es"
  }, {
    path: "/es/demo/mde",
    component: _d9ed378c,
    name: "demo-mde___es"
  }, {
    path: "/es/demo/s3",
    component: _1472ef44,
    name: "demo-s3___es"
  }, {
    path: "/es/demo/search-pills",
    component: _3dce7199,
    name: "demo-search-pills___es"
  }, {
    path: "/es/demo/series",
    component: _7a2cb61b,
    name: "demo-series___es"
  }, {
    path: "/es/demo/sermon",
    component: _5e8fa3d0,
    name: "demo-sermon___es"
  }, {
    path: "/es/demo/speaker",
    component: _a5a417ea,
    name: "demo-speaker___es"
  }, {
    path: "/es/demo/speaker-picker",
    component: _6900f740,
    name: "demo-speaker-picker___es"
  }, {
    path: "/es/demo/video-embeds",
    component: _3aab1e70,
    name: "demo-video-embeds___es"
  }, {
    path: "/es/library/bookmarks",
    component: _6ff6cca1,
    name: "library-bookmarks___es"
  }, {
    path: "/es/library/comments",
    component: _5fa7d300,
    name: "library-comments___es"
  }, {
    path: "/es/library/history",
    component: _a2928290,
    name: "library-history___es"
  }, {
    path: "/es/rewrite/psalter",
    component: _5f623b2a,
    name: "rewrite-psalter___es"
  }, {
    path: "/es/series/picks",
    component: _50e36392,
    name: "series-picks___es"
  }, {
    path: "/es/series/podcasts",
    component: _64c40302,
    name: "series-podcasts___es"
  }, {
    path: "/es/series/recent",
    component: _20d64eeb,
    name: "series-recent___es"
  }, {
    path: "/es/sermons/categories",
    component: _ac2f59c2,
    name: "sermons-categories___es"
  }, {
    path: "/es/sermons/clips",
    component: _745ecf5a,
    name: "sermons-clips___es"
  }, {
    path: "/es/sermons/date",
    component: _681d7c31,
    name: "sermons-date___es"
  }, {
    path: "/es/sermons/featured",
    component: _5fe950ae,
    name: "sermons-featured___es"
  }, {
    path: "/es/sermons/languages",
    component: _4beac38e,
    name: "sermons-languages___es"
  }, {
    path: "/es/sermons/scripture",
    component: _714574a0,
    name: "sermons-scripture___es"
  }, {
    path: "/es/sermons/staff-picks",
    component: _66663175,
    name: "sermons-staff-picks___es"
  }, {
    path: "/es/sermons/topics",
    component: _176e7027,
    name: "sermons-topics___es"
  }, {
    path: "/es/sermons/transcripts",
    component: _38c3a1ad,
    name: "sermons-transcripts___es"
  }, {
    path: "/es/sermons/videos",
    component: _08765cd8,
    name: "sermons-videos___es"
  }, {
    path: "/es/speakers/choice",
    component: _d2fdc598,
    name: "speakers-choice___es"
  }, {
    path: "/es/speakers/classic",
    component: _55d73c0f,
    name: "speakers-classic___es"
  }, {
    path: "/es/speakers/featured",
    component: _9f75bafe,
    name: "speakers-featured___es"
  }, {
    path: "/es/speakers/puritans",
    component: _4a746441,
    name: "speakers-puritans___es"
  }, {
    path: "/es/user/about",
    component: _49399981,
    name: "user-about___es"
  }, {
    path: "/es/user/login",
    component: _3299175d,
    name: "user-login___es"
  }, {
    path: "/es/user/logout",
    component: _6d9adcc6,
    name: "user-logout___es"
  }, {
    path: "/es/user/reset-password",
    component: _269a0d15,
    name: "user-reset-password___es"
  }, {
    path: "/es/user/settings",
    component: _695ab5df,
    name: "user-settings___es"
  }, {
    path: "/es/user/verify",
    component: _4dc24035,
    name: "user-verify___es"
  }, {
    path: "/es/video-wall/admin",
    component: _1319d1d8,
    name: "video-wall-admin___es"
  }, {
    path: "/es/video-wall/qr",
    component: _d246a2a8,
    name: "video-wall-qr___es"
  }, {
    path: "/es/webcasts/recent",
    component: _54da10fc,
    name: "webcasts-recent___es"
  }, {
    path: "/fr/admin/api",
    component: _0caf89d2,
    name: "admin-api___fr"
  }, {
    path: "/fr/admin/classic-embeds",
    component: _7283484d,
    name: "admin-classic-embeds___fr"
  }, {
    path: "/fr/admin/metadata",
    component: _65765827,
    name: "admin-metadata___fr"
  }, {
    path: "/fr/admin/speakers",
    component: _517b62cc,
    name: "admin-speakers___fr"
  }, {
    path: "/fr/broadcaster/linking-users",
    component: _59df70fa,
    name: "broadcaster-linking-users___fr"
  }, {
    path: "/fr/broadcaster/select",
    component: _2ecdac35,
    name: "broadcaster-select___fr"
  }, {
    path: "/fr/broadcaster/sign-in",
    component: _05b164cc,
    name: "broadcaster-sign-in___fr"
  }, {
    path: "/fr/broadcaster/sign-up",
    component: _1a47bb42,
    name: "broadcaster-sign-up___fr"
  }, {
    path: "/fr/broadcasters/featured",
    component: _ef664520,
    name: "broadcasters-featured___fr"
  }, {
    path: "/fr/broadcasters/group",
    component: _3cb3b620,
    name: "broadcasters-group___fr"
  }, {
    path: "/fr/broadcasters/international",
    component: _460fd288,
    name: "broadcasters-international___fr"
  }, {
    path: "/fr/broadcasters/podcast",
    component: _3888a8b2,
    name: "broadcasters-podcast___fr"
  }, {
    path: "/fr/broadcasters/school",
    component: _07b8b794,
    name: "broadcasters-school___fr"
  }, {
    path: "/fr/broadcasters/seminary",
    component: _8490e02c,
    name: "broadcasters-seminary___fr"
  }, {
    path: "/fr/broadcasters/usa",
    component: _b654e29e,
    name: "broadcasters-usa___fr"
  }, {
    path: "/fr/broadcasters/vacant",
    component: _799441de,
    name: "broadcasters-vacant___fr"
  }, {
    path: "/fr/dashboard/articles",
    component: _ddff98da,
    name: "dashboard-articles___fr"
  }, {
    path: "/fr/demo/asyncdata",
    component: _5dd50fb2,
    name: "demo-asyncdata___fr"
  }, {
    path: "/fr/demo/audio-embeds",
    component: _abedff96,
    name: "demo-audio-embeds___fr"
  }, {
    path: "/fr/demo/broadcaster",
    component: _fa06d88c,
    name: "demo-broadcaster___fr"
  }, {
    path: "/fr/demo/broadcaster-locations",
    component: _0bfe0aea,
    name: "demo-broadcaster-locations___fr"
  }, {
    path: "/fr/demo/buttons",
    component: _4fae7ae6,
    name: "demo-buttons___fr"
  }, {
    path: "/fr/demo/cornerstone",
    component: _26d5acbc,
    name: "demo-cornerstone___fr"
  }, {
    path: "/fr/demo/dates",
    component: _0178d6de,
    name: "demo-dates___fr"
  }, {
    path: "/fr/demo/device",
    component: _7596630c,
    name: "demo-device___fr"
  }, {
    path: "/fr/demo/legacy-urls",
    component: _52a4ac18,
    name: "demo-legacy-urls___fr"
  }, {
    path: "/fr/demo/md",
    component: _0ce85b3b,
    name: "demo-md___fr"
  }, {
    path: "/fr/demo/mde",
    component: _d9ed378c,
    name: "demo-mde___fr"
  }, {
    path: "/fr/demo/s3",
    component: _1472ef44,
    name: "demo-s3___fr"
  }, {
    path: "/fr/demo/search-pills",
    component: _3dce7199,
    name: "demo-search-pills___fr"
  }, {
    path: "/fr/demo/series",
    component: _7a2cb61b,
    name: "demo-series___fr"
  }, {
    path: "/fr/demo/sermon",
    component: _5e8fa3d0,
    name: "demo-sermon___fr"
  }, {
    path: "/fr/demo/speaker",
    component: _a5a417ea,
    name: "demo-speaker___fr"
  }, {
    path: "/fr/demo/speaker-picker",
    component: _6900f740,
    name: "demo-speaker-picker___fr"
  }, {
    path: "/fr/demo/video-embeds",
    component: _3aab1e70,
    name: "demo-video-embeds___fr"
  }, {
    path: "/fr/library/bookmarks",
    component: _6ff6cca1,
    name: "library-bookmarks___fr"
  }, {
    path: "/fr/library/comments",
    component: _5fa7d300,
    name: "library-comments___fr"
  }, {
    path: "/fr/library/history",
    component: _a2928290,
    name: "library-history___fr"
  }, {
    path: "/fr/rewrite/psalter",
    component: _5f623b2a,
    name: "rewrite-psalter___fr"
  }, {
    path: "/fr/series/picks",
    component: _50e36392,
    name: "series-picks___fr"
  }, {
    path: "/fr/series/podcasts",
    component: _64c40302,
    name: "series-podcasts___fr"
  }, {
    path: "/fr/series/recent",
    component: _20d64eeb,
    name: "series-recent___fr"
  }, {
    path: "/fr/sermons/categories",
    component: _ac2f59c2,
    name: "sermons-categories___fr"
  }, {
    path: "/fr/sermons/clips",
    component: _745ecf5a,
    name: "sermons-clips___fr"
  }, {
    path: "/fr/sermons/date",
    component: _681d7c31,
    name: "sermons-date___fr"
  }, {
    path: "/fr/sermons/featured",
    component: _5fe950ae,
    name: "sermons-featured___fr"
  }, {
    path: "/fr/sermons/languages",
    component: _4beac38e,
    name: "sermons-languages___fr"
  }, {
    path: "/fr/sermons/scripture",
    component: _714574a0,
    name: "sermons-scripture___fr"
  }, {
    path: "/fr/sermons/staff-picks",
    component: _66663175,
    name: "sermons-staff-picks___fr"
  }, {
    path: "/fr/sermons/topics",
    component: _176e7027,
    name: "sermons-topics___fr"
  }, {
    path: "/fr/sermons/transcripts",
    component: _38c3a1ad,
    name: "sermons-transcripts___fr"
  }, {
    path: "/fr/sermons/videos",
    component: _08765cd8,
    name: "sermons-videos___fr"
  }, {
    path: "/fr/speakers/choice",
    component: _d2fdc598,
    name: "speakers-choice___fr"
  }, {
    path: "/fr/speakers/classic",
    component: _55d73c0f,
    name: "speakers-classic___fr"
  }, {
    path: "/fr/speakers/featured",
    component: _9f75bafe,
    name: "speakers-featured___fr"
  }, {
    path: "/fr/speakers/puritans",
    component: _4a746441,
    name: "speakers-puritans___fr"
  }, {
    path: "/fr/user/about",
    component: _49399981,
    name: "user-about___fr"
  }, {
    path: "/fr/user/login",
    component: _3299175d,
    name: "user-login___fr"
  }, {
    path: "/fr/user/logout",
    component: _6d9adcc6,
    name: "user-logout___fr"
  }, {
    path: "/fr/user/reset-password",
    component: _269a0d15,
    name: "user-reset-password___fr"
  }, {
    path: "/fr/user/settings",
    component: _695ab5df,
    name: "user-settings___fr"
  }, {
    path: "/fr/user/verify",
    component: _4dc24035,
    name: "user-verify___fr"
  }, {
    path: "/fr/video-wall/admin",
    component: _1319d1d8,
    name: "video-wall-admin___fr"
  }, {
    path: "/fr/video-wall/qr",
    component: _d246a2a8,
    name: "video-wall-qr___fr"
  }, {
    path: "/fr/webcasts/recent",
    component: _54da10fc,
    name: "webcasts-recent___fr"
  }, {
    path: "/gb/admin/api",
    component: _0caf89d2,
    name: "admin-api___gb"
  }, {
    path: "/gb/admin/classic-embeds",
    component: _7283484d,
    name: "admin-classic-embeds___gb"
  }, {
    path: "/gb/admin/metadata",
    component: _65765827,
    name: "admin-metadata___gb"
  }, {
    path: "/gb/admin/speakers",
    component: _517b62cc,
    name: "admin-speakers___gb"
  }, {
    path: "/gb/broadcaster/linking-users",
    component: _59df70fa,
    name: "broadcaster-linking-users___gb"
  }, {
    path: "/gb/broadcaster/select",
    component: _2ecdac35,
    name: "broadcaster-select___gb"
  }, {
    path: "/gb/broadcaster/sign-in",
    component: _05b164cc,
    name: "broadcaster-sign-in___gb"
  }, {
    path: "/gb/broadcaster/sign-up",
    component: _1a47bb42,
    name: "broadcaster-sign-up___gb"
  }, {
    path: "/gb/broadcasters/featured",
    component: _ef664520,
    name: "broadcasters-featured___gb"
  }, {
    path: "/gb/broadcasters/group",
    component: _3cb3b620,
    name: "broadcasters-group___gb"
  }, {
    path: "/gb/broadcasters/international",
    component: _460fd288,
    name: "broadcasters-international___gb"
  }, {
    path: "/gb/broadcasters/podcast",
    component: _3888a8b2,
    name: "broadcasters-podcast___gb"
  }, {
    path: "/gb/broadcasters/school",
    component: _07b8b794,
    name: "broadcasters-school___gb"
  }, {
    path: "/gb/broadcasters/seminary",
    component: _8490e02c,
    name: "broadcasters-seminary___gb"
  }, {
    path: "/gb/broadcasters/usa",
    component: _b654e29e,
    name: "broadcasters-usa___gb"
  }, {
    path: "/gb/broadcasters/vacant",
    component: _799441de,
    name: "broadcasters-vacant___gb"
  }, {
    path: "/gb/dashboard/articles",
    component: _ddff98da,
    name: "dashboard-articles___gb"
  }, {
    path: "/gb/demo/asyncdata",
    component: _5dd50fb2,
    name: "demo-asyncdata___gb"
  }, {
    path: "/gb/demo/audio-embeds",
    component: _abedff96,
    name: "demo-audio-embeds___gb"
  }, {
    path: "/gb/demo/broadcaster",
    component: _fa06d88c,
    name: "demo-broadcaster___gb"
  }, {
    path: "/gb/demo/broadcaster-locations",
    component: _0bfe0aea,
    name: "demo-broadcaster-locations___gb"
  }, {
    path: "/gb/demo/buttons",
    component: _4fae7ae6,
    name: "demo-buttons___gb"
  }, {
    path: "/gb/demo/cornerstone",
    component: _26d5acbc,
    name: "demo-cornerstone___gb"
  }, {
    path: "/gb/demo/dates",
    component: _0178d6de,
    name: "demo-dates___gb"
  }, {
    path: "/gb/demo/device",
    component: _7596630c,
    name: "demo-device___gb"
  }, {
    path: "/gb/demo/legacy-urls",
    component: _52a4ac18,
    name: "demo-legacy-urls___gb"
  }, {
    path: "/gb/demo/md",
    component: _0ce85b3b,
    name: "demo-md___gb"
  }, {
    path: "/gb/demo/mde",
    component: _d9ed378c,
    name: "demo-mde___gb"
  }, {
    path: "/gb/demo/s3",
    component: _1472ef44,
    name: "demo-s3___gb"
  }, {
    path: "/gb/demo/search-pills",
    component: _3dce7199,
    name: "demo-search-pills___gb"
  }, {
    path: "/gb/demo/series",
    component: _7a2cb61b,
    name: "demo-series___gb"
  }, {
    path: "/gb/demo/sermon",
    component: _5e8fa3d0,
    name: "demo-sermon___gb"
  }, {
    path: "/gb/demo/speaker",
    component: _a5a417ea,
    name: "demo-speaker___gb"
  }, {
    path: "/gb/demo/speaker-picker",
    component: _6900f740,
    name: "demo-speaker-picker___gb"
  }, {
    path: "/gb/demo/video-embeds",
    component: _3aab1e70,
    name: "demo-video-embeds___gb"
  }, {
    path: "/gb/library/bookmarks",
    component: _6ff6cca1,
    name: "library-bookmarks___gb"
  }, {
    path: "/gb/library/comments",
    component: _5fa7d300,
    name: "library-comments___gb"
  }, {
    path: "/gb/library/history",
    component: _a2928290,
    name: "library-history___gb"
  }, {
    path: "/gb/rewrite/psalter",
    component: _5f623b2a,
    name: "rewrite-psalter___gb"
  }, {
    path: "/gb/series/picks",
    component: _50e36392,
    name: "series-picks___gb"
  }, {
    path: "/gb/series/podcasts",
    component: _64c40302,
    name: "series-podcasts___gb"
  }, {
    path: "/gb/series/recent",
    component: _20d64eeb,
    name: "series-recent___gb"
  }, {
    path: "/gb/sermons/categories",
    component: _ac2f59c2,
    name: "sermons-categories___gb"
  }, {
    path: "/gb/sermons/clips",
    component: _745ecf5a,
    name: "sermons-clips___gb"
  }, {
    path: "/gb/sermons/date",
    component: _681d7c31,
    name: "sermons-date___gb"
  }, {
    path: "/gb/sermons/featured",
    component: _5fe950ae,
    name: "sermons-featured___gb"
  }, {
    path: "/gb/sermons/languages",
    component: _4beac38e,
    name: "sermons-languages___gb"
  }, {
    path: "/gb/sermons/scripture",
    component: _714574a0,
    name: "sermons-scripture___gb"
  }, {
    path: "/gb/sermons/staff-picks",
    component: _66663175,
    name: "sermons-staff-picks___gb"
  }, {
    path: "/gb/sermons/topics",
    component: _176e7027,
    name: "sermons-topics___gb"
  }, {
    path: "/gb/sermons/transcripts",
    component: _38c3a1ad,
    name: "sermons-transcripts___gb"
  }, {
    path: "/gb/sermons/videos",
    component: _08765cd8,
    name: "sermons-videos___gb"
  }, {
    path: "/gb/speakers/choice",
    component: _d2fdc598,
    name: "speakers-choice___gb"
  }, {
    path: "/gb/speakers/classic",
    component: _55d73c0f,
    name: "speakers-classic___gb"
  }, {
    path: "/gb/speakers/featured",
    component: _9f75bafe,
    name: "speakers-featured___gb"
  }, {
    path: "/gb/speakers/puritans",
    component: _4a746441,
    name: "speakers-puritans___gb"
  }, {
    path: "/gb/user/about",
    component: _49399981,
    name: "user-about___gb"
  }, {
    path: "/gb/user/login",
    component: _3299175d,
    name: "user-login___gb"
  }, {
    path: "/gb/user/logout",
    component: _6d9adcc6,
    name: "user-logout___gb"
  }, {
    path: "/gb/user/reset-password",
    component: _269a0d15,
    name: "user-reset-password___gb"
  }, {
    path: "/gb/user/settings",
    component: _695ab5df,
    name: "user-settings___gb"
  }, {
    path: "/gb/user/verify",
    component: _4dc24035,
    name: "user-verify___gb"
  }, {
    path: "/gb/video-wall/admin",
    component: _1319d1d8,
    name: "video-wall-admin___gb"
  }, {
    path: "/gb/video-wall/qr",
    component: _d246a2a8,
    name: "video-wall-qr___gb"
  }, {
    path: "/gb/webcasts/recent",
    component: _54da10fc,
    name: "webcasts-recent___gb"
  }, {
    path: "/hi/admin/api",
    component: _0caf89d2,
    name: "admin-api___hi"
  }, {
    path: "/hi/admin/classic-embeds",
    component: _7283484d,
    name: "admin-classic-embeds___hi"
  }, {
    path: "/hi/admin/metadata",
    component: _65765827,
    name: "admin-metadata___hi"
  }, {
    path: "/hi/admin/speakers",
    component: _517b62cc,
    name: "admin-speakers___hi"
  }, {
    path: "/hi/broadcaster/linking-users",
    component: _59df70fa,
    name: "broadcaster-linking-users___hi"
  }, {
    path: "/hi/broadcaster/select",
    component: _2ecdac35,
    name: "broadcaster-select___hi"
  }, {
    path: "/hi/broadcaster/sign-in",
    component: _05b164cc,
    name: "broadcaster-sign-in___hi"
  }, {
    path: "/hi/broadcaster/sign-up",
    component: _1a47bb42,
    name: "broadcaster-sign-up___hi"
  }, {
    path: "/hi/broadcasters/featured",
    component: _ef664520,
    name: "broadcasters-featured___hi"
  }, {
    path: "/hi/broadcasters/group",
    component: _3cb3b620,
    name: "broadcasters-group___hi"
  }, {
    path: "/hi/broadcasters/international",
    component: _460fd288,
    name: "broadcasters-international___hi"
  }, {
    path: "/hi/broadcasters/podcast",
    component: _3888a8b2,
    name: "broadcasters-podcast___hi"
  }, {
    path: "/hi/broadcasters/school",
    component: _07b8b794,
    name: "broadcasters-school___hi"
  }, {
    path: "/hi/broadcasters/seminary",
    component: _8490e02c,
    name: "broadcasters-seminary___hi"
  }, {
    path: "/hi/broadcasters/usa",
    component: _b654e29e,
    name: "broadcasters-usa___hi"
  }, {
    path: "/hi/broadcasters/vacant",
    component: _799441de,
    name: "broadcasters-vacant___hi"
  }, {
    path: "/hi/dashboard/articles",
    component: _ddff98da,
    name: "dashboard-articles___hi"
  }, {
    path: "/hi/demo/asyncdata",
    component: _5dd50fb2,
    name: "demo-asyncdata___hi"
  }, {
    path: "/hi/demo/audio-embeds",
    component: _abedff96,
    name: "demo-audio-embeds___hi"
  }, {
    path: "/hi/demo/broadcaster",
    component: _fa06d88c,
    name: "demo-broadcaster___hi"
  }, {
    path: "/hi/demo/broadcaster-locations",
    component: _0bfe0aea,
    name: "demo-broadcaster-locations___hi"
  }, {
    path: "/hi/demo/buttons",
    component: _4fae7ae6,
    name: "demo-buttons___hi"
  }, {
    path: "/hi/demo/cornerstone",
    component: _26d5acbc,
    name: "demo-cornerstone___hi"
  }, {
    path: "/hi/demo/dates",
    component: _0178d6de,
    name: "demo-dates___hi"
  }, {
    path: "/hi/demo/device",
    component: _7596630c,
    name: "demo-device___hi"
  }, {
    path: "/hi/demo/legacy-urls",
    component: _52a4ac18,
    name: "demo-legacy-urls___hi"
  }, {
    path: "/hi/demo/md",
    component: _0ce85b3b,
    name: "demo-md___hi"
  }, {
    path: "/hi/demo/mde",
    component: _d9ed378c,
    name: "demo-mde___hi"
  }, {
    path: "/hi/demo/s3",
    component: _1472ef44,
    name: "demo-s3___hi"
  }, {
    path: "/hi/demo/search-pills",
    component: _3dce7199,
    name: "demo-search-pills___hi"
  }, {
    path: "/hi/demo/series",
    component: _7a2cb61b,
    name: "demo-series___hi"
  }, {
    path: "/hi/demo/sermon",
    component: _5e8fa3d0,
    name: "demo-sermon___hi"
  }, {
    path: "/hi/demo/speaker",
    component: _a5a417ea,
    name: "demo-speaker___hi"
  }, {
    path: "/hi/demo/speaker-picker",
    component: _6900f740,
    name: "demo-speaker-picker___hi"
  }, {
    path: "/hi/demo/video-embeds",
    component: _3aab1e70,
    name: "demo-video-embeds___hi"
  }, {
    path: "/hi/library/bookmarks",
    component: _6ff6cca1,
    name: "library-bookmarks___hi"
  }, {
    path: "/hi/library/comments",
    component: _5fa7d300,
    name: "library-comments___hi"
  }, {
    path: "/hi/library/history",
    component: _a2928290,
    name: "library-history___hi"
  }, {
    path: "/hi/rewrite/psalter",
    component: _5f623b2a,
    name: "rewrite-psalter___hi"
  }, {
    path: "/hi/series/picks",
    component: _50e36392,
    name: "series-picks___hi"
  }, {
    path: "/hi/series/podcasts",
    component: _64c40302,
    name: "series-podcasts___hi"
  }, {
    path: "/hi/series/recent",
    component: _20d64eeb,
    name: "series-recent___hi"
  }, {
    path: "/hi/sermons/categories",
    component: _ac2f59c2,
    name: "sermons-categories___hi"
  }, {
    path: "/hi/sermons/clips",
    component: _745ecf5a,
    name: "sermons-clips___hi"
  }, {
    path: "/hi/sermons/date",
    component: _681d7c31,
    name: "sermons-date___hi"
  }, {
    path: "/hi/sermons/featured",
    component: _5fe950ae,
    name: "sermons-featured___hi"
  }, {
    path: "/hi/sermons/languages",
    component: _4beac38e,
    name: "sermons-languages___hi"
  }, {
    path: "/hi/sermons/scripture",
    component: _714574a0,
    name: "sermons-scripture___hi"
  }, {
    path: "/hi/sermons/staff-picks",
    component: _66663175,
    name: "sermons-staff-picks___hi"
  }, {
    path: "/hi/sermons/topics",
    component: _176e7027,
    name: "sermons-topics___hi"
  }, {
    path: "/hi/sermons/transcripts",
    component: _38c3a1ad,
    name: "sermons-transcripts___hi"
  }, {
    path: "/hi/sermons/videos",
    component: _08765cd8,
    name: "sermons-videos___hi"
  }, {
    path: "/hi/speakers/choice",
    component: _d2fdc598,
    name: "speakers-choice___hi"
  }, {
    path: "/hi/speakers/classic",
    component: _55d73c0f,
    name: "speakers-classic___hi"
  }, {
    path: "/hi/speakers/featured",
    component: _9f75bafe,
    name: "speakers-featured___hi"
  }, {
    path: "/hi/speakers/puritans",
    component: _4a746441,
    name: "speakers-puritans___hi"
  }, {
    path: "/hi/user/about",
    component: _49399981,
    name: "user-about___hi"
  }, {
    path: "/hi/user/login",
    component: _3299175d,
    name: "user-login___hi"
  }, {
    path: "/hi/user/logout",
    component: _6d9adcc6,
    name: "user-logout___hi"
  }, {
    path: "/hi/user/reset-password",
    component: _269a0d15,
    name: "user-reset-password___hi"
  }, {
    path: "/hi/user/settings",
    component: _695ab5df,
    name: "user-settings___hi"
  }, {
    path: "/hi/user/verify",
    component: _4dc24035,
    name: "user-verify___hi"
  }, {
    path: "/hi/video-wall/admin",
    component: _1319d1d8,
    name: "video-wall-admin___hi"
  }, {
    path: "/hi/video-wall/qr",
    component: _d246a2a8,
    name: "video-wall-qr___hi"
  }, {
    path: "/hi/webcasts/recent",
    component: _54da10fc,
    name: "webcasts-recent___hi"
  }, {
    path: "/it/admin/api",
    component: _0caf89d2,
    name: "admin-api___it"
  }, {
    path: "/it/admin/classic-embeds",
    component: _7283484d,
    name: "admin-classic-embeds___it"
  }, {
    path: "/it/admin/metadata",
    component: _65765827,
    name: "admin-metadata___it"
  }, {
    path: "/it/admin/speakers",
    component: _517b62cc,
    name: "admin-speakers___it"
  }, {
    path: "/it/broadcaster/linking-users",
    component: _59df70fa,
    name: "broadcaster-linking-users___it"
  }, {
    path: "/it/broadcaster/select",
    component: _2ecdac35,
    name: "broadcaster-select___it"
  }, {
    path: "/it/broadcaster/sign-in",
    component: _05b164cc,
    name: "broadcaster-sign-in___it"
  }, {
    path: "/it/broadcaster/sign-up",
    component: _1a47bb42,
    name: "broadcaster-sign-up___it"
  }, {
    path: "/it/broadcasters/featured",
    component: _ef664520,
    name: "broadcasters-featured___it"
  }, {
    path: "/it/broadcasters/group",
    component: _3cb3b620,
    name: "broadcasters-group___it"
  }, {
    path: "/it/broadcasters/international",
    component: _460fd288,
    name: "broadcasters-international___it"
  }, {
    path: "/it/broadcasters/podcast",
    component: _3888a8b2,
    name: "broadcasters-podcast___it"
  }, {
    path: "/it/broadcasters/school",
    component: _07b8b794,
    name: "broadcasters-school___it"
  }, {
    path: "/it/broadcasters/seminary",
    component: _8490e02c,
    name: "broadcasters-seminary___it"
  }, {
    path: "/it/broadcasters/usa",
    component: _b654e29e,
    name: "broadcasters-usa___it"
  }, {
    path: "/it/broadcasters/vacant",
    component: _799441de,
    name: "broadcasters-vacant___it"
  }, {
    path: "/it/dashboard/articles",
    component: _ddff98da,
    name: "dashboard-articles___it"
  }, {
    path: "/it/demo/asyncdata",
    component: _5dd50fb2,
    name: "demo-asyncdata___it"
  }, {
    path: "/it/demo/audio-embeds",
    component: _abedff96,
    name: "demo-audio-embeds___it"
  }, {
    path: "/it/demo/broadcaster",
    component: _fa06d88c,
    name: "demo-broadcaster___it"
  }, {
    path: "/it/demo/broadcaster-locations",
    component: _0bfe0aea,
    name: "demo-broadcaster-locations___it"
  }, {
    path: "/it/demo/buttons",
    component: _4fae7ae6,
    name: "demo-buttons___it"
  }, {
    path: "/it/demo/cornerstone",
    component: _26d5acbc,
    name: "demo-cornerstone___it"
  }, {
    path: "/it/demo/dates",
    component: _0178d6de,
    name: "demo-dates___it"
  }, {
    path: "/it/demo/device",
    component: _7596630c,
    name: "demo-device___it"
  }, {
    path: "/it/demo/legacy-urls",
    component: _52a4ac18,
    name: "demo-legacy-urls___it"
  }, {
    path: "/it/demo/md",
    component: _0ce85b3b,
    name: "demo-md___it"
  }, {
    path: "/it/demo/mde",
    component: _d9ed378c,
    name: "demo-mde___it"
  }, {
    path: "/it/demo/s3",
    component: _1472ef44,
    name: "demo-s3___it"
  }, {
    path: "/it/demo/search-pills",
    component: _3dce7199,
    name: "demo-search-pills___it"
  }, {
    path: "/it/demo/series",
    component: _7a2cb61b,
    name: "demo-series___it"
  }, {
    path: "/it/demo/sermon",
    component: _5e8fa3d0,
    name: "demo-sermon___it"
  }, {
    path: "/it/demo/speaker",
    component: _a5a417ea,
    name: "demo-speaker___it"
  }, {
    path: "/it/demo/speaker-picker",
    component: _6900f740,
    name: "demo-speaker-picker___it"
  }, {
    path: "/it/demo/video-embeds",
    component: _3aab1e70,
    name: "demo-video-embeds___it"
  }, {
    path: "/it/library/bookmarks",
    component: _6ff6cca1,
    name: "library-bookmarks___it"
  }, {
    path: "/it/library/comments",
    component: _5fa7d300,
    name: "library-comments___it"
  }, {
    path: "/it/library/history",
    component: _a2928290,
    name: "library-history___it"
  }, {
    path: "/it/rewrite/psalter",
    component: _5f623b2a,
    name: "rewrite-psalter___it"
  }, {
    path: "/it/series/picks",
    component: _50e36392,
    name: "series-picks___it"
  }, {
    path: "/it/series/podcasts",
    component: _64c40302,
    name: "series-podcasts___it"
  }, {
    path: "/it/series/recent",
    component: _20d64eeb,
    name: "series-recent___it"
  }, {
    path: "/it/sermons/categories",
    component: _ac2f59c2,
    name: "sermons-categories___it"
  }, {
    path: "/it/sermons/clips",
    component: _745ecf5a,
    name: "sermons-clips___it"
  }, {
    path: "/it/sermons/date",
    component: _681d7c31,
    name: "sermons-date___it"
  }, {
    path: "/it/sermons/featured",
    component: _5fe950ae,
    name: "sermons-featured___it"
  }, {
    path: "/it/sermons/languages",
    component: _4beac38e,
    name: "sermons-languages___it"
  }, {
    path: "/it/sermons/scripture",
    component: _714574a0,
    name: "sermons-scripture___it"
  }, {
    path: "/it/sermons/staff-picks",
    component: _66663175,
    name: "sermons-staff-picks___it"
  }, {
    path: "/it/sermons/topics",
    component: _176e7027,
    name: "sermons-topics___it"
  }, {
    path: "/it/sermons/transcripts",
    component: _38c3a1ad,
    name: "sermons-transcripts___it"
  }, {
    path: "/it/sermons/videos",
    component: _08765cd8,
    name: "sermons-videos___it"
  }, {
    path: "/it/speakers/choice",
    component: _d2fdc598,
    name: "speakers-choice___it"
  }, {
    path: "/it/speakers/classic",
    component: _55d73c0f,
    name: "speakers-classic___it"
  }, {
    path: "/it/speakers/featured",
    component: _9f75bafe,
    name: "speakers-featured___it"
  }, {
    path: "/it/speakers/puritans",
    component: _4a746441,
    name: "speakers-puritans___it"
  }, {
    path: "/it/user/about",
    component: _49399981,
    name: "user-about___it"
  }, {
    path: "/it/user/login",
    component: _3299175d,
    name: "user-login___it"
  }, {
    path: "/it/user/logout",
    component: _6d9adcc6,
    name: "user-logout___it"
  }, {
    path: "/it/user/reset-password",
    component: _269a0d15,
    name: "user-reset-password___it"
  }, {
    path: "/it/user/settings",
    component: _695ab5df,
    name: "user-settings___it"
  }, {
    path: "/it/user/verify",
    component: _4dc24035,
    name: "user-verify___it"
  }, {
    path: "/it/video-wall/admin",
    component: _1319d1d8,
    name: "video-wall-admin___it"
  }, {
    path: "/it/video-wall/qr",
    component: _d246a2a8,
    name: "video-wall-qr___it"
  }, {
    path: "/it/webcasts/recent",
    component: _54da10fc,
    name: "webcasts-recent___it"
  }, {
    path: "/km/admin/api",
    component: _0caf89d2,
    name: "admin-api___km"
  }, {
    path: "/km/admin/classic-embeds",
    component: _7283484d,
    name: "admin-classic-embeds___km"
  }, {
    path: "/km/admin/metadata",
    component: _65765827,
    name: "admin-metadata___km"
  }, {
    path: "/km/admin/speakers",
    component: _517b62cc,
    name: "admin-speakers___km"
  }, {
    path: "/km/broadcaster/linking-users",
    component: _59df70fa,
    name: "broadcaster-linking-users___km"
  }, {
    path: "/km/broadcaster/select",
    component: _2ecdac35,
    name: "broadcaster-select___km"
  }, {
    path: "/km/broadcaster/sign-in",
    component: _05b164cc,
    name: "broadcaster-sign-in___km"
  }, {
    path: "/km/broadcaster/sign-up",
    component: _1a47bb42,
    name: "broadcaster-sign-up___km"
  }, {
    path: "/km/broadcasters/featured",
    component: _ef664520,
    name: "broadcasters-featured___km"
  }, {
    path: "/km/broadcasters/group",
    component: _3cb3b620,
    name: "broadcasters-group___km"
  }, {
    path: "/km/broadcasters/international",
    component: _460fd288,
    name: "broadcasters-international___km"
  }, {
    path: "/km/broadcasters/podcast",
    component: _3888a8b2,
    name: "broadcasters-podcast___km"
  }, {
    path: "/km/broadcasters/school",
    component: _07b8b794,
    name: "broadcasters-school___km"
  }, {
    path: "/km/broadcasters/seminary",
    component: _8490e02c,
    name: "broadcasters-seminary___km"
  }, {
    path: "/km/broadcasters/usa",
    component: _b654e29e,
    name: "broadcasters-usa___km"
  }, {
    path: "/km/broadcasters/vacant",
    component: _799441de,
    name: "broadcasters-vacant___km"
  }, {
    path: "/km/dashboard/articles",
    component: _ddff98da,
    name: "dashboard-articles___km"
  }, {
    path: "/km/demo/asyncdata",
    component: _5dd50fb2,
    name: "demo-asyncdata___km"
  }, {
    path: "/km/demo/audio-embeds",
    component: _abedff96,
    name: "demo-audio-embeds___km"
  }, {
    path: "/km/demo/broadcaster",
    component: _fa06d88c,
    name: "demo-broadcaster___km"
  }, {
    path: "/km/demo/broadcaster-locations",
    component: _0bfe0aea,
    name: "demo-broadcaster-locations___km"
  }, {
    path: "/km/demo/buttons",
    component: _4fae7ae6,
    name: "demo-buttons___km"
  }, {
    path: "/km/demo/cornerstone",
    component: _26d5acbc,
    name: "demo-cornerstone___km"
  }, {
    path: "/km/demo/dates",
    component: _0178d6de,
    name: "demo-dates___km"
  }, {
    path: "/km/demo/device",
    component: _7596630c,
    name: "demo-device___km"
  }, {
    path: "/km/demo/legacy-urls",
    component: _52a4ac18,
    name: "demo-legacy-urls___km"
  }, {
    path: "/km/demo/md",
    component: _0ce85b3b,
    name: "demo-md___km"
  }, {
    path: "/km/demo/mde",
    component: _d9ed378c,
    name: "demo-mde___km"
  }, {
    path: "/km/demo/s3",
    component: _1472ef44,
    name: "demo-s3___km"
  }, {
    path: "/km/demo/search-pills",
    component: _3dce7199,
    name: "demo-search-pills___km"
  }, {
    path: "/km/demo/series",
    component: _7a2cb61b,
    name: "demo-series___km"
  }, {
    path: "/km/demo/sermon",
    component: _5e8fa3d0,
    name: "demo-sermon___km"
  }, {
    path: "/km/demo/speaker",
    component: _a5a417ea,
    name: "demo-speaker___km"
  }, {
    path: "/km/demo/speaker-picker",
    component: _6900f740,
    name: "demo-speaker-picker___km"
  }, {
    path: "/km/demo/video-embeds",
    component: _3aab1e70,
    name: "demo-video-embeds___km"
  }, {
    path: "/km/library/bookmarks",
    component: _6ff6cca1,
    name: "library-bookmarks___km"
  }, {
    path: "/km/library/comments",
    component: _5fa7d300,
    name: "library-comments___km"
  }, {
    path: "/km/library/history",
    component: _a2928290,
    name: "library-history___km"
  }, {
    path: "/km/rewrite/psalter",
    component: _5f623b2a,
    name: "rewrite-psalter___km"
  }, {
    path: "/km/series/picks",
    component: _50e36392,
    name: "series-picks___km"
  }, {
    path: "/km/series/podcasts",
    component: _64c40302,
    name: "series-podcasts___km"
  }, {
    path: "/km/series/recent",
    component: _20d64eeb,
    name: "series-recent___km"
  }, {
    path: "/km/sermons/categories",
    component: _ac2f59c2,
    name: "sermons-categories___km"
  }, {
    path: "/km/sermons/clips",
    component: _745ecf5a,
    name: "sermons-clips___km"
  }, {
    path: "/km/sermons/date",
    component: _681d7c31,
    name: "sermons-date___km"
  }, {
    path: "/km/sermons/featured",
    component: _5fe950ae,
    name: "sermons-featured___km"
  }, {
    path: "/km/sermons/languages",
    component: _4beac38e,
    name: "sermons-languages___km"
  }, {
    path: "/km/sermons/scripture",
    component: _714574a0,
    name: "sermons-scripture___km"
  }, {
    path: "/km/sermons/staff-picks",
    component: _66663175,
    name: "sermons-staff-picks___km"
  }, {
    path: "/km/sermons/topics",
    component: _176e7027,
    name: "sermons-topics___km"
  }, {
    path: "/km/sermons/transcripts",
    component: _38c3a1ad,
    name: "sermons-transcripts___km"
  }, {
    path: "/km/sermons/videos",
    component: _08765cd8,
    name: "sermons-videos___km"
  }, {
    path: "/km/speakers/choice",
    component: _d2fdc598,
    name: "speakers-choice___km"
  }, {
    path: "/km/speakers/classic",
    component: _55d73c0f,
    name: "speakers-classic___km"
  }, {
    path: "/km/speakers/featured",
    component: _9f75bafe,
    name: "speakers-featured___km"
  }, {
    path: "/km/speakers/puritans",
    component: _4a746441,
    name: "speakers-puritans___km"
  }, {
    path: "/km/user/about",
    component: _49399981,
    name: "user-about___km"
  }, {
    path: "/km/user/login",
    component: _3299175d,
    name: "user-login___km"
  }, {
    path: "/km/user/logout",
    component: _6d9adcc6,
    name: "user-logout___km"
  }, {
    path: "/km/user/reset-password",
    component: _269a0d15,
    name: "user-reset-password___km"
  }, {
    path: "/km/user/settings",
    component: _695ab5df,
    name: "user-settings___km"
  }, {
    path: "/km/user/verify",
    component: _4dc24035,
    name: "user-verify___km"
  }, {
    path: "/km/video-wall/admin",
    component: _1319d1d8,
    name: "video-wall-admin___km"
  }, {
    path: "/km/video-wall/qr",
    component: _d246a2a8,
    name: "video-wall-qr___km"
  }, {
    path: "/km/webcasts/recent",
    component: _54da10fc,
    name: "webcasts-recent___km"
  }, {
    path: "/ko/admin/api",
    component: _0caf89d2,
    name: "admin-api___ko"
  }, {
    path: "/ko/admin/classic-embeds",
    component: _7283484d,
    name: "admin-classic-embeds___ko"
  }, {
    path: "/ko/admin/metadata",
    component: _65765827,
    name: "admin-metadata___ko"
  }, {
    path: "/ko/admin/speakers",
    component: _517b62cc,
    name: "admin-speakers___ko"
  }, {
    path: "/ko/broadcaster/linking-users",
    component: _59df70fa,
    name: "broadcaster-linking-users___ko"
  }, {
    path: "/ko/broadcaster/select",
    component: _2ecdac35,
    name: "broadcaster-select___ko"
  }, {
    path: "/ko/broadcaster/sign-in",
    component: _05b164cc,
    name: "broadcaster-sign-in___ko"
  }, {
    path: "/ko/broadcaster/sign-up",
    component: _1a47bb42,
    name: "broadcaster-sign-up___ko"
  }, {
    path: "/ko/broadcasters/featured",
    component: _ef664520,
    name: "broadcasters-featured___ko"
  }, {
    path: "/ko/broadcasters/group",
    component: _3cb3b620,
    name: "broadcasters-group___ko"
  }, {
    path: "/ko/broadcasters/international",
    component: _460fd288,
    name: "broadcasters-international___ko"
  }, {
    path: "/ko/broadcasters/podcast",
    component: _3888a8b2,
    name: "broadcasters-podcast___ko"
  }, {
    path: "/ko/broadcasters/school",
    component: _07b8b794,
    name: "broadcasters-school___ko"
  }, {
    path: "/ko/broadcasters/seminary",
    component: _8490e02c,
    name: "broadcasters-seminary___ko"
  }, {
    path: "/ko/broadcasters/usa",
    component: _b654e29e,
    name: "broadcasters-usa___ko"
  }, {
    path: "/ko/broadcasters/vacant",
    component: _799441de,
    name: "broadcasters-vacant___ko"
  }, {
    path: "/ko/dashboard/articles",
    component: _ddff98da,
    name: "dashboard-articles___ko"
  }, {
    path: "/ko/demo/asyncdata",
    component: _5dd50fb2,
    name: "demo-asyncdata___ko"
  }, {
    path: "/ko/demo/audio-embeds",
    component: _abedff96,
    name: "demo-audio-embeds___ko"
  }, {
    path: "/ko/demo/broadcaster",
    component: _fa06d88c,
    name: "demo-broadcaster___ko"
  }, {
    path: "/ko/demo/broadcaster-locations",
    component: _0bfe0aea,
    name: "demo-broadcaster-locations___ko"
  }, {
    path: "/ko/demo/buttons",
    component: _4fae7ae6,
    name: "demo-buttons___ko"
  }, {
    path: "/ko/demo/cornerstone",
    component: _26d5acbc,
    name: "demo-cornerstone___ko"
  }, {
    path: "/ko/demo/dates",
    component: _0178d6de,
    name: "demo-dates___ko"
  }, {
    path: "/ko/demo/device",
    component: _7596630c,
    name: "demo-device___ko"
  }, {
    path: "/ko/demo/legacy-urls",
    component: _52a4ac18,
    name: "demo-legacy-urls___ko"
  }, {
    path: "/ko/demo/md",
    component: _0ce85b3b,
    name: "demo-md___ko"
  }, {
    path: "/ko/demo/mde",
    component: _d9ed378c,
    name: "demo-mde___ko"
  }, {
    path: "/ko/demo/s3",
    component: _1472ef44,
    name: "demo-s3___ko"
  }, {
    path: "/ko/demo/search-pills",
    component: _3dce7199,
    name: "demo-search-pills___ko"
  }, {
    path: "/ko/demo/series",
    component: _7a2cb61b,
    name: "demo-series___ko"
  }, {
    path: "/ko/demo/sermon",
    component: _5e8fa3d0,
    name: "demo-sermon___ko"
  }, {
    path: "/ko/demo/speaker",
    component: _a5a417ea,
    name: "demo-speaker___ko"
  }, {
    path: "/ko/demo/speaker-picker",
    component: _6900f740,
    name: "demo-speaker-picker___ko"
  }, {
    path: "/ko/demo/video-embeds",
    component: _3aab1e70,
    name: "demo-video-embeds___ko"
  }, {
    path: "/ko/library/bookmarks",
    component: _6ff6cca1,
    name: "library-bookmarks___ko"
  }, {
    path: "/ko/library/comments",
    component: _5fa7d300,
    name: "library-comments___ko"
  }, {
    path: "/ko/library/history",
    component: _a2928290,
    name: "library-history___ko"
  }, {
    path: "/ko/rewrite/psalter",
    component: _5f623b2a,
    name: "rewrite-psalter___ko"
  }, {
    path: "/ko/series/picks",
    component: _50e36392,
    name: "series-picks___ko"
  }, {
    path: "/ko/series/podcasts",
    component: _64c40302,
    name: "series-podcasts___ko"
  }, {
    path: "/ko/series/recent",
    component: _20d64eeb,
    name: "series-recent___ko"
  }, {
    path: "/ko/sermons/categories",
    component: _ac2f59c2,
    name: "sermons-categories___ko"
  }, {
    path: "/ko/sermons/clips",
    component: _745ecf5a,
    name: "sermons-clips___ko"
  }, {
    path: "/ko/sermons/date",
    component: _681d7c31,
    name: "sermons-date___ko"
  }, {
    path: "/ko/sermons/featured",
    component: _5fe950ae,
    name: "sermons-featured___ko"
  }, {
    path: "/ko/sermons/languages",
    component: _4beac38e,
    name: "sermons-languages___ko"
  }, {
    path: "/ko/sermons/scripture",
    component: _714574a0,
    name: "sermons-scripture___ko"
  }, {
    path: "/ko/sermons/staff-picks",
    component: _66663175,
    name: "sermons-staff-picks___ko"
  }, {
    path: "/ko/sermons/topics",
    component: _176e7027,
    name: "sermons-topics___ko"
  }, {
    path: "/ko/sermons/transcripts",
    component: _38c3a1ad,
    name: "sermons-transcripts___ko"
  }, {
    path: "/ko/sermons/videos",
    component: _08765cd8,
    name: "sermons-videos___ko"
  }, {
    path: "/ko/speakers/choice",
    component: _d2fdc598,
    name: "speakers-choice___ko"
  }, {
    path: "/ko/speakers/classic",
    component: _55d73c0f,
    name: "speakers-classic___ko"
  }, {
    path: "/ko/speakers/featured",
    component: _9f75bafe,
    name: "speakers-featured___ko"
  }, {
    path: "/ko/speakers/puritans",
    component: _4a746441,
    name: "speakers-puritans___ko"
  }, {
    path: "/ko/user/about",
    component: _49399981,
    name: "user-about___ko"
  }, {
    path: "/ko/user/login",
    component: _3299175d,
    name: "user-login___ko"
  }, {
    path: "/ko/user/logout",
    component: _6d9adcc6,
    name: "user-logout___ko"
  }, {
    path: "/ko/user/reset-password",
    component: _269a0d15,
    name: "user-reset-password___ko"
  }, {
    path: "/ko/user/settings",
    component: _695ab5df,
    name: "user-settings___ko"
  }, {
    path: "/ko/user/verify",
    component: _4dc24035,
    name: "user-verify___ko"
  }, {
    path: "/ko/video-wall/admin",
    component: _1319d1d8,
    name: "video-wall-admin___ko"
  }, {
    path: "/ko/video-wall/qr",
    component: _d246a2a8,
    name: "video-wall-qr___ko"
  }, {
    path: "/ko/webcasts/recent",
    component: _54da10fc,
    name: "webcasts-recent___ko"
  }, {
    path: "/pt/admin/api",
    component: _0caf89d2,
    name: "admin-api___pt"
  }, {
    path: "/pt/admin/classic-embeds",
    component: _7283484d,
    name: "admin-classic-embeds___pt"
  }, {
    path: "/pt/admin/metadata",
    component: _65765827,
    name: "admin-metadata___pt"
  }, {
    path: "/pt/admin/speakers",
    component: _517b62cc,
    name: "admin-speakers___pt"
  }, {
    path: "/pt/broadcaster/linking-users",
    component: _59df70fa,
    name: "broadcaster-linking-users___pt"
  }, {
    path: "/pt/broadcaster/select",
    component: _2ecdac35,
    name: "broadcaster-select___pt"
  }, {
    path: "/pt/broadcaster/sign-in",
    component: _05b164cc,
    name: "broadcaster-sign-in___pt"
  }, {
    path: "/pt/broadcaster/sign-up",
    component: _1a47bb42,
    name: "broadcaster-sign-up___pt"
  }, {
    path: "/pt/broadcasters/featured",
    component: _ef664520,
    name: "broadcasters-featured___pt"
  }, {
    path: "/pt/broadcasters/group",
    component: _3cb3b620,
    name: "broadcasters-group___pt"
  }, {
    path: "/pt/broadcasters/international",
    component: _460fd288,
    name: "broadcasters-international___pt"
  }, {
    path: "/pt/broadcasters/podcast",
    component: _3888a8b2,
    name: "broadcasters-podcast___pt"
  }, {
    path: "/pt/broadcasters/school",
    component: _07b8b794,
    name: "broadcasters-school___pt"
  }, {
    path: "/pt/broadcasters/seminary",
    component: _8490e02c,
    name: "broadcasters-seminary___pt"
  }, {
    path: "/pt/broadcasters/usa",
    component: _b654e29e,
    name: "broadcasters-usa___pt"
  }, {
    path: "/pt/broadcasters/vacant",
    component: _799441de,
    name: "broadcasters-vacant___pt"
  }, {
    path: "/pt/dashboard/articles",
    component: _ddff98da,
    name: "dashboard-articles___pt"
  }, {
    path: "/pt/demo/asyncdata",
    component: _5dd50fb2,
    name: "demo-asyncdata___pt"
  }, {
    path: "/pt/demo/audio-embeds",
    component: _abedff96,
    name: "demo-audio-embeds___pt"
  }, {
    path: "/pt/demo/broadcaster",
    component: _fa06d88c,
    name: "demo-broadcaster___pt"
  }, {
    path: "/pt/demo/broadcaster-locations",
    component: _0bfe0aea,
    name: "demo-broadcaster-locations___pt"
  }, {
    path: "/pt/demo/buttons",
    component: _4fae7ae6,
    name: "demo-buttons___pt"
  }, {
    path: "/pt/demo/cornerstone",
    component: _26d5acbc,
    name: "demo-cornerstone___pt"
  }, {
    path: "/pt/demo/dates",
    component: _0178d6de,
    name: "demo-dates___pt"
  }, {
    path: "/pt/demo/device",
    component: _7596630c,
    name: "demo-device___pt"
  }, {
    path: "/pt/demo/legacy-urls",
    component: _52a4ac18,
    name: "demo-legacy-urls___pt"
  }, {
    path: "/pt/demo/md",
    component: _0ce85b3b,
    name: "demo-md___pt"
  }, {
    path: "/pt/demo/mde",
    component: _d9ed378c,
    name: "demo-mde___pt"
  }, {
    path: "/pt/demo/s3",
    component: _1472ef44,
    name: "demo-s3___pt"
  }, {
    path: "/pt/demo/search-pills",
    component: _3dce7199,
    name: "demo-search-pills___pt"
  }, {
    path: "/pt/demo/series",
    component: _7a2cb61b,
    name: "demo-series___pt"
  }, {
    path: "/pt/demo/sermon",
    component: _5e8fa3d0,
    name: "demo-sermon___pt"
  }, {
    path: "/pt/demo/speaker",
    component: _a5a417ea,
    name: "demo-speaker___pt"
  }, {
    path: "/pt/demo/speaker-picker",
    component: _6900f740,
    name: "demo-speaker-picker___pt"
  }, {
    path: "/pt/demo/video-embeds",
    component: _3aab1e70,
    name: "demo-video-embeds___pt"
  }, {
    path: "/pt/library/bookmarks",
    component: _6ff6cca1,
    name: "library-bookmarks___pt"
  }, {
    path: "/pt/library/comments",
    component: _5fa7d300,
    name: "library-comments___pt"
  }, {
    path: "/pt/library/history",
    component: _a2928290,
    name: "library-history___pt"
  }, {
    path: "/pt/rewrite/psalter",
    component: _5f623b2a,
    name: "rewrite-psalter___pt"
  }, {
    path: "/pt/series/picks",
    component: _50e36392,
    name: "series-picks___pt"
  }, {
    path: "/pt/series/podcasts",
    component: _64c40302,
    name: "series-podcasts___pt"
  }, {
    path: "/pt/series/recent",
    component: _20d64eeb,
    name: "series-recent___pt"
  }, {
    path: "/pt/sermons/categories",
    component: _ac2f59c2,
    name: "sermons-categories___pt"
  }, {
    path: "/pt/sermons/clips",
    component: _745ecf5a,
    name: "sermons-clips___pt"
  }, {
    path: "/pt/sermons/date",
    component: _681d7c31,
    name: "sermons-date___pt"
  }, {
    path: "/pt/sermons/featured",
    component: _5fe950ae,
    name: "sermons-featured___pt"
  }, {
    path: "/pt/sermons/languages",
    component: _4beac38e,
    name: "sermons-languages___pt"
  }, {
    path: "/pt/sermons/scripture",
    component: _714574a0,
    name: "sermons-scripture___pt"
  }, {
    path: "/pt/sermons/staff-picks",
    component: _66663175,
    name: "sermons-staff-picks___pt"
  }, {
    path: "/pt/sermons/topics",
    component: _176e7027,
    name: "sermons-topics___pt"
  }, {
    path: "/pt/sermons/transcripts",
    component: _38c3a1ad,
    name: "sermons-transcripts___pt"
  }, {
    path: "/pt/sermons/videos",
    component: _08765cd8,
    name: "sermons-videos___pt"
  }, {
    path: "/pt/speakers/choice",
    component: _d2fdc598,
    name: "speakers-choice___pt"
  }, {
    path: "/pt/speakers/classic",
    component: _55d73c0f,
    name: "speakers-classic___pt"
  }, {
    path: "/pt/speakers/featured",
    component: _9f75bafe,
    name: "speakers-featured___pt"
  }, {
    path: "/pt/speakers/puritans",
    component: _4a746441,
    name: "speakers-puritans___pt"
  }, {
    path: "/pt/user/about",
    component: _49399981,
    name: "user-about___pt"
  }, {
    path: "/pt/user/login",
    component: _3299175d,
    name: "user-login___pt"
  }, {
    path: "/pt/user/logout",
    component: _6d9adcc6,
    name: "user-logout___pt"
  }, {
    path: "/pt/user/reset-password",
    component: _269a0d15,
    name: "user-reset-password___pt"
  }, {
    path: "/pt/user/settings",
    component: _695ab5df,
    name: "user-settings___pt"
  }, {
    path: "/pt/user/verify",
    component: _4dc24035,
    name: "user-verify___pt"
  }, {
    path: "/pt/video-wall/admin",
    component: _1319d1d8,
    name: "video-wall-admin___pt"
  }, {
    path: "/pt/video-wall/qr",
    component: _d246a2a8,
    name: "video-wall-qr___pt"
  }, {
    path: "/pt/webcasts/recent",
    component: _54da10fc,
    name: "webcasts-recent___pt"
  }, {
    path: "/sermons/date/50s",
    component: _12584357,
    name: "sermons-date-50s___en"
  }, {
    path: "/sermons/date/60s",
    component: _473e7e98,
    name: "sermons-date-60s___en"
  }, {
    path: "/sermons/date/70s",
    component: _7c24b9d9,
    name: "sermons-date-70s___en"
  }, {
    path: "/sermons/date/week",
    component: _4081ec36,
    name: "sermons-date-week___en"
  }, {
    path: "/zh/admin/api",
    component: _0caf89d2,
    name: "admin-api___zh"
  }, {
    path: "/zh/admin/classic-embeds",
    component: _7283484d,
    name: "admin-classic-embeds___zh"
  }, {
    path: "/zh/admin/metadata",
    component: _65765827,
    name: "admin-metadata___zh"
  }, {
    path: "/zh/admin/speakers",
    component: _517b62cc,
    name: "admin-speakers___zh"
  }, {
    path: "/zh/broadcaster/linking-users",
    component: _59df70fa,
    name: "broadcaster-linking-users___zh"
  }, {
    path: "/zh/broadcaster/select",
    component: _2ecdac35,
    name: "broadcaster-select___zh"
  }, {
    path: "/zh/broadcaster/sign-in",
    component: _05b164cc,
    name: "broadcaster-sign-in___zh"
  }, {
    path: "/zh/broadcaster/sign-up",
    component: _1a47bb42,
    name: "broadcaster-sign-up___zh"
  }, {
    path: "/zh/broadcasters/featured",
    component: _ef664520,
    name: "broadcasters-featured___zh"
  }, {
    path: "/zh/broadcasters/group",
    component: _3cb3b620,
    name: "broadcasters-group___zh"
  }, {
    path: "/zh/broadcasters/international",
    component: _460fd288,
    name: "broadcasters-international___zh"
  }, {
    path: "/zh/broadcasters/podcast",
    component: _3888a8b2,
    name: "broadcasters-podcast___zh"
  }, {
    path: "/zh/broadcasters/school",
    component: _07b8b794,
    name: "broadcasters-school___zh"
  }, {
    path: "/zh/broadcasters/seminary",
    component: _8490e02c,
    name: "broadcasters-seminary___zh"
  }, {
    path: "/zh/broadcasters/usa",
    component: _b654e29e,
    name: "broadcasters-usa___zh"
  }, {
    path: "/zh/broadcasters/vacant",
    component: _799441de,
    name: "broadcasters-vacant___zh"
  }, {
    path: "/zh/dashboard/articles",
    component: _ddff98da,
    name: "dashboard-articles___zh"
  }, {
    path: "/zh/demo/asyncdata",
    component: _5dd50fb2,
    name: "demo-asyncdata___zh"
  }, {
    path: "/zh/demo/audio-embeds",
    component: _abedff96,
    name: "demo-audio-embeds___zh"
  }, {
    path: "/zh/demo/broadcaster",
    component: _fa06d88c,
    name: "demo-broadcaster___zh"
  }, {
    path: "/zh/demo/broadcaster-locations",
    component: _0bfe0aea,
    name: "demo-broadcaster-locations___zh"
  }, {
    path: "/zh/demo/buttons",
    component: _4fae7ae6,
    name: "demo-buttons___zh"
  }, {
    path: "/zh/demo/cornerstone",
    component: _26d5acbc,
    name: "demo-cornerstone___zh"
  }, {
    path: "/zh/demo/dates",
    component: _0178d6de,
    name: "demo-dates___zh"
  }, {
    path: "/zh/demo/device",
    component: _7596630c,
    name: "demo-device___zh"
  }, {
    path: "/zh/demo/legacy-urls",
    component: _52a4ac18,
    name: "demo-legacy-urls___zh"
  }, {
    path: "/zh/demo/md",
    component: _0ce85b3b,
    name: "demo-md___zh"
  }, {
    path: "/zh/demo/mde",
    component: _d9ed378c,
    name: "demo-mde___zh"
  }, {
    path: "/zh/demo/s3",
    component: _1472ef44,
    name: "demo-s3___zh"
  }, {
    path: "/zh/demo/search-pills",
    component: _3dce7199,
    name: "demo-search-pills___zh"
  }, {
    path: "/zh/demo/series",
    component: _7a2cb61b,
    name: "demo-series___zh"
  }, {
    path: "/zh/demo/sermon",
    component: _5e8fa3d0,
    name: "demo-sermon___zh"
  }, {
    path: "/zh/demo/speaker",
    component: _a5a417ea,
    name: "demo-speaker___zh"
  }, {
    path: "/zh/demo/speaker-picker",
    component: _6900f740,
    name: "demo-speaker-picker___zh"
  }, {
    path: "/zh/demo/video-embeds",
    component: _3aab1e70,
    name: "demo-video-embeds___zh"
  }, {
    path: "/zh/library/bookmarks",
    component: _6ff6cca1,
    name: "library-bookmarks___zh"
  }, {
    path: "/zh/library/comments",
    component: _5fa7d300,
    name: "library-comments___zh"
  }, {
    path: "/zh/library/history",
    component: _a2928290,
    name: "library-history___zh"
  }, {
    path: "/zh/rewrite/psalter",
    component: _5f623b2a,
    name: "rewrite-psalter___zh"
  }, {
    path: "/zh/series/picks",
    component: _50e36392,
    name: "series-picks___zh"
  }, {
    path: "/zh/series/podcasts",
    component: _64c40302,
    name: "series-podcasts___zh"
  }, {
    path: "/zh/series/recent",
    component: _20d64eeb,
    name: "series-recent___zh"
  }, {
    path: "/zh/sermons/categories",
    component: _ac2f59c2,
    name: "sermons-categories___zh"
  }, {
    path: "/zh/sermons/clips",
    component: _745ecf5a,
    name: "sermons-clips___zh"
  }, {
    path: "/zh/sermons/date",
    component: _681d7c31,
    name: "sermons-date___zh"
  }, {
    path: "/zh/sermons/featured",
    component: _5fe950ae,
    name: "sermons-featured___zh"
  }, {
    path: "/zh/sermons/languages",
    component: _4beac38e,
    name: "sermons-languages___zh"
  }, {
    path: "/zh/sermons/scripture",
    component: _714574a0,
    name: "sermons-scripture___zh"
  }, {
    path: "/zh/sermons/staff-picks",
    component: _66663175,
    name: "sermons-staff-picks___zh"
  }, {
    path: "/zh/sermons/topics",
    component: _176e7027,
    name: "sermons-topics___zh"
  }, {
    path: "/zh/sermons/transcripts",
    component: _38c3a1ad,
    name: "sermons-transcripts___zh"
  }, {
    path: "/zh/sermons/videos",
    component: _08765cd8,
    name: "sermons-videos___zh"
  }, {
    path: "/zh/speakers/choice",
    component: _d2fdc598,
    name: "speakers-choice___zh"
  }, {
    path: "/zh/speakers/classic",
    component: _55d73c0f,
    name: "speakers-classic___zh"
  }, {
    path: "/zh/speakers/featured",
    component: _9f75bafe,
    name: "speakers-featured___zh"
  }, {
    path: "/zh/speakers/puritans",
    component: _4a746441,
    name: "speakers-puritans___zh"
  }, {
    path: "/zh/user/about",
    component: _49399981,
    name: "user-about___zh"
  }, {
    path: "/zh/user/login",
    component: _3299175d,
    name: "user-login___zh"
  }, {
    path: "/zh/user/logout",
    component: _6d9adcc6,
    name: "user-logout___zh"
  }, {
    path: "/zh/user/reset-password",
    component: _269a0d15,
    name: "user-reset-password___zh"
  }, {
    path: "/zh/user/settings",
    component: _695ab5df,
    name: "user-settings___zh"
  }, {
    path: "/zh/user/verify",
    component: _4dc24035,
    name: "user-verify___zh"
  }, {
    path: "/zh/video-wall/admin",
    component: _1319d1d8,
    name: "video-wall-admin___zh"
  }, {
    path: "/zh/video-wall/qr",
    component: _d246a2a8,
    name: "video-wall-qr___zh"
  }, {
    path: "/zh/webcasts/recent",
    component: _54da10fc,
    name: "webcasts-recent___zh"
  }, {
    path: "/de/dashboard/account/customization",
    component: _8e303aa4,
    name: "dashboard-account-customization___de"
  }, {
    path: "/de/dashboard/account/donations",
    component: _b01caf08,
    name: "dashboard-account-donations___de"
  }, {
    path: "/de/dashboard/account/email-settings",
    component: _6353ca69,
    name: "dashboard-account-email-settings___de"
  }, {
    path: "/de/dashboard/account/managers",
    component: _5bb8fe5b,
    name: "dashboard-account-managers___de"
  }, {
    path: "/de/dashboard/account/settings",
    component: _3157ad98,
    name: "dashboard-account-settings___de"
  }, {
    path: "/de/dashboard/django/articles",
    component: _3c962d1e,
    name: "dashboard-django-articles___de"
  }, {
    path: "/de/dashboard/django/customization",
    component: _45933172,
    name: "dashboard-django-customization___de"
  }, {
    path: "/de/dashboard/django/donations",
    component: _6efaf940,
    name: "dashboard-django-donations___de"
  }, {
    path: "/de/dashboard/django/email-settings",
    component: _6c125425,
    name: "dashboard-django-email-settings___de"
  }, {
    path: "/de/dashboard/django/managers",
    component: _51a08517,
    name: "dashboard-django-managers___de"
  }, {
    path: "/de/dashboard/django/settings",
    component: _273f3454,
    name: "dashboard-django-settings___de"
  }, {
    path: "/de/dashboard/embed/link-user",
    component: _127bbd85,
    name: "dashboard-embed-link-user___de"
  }, {
    path: "/de/dashboard/embed/payment-method-input",
    component: _73465e64,
    name: "dashboard-embed-payment-method-input___de"
  }, {
    path: "/de/dashboard/embed/user-dropdown",
    component: _3b84a19a,
    name: "dashboard-embed-user-dropdown___de"
  }, {
    path: "/de/embed/classic/examples",
    component: _08602154,
    name: "embed-classic-examples___de"
  }, {
    path: "/de/sermons/date/50s",
    component: _12584357,
    name: "sermons-date-50s___de"
  }, {
    path: "/de/sermons/date/60s",
    component: _473e7e98,
    name: "sermons-date-60s___de"
  }, {
    path: "/de/sermons/date/70s",
    component: _7c24b9d9,
    name: "sermons-date-70s___de"
  }, {
    path: "/de/sermons/date/week",
    component: _4081ec36,
    name: "sermons-date-week___de"
  }, {
    path: "/es/dashboard/account/customization",
    component: _8e303aa4,
    name: "dashboard-account-customization___es"
  }, {
    path: "/es/dashboard/account/donations",
    component: _b01caf08,
    name: "dashboard-account-donations___es"
  }, {
    path: "/es/dashboard/account/email-settings",
    component: _6353ca69,
    name: "dashboard-account-email-settings___es"
  }, {
    path: "/es/dashboard/account/managers",
    component: _5bb8fe5b,
    name: "dashboard-account-managers___es"
  }, {
    path: "/es/dashboard/account/settings",
    component: _3157ad98,
    name: "dashboard-account-settings___es"
  }, {
    path: "/es/dashboard/django/articles",
    component: _3c962d1e,
    name: "dashboard-django-articles___es"
  }, {
    path: "/es/dashboard/django/customization",
    component: _45933172,
    name: "dashboard-django-customization___es"
  }, {
    path: "/es/dashboard/django/donations",
    component: _6efaf940,
    name: "dashboard-django-donations___es"
  }, {
    path: "/es/dashboard/django/email-settings",
    component: _6c125425,
    name: "dashboard-django-email-settings___es"
  }, {
    path: "/es/dashboard/django/managers",
    component: _51a08517,
    name: "dashboard-django-managers___es"
  }, {
    path: "/es/dashboard/django/settings",
    component: _273f3454,
    name: "dashboard-django-settings___es"
  }, {
    path: "/es/dashboard/embed/link-user",
    component: _127bbd85,
    name: "dashboard-embed-link-user___es"
  }, {
    path: "/es/dashboard/embed/payment-method-input",
    component: _73465e64,
    name: "dashboard-embed-payment-method-input___es"
  }, {
    path: "/es/dashboard/embed/user-dropdown",
    component: _3b84a19a,
    name: "dashboard-embed-user-dropdown___es"
  }, {
    path: "/es/embed/classic/examples",
    component: _08602154,
    name: "embed-classic-examples___es"
  }, {
    path: "/es/sermons/date/50s",
    component: _12584357,
    name: "sermons-date-50s___es"
  }, {
    path: "/es/sermons/date/60s",
    component: _473e7e98,
    name: "sermons-date-60s___es"
  }, {
    path: "/es/sermons/date/70s",
    component: _7c24b9d9,
    name: "sermons-date-70s___es"
  }, {
    path: "/es/sermons/date/week",
    component: _4081ec36,
    name: "sermons-date-week___es"
  }, {
    path: "/fr/dashboard/account/customization",
    component: _8e303aa4,
    name: "dashboard-account-customization___fr"
  }, {
    path: "/fr/dashboard/account/donations",
    component: _b01caf08,
    name: "dashboard-account-donations___fr"
  }, {
    path: "/fr/dashboard/account/email-settings",
    component: _6353ca69,
    name: "dashboard-account-email-settings___fr"
  }, {
    path: "/fr/dashboard/account/managers",
    component: _5bb8fe5b,
    name: "dashboard-account-managers___fr"
  }, {
    path: "/fr/dashboard/account/settings",
    component: _3157ad98,
    name: "dashboard-account-settings___fr"
  }, {
    path: "/fr/dashboard/django/articles",
    component: _3c962d1e,
    name: "dashboard-django-articles___fr"
  }, {
    path: "/fr/dashboard/django/customization",
    component: _45933172,
    name: "dashboard-django-customization___fr"
  }, {
    path: "/fr/dashboard/django/donations",
    component: _6efaf940,
    name: "dashboard-django-donations___fr"
  }, {
    path: "/fr/dashboard/django/email-settings",
    component: _6c125425,
    name: "dashboard-django-email-settings___fr"
  }, {
    path: "/fr/dashboard/django/managers",
    component: _51a08517,
    name: "dashboard-django-managers___fr"
  }, {
    path: "/fr/dashboard/django/settings",
    component: _273f3454,
    name: "dashboard-django-settings___fr"
  }, {
    path: "/fr/dashboard/embed/link-user",
    component: _127bbd85,
    name: "dashboard-embed-link-user___fr"
  }, {
    path: "/fr/dashboard/embed/payment-method-input",
    component: _73465e64,
    name: "dashboard-embed-payment-method-input___fr"
  }, {
    path: "/fr/dashboard/embed/user-dropdown",
    component: _3b84a19a,
    name: "dashboard-embed-user-dropdown___fr"
  }, {
    path: "/fr/embed/classic/examples",
    component: _08602154,
    name: "embed-classic-examples___fr"
  }, {
    path: "/fr/sermons/date/50s",
    component: _12584357,
    name: "sermons-date-50s___fr"
  }, {
    path: "/fr/sermons/date/60s",
    component: _473e7e98,
    name: "sermons-date-60s___fr"
  }, {
    path: "/fr/sermons/date/70s",
    component: _7c24b9d9,
    name: "sermons-date-70s___fr"
  }, {
    path: "/fr/sermons/date/week",
    component: _4081ec36,
    name: "sermons-date-week___fr"
  }, {
    path: "/gb/dashboard/account/customization",
    component: _8e303aa4,
    name: "dashboard-account-customization___gb"
  }, {
    path: "/gb/dashboard/account/donations",
    component: _b01caf08,
    name: "dashboard-account-donations___gb"
  }, {
    path: "/gb/dashboard/account/email-settings",
    component: _6353ca69,
    name: "dashboard-account-email-settings___gb"
  }, {
    path: "/gb/dashboard/account/managers",
    component: _5bb8fe5b,
    name: "dashboard-account-managers___gb"
  }, {
    path: "/gb/dashboard/account/settings",
    component: _3157ad98,
    name: "dashboard-account-settings___gb"
  }, {
    path: "/gb/dashboard/django/articles",
    component: _3c962d1e,
    name: "dashboard-django-articles___gb"
  }, {
    path: "/gb/dashboard/django/customization",
    component: _45933172,
    name: "dashboard-django-customization___gb"
  }, {
    path: "/gb/dashboard/django/donations",
    component: _6efaf940,
    name: "dashboard-django-donations___gb"
  }, {
    path: "/gb/dashboard/django/email-settings",
    component: _6c125425,
    name: "dashboard-django-email-settings___gb"
  }, {
    path: "/gb/dashboard/django/managers",
    component: _51a08517,
    name: "dashboard-django-managers___gb"
  }, {
    path: "/gb/dashboard/django/settings",
    component: _273f3454,
    name: "dashboard-django-settings___gb"
  }, {
    path: "/gb/dashboard/embed/link-user",
    component: _127bbd85,
    name: "dashboard-embed-link-user___gb"
  }, {
    path: "/gb/dashboard/embed/payment-method-input",
    component: _73465e64,
    name: "dashboard-embed-payment-method-input___gb"
  }, {
    path: "/gb/dashboard/embed/user-dropdown",
    component: _3b84a19a,
    name: "dashboard-embed-user-dropdown___gb"
  }, {
    path: "/gb/embed/classic/examples",
    component: _08602154,
    name: "embed-classic-examples___gb"
  }, {
    path: "/gb/sermons/date/50s",
    component: _12584357,
    name: "sermons-date-50s___gb"
  }, {
    path: "/gb/sermons/date/60s",
    component: _473e7e98,
    name: "sermons-date-60s___gb"
  }, {
    path: "/gb/sermons/date/70s",
    component: _7c24b9d9,
    name: "sermons-date-70s___gb"
  }, {
    path: "/gb/sermons/date/week",
    component: _4081ec36,
    name: "sermons-date-week___gb"
  }, {
    path: "/hi/dashboard/account/customization",
    component: _8e303aa4,
    name: "dashboard-account-customization___hi"
  }, {
    path: "/hi/dashboard/account/donations",
    component: _b01caf08,
    name: "dashboard-account-donations___hi"
  }, {
    path: "/hi/dashboard/account/email-settings",
    component: _6353ca69,
    name: "dashboard-account-email-settings___hi"
  }, {
    path: "/hi/dashboard/account/managers",
    component: _5bb8fe5b,
    name: "dashboard-account-managers___hi"
  }, {
    path: "/hi/dashboard/account/settings",
    component: _3157ad98,
    name: "dashboard-account-settings___hi"
  }, {
    path: "/hi/dashboard/django/articles",
    component: _3c962d1e,
    name: "dashboard-django-articles___hi"
  }, {
    path: "/hi/dashboard/django/customization",
    component: _45933172,
    name: "dashboard-django-customization___hi"
  }, {
    path: "/hi/dashboard/django/donations",
    component: _6efaf940,
    name: "dashboard-django-donations___hi"
  }, {
    path: "/hi/dashboard/django/email-settings",
    component: _6c125425,
    name: "dashboard-django-email-settings___hi"
  }, {
    path: "/hi/dashboard/django/managers",
    component: _51a08517,
    name: "dashboard-django-managers___hi"
  }, {
    path: "/hi/dashboard/django/settings",
    component: _273f3454,
    name: "dashboard-django-settings___hi"
  }, {
    path: "/hi/dashboard/embed/link-user",
    component: _127bbd85,
    name: "dashboard-embed-link-user___hi"
  }, {
    path: "/hi/dashboard/embed/payment-method-input",
    component: _73465e64,
    name: "dashboard-embed-payment-method-input___hi"
  }, {
    path: "/hi/dashboard/embed/user-dropdown",
    component: _3b84a19a,
    name: "dashboard-embed-user-dropdown___hi"
  }, {
    path: "/hi/embed/classic/examples",
    component: _08602154,
    name: "embed-classic-examples___hi"
  }, {
    path: "/hi/sermons/date/50s",
    component: _12584357,
    name: "sermons-date-50s___hi"
  }, {
    path: "/hi/sermons/date/60s",
    component: _473e7e98,
    name: "sermons-date-60s___hi"
  }, {
    path: "/hi/sermons/date/70s",
    component: _7c24b9d9,
    name: "sermons-date-70s___hi"
  }, {
    path: "/hi/sermons/date/week",
    component: _4081ec36,
    name: "sermons-date-week___hi"
  }, {
    path: "/it/dashboard/account/customization",
    component: _8e303aa4,
    name: "dashboard-account-customization___it"
  }, {
    path: "/it/dashboard/account/donations",
    component: _b01caf08,
    name: "dashboard-account-donations___it"
  }, {
    path: "/it/dashboard/account/email-settings",
    component: _6353ca69,
    name: "dashboard-account-email-settings___it"
  }, {
    path: "/it/dashboard/account/managers",
    component: _5bb8fe5b,
    name: "dashboard-account-managers___it"
  }, {
    path: "/it/dashboard/account/settings",
    component: _3157ad98,
    name: "dashboard-account-settings___it"
  }, {
    path: "/it/dashboard/django/articles",
    component: _3c962d1e,
    name: "dashboard-django-articles___it"
  }, {
    path: "/it/dashboard/django/customization",
    component: _45933172,
    name: "dashboard-django-customization___it"
  }, {
    path: "/it/dashboard/django/donations",
    component: _6efaf940,
    name: "dashboard-django-donations___it"
  }, {
    path: "/it/dashboard/django/email-settings",
    component: _6c125425,
    name: "dashboard-django-email-settings___it"
  }, {
    path: "/it/dashboard/django/managers",
    component: _51a08517,
    name: "dashboard-django-managers___it"
  }, {
    path: "/it/dashboard/django/settings",
    component: _273f3454,
    name: "dashboard-django-settings___it"
  }, {
    path: "/it/dashboard/embed/link-user",
    component: _127bbd85,
    name: "dashboard-embed-link-user___it"
  }, {
    path: "/it/dashboard/embed/payment-method-input",
    component: _73465e64,
    name: "dashboard-embed-payment-method-input___it"
  }, {
    path: "/it/dashboard/embed/user-dropdown",
    component: _3b84a19a,
    name: "dashboard-embed-user-dropdown___it"
  }, {
    path: "/it/embed/classic/examples",
    component: _08602154,
    name: "embed-classic-examples___it"
  }, {
    path: "/it/sermons/date/50s",
    component: _12584357,
    name: "sermons-date-50s___it"
  }, {
    path: "/it/sermons/date/60s",
    component: _473e7e98,
    name: "sermons-date-60s___it"
  }, {
    path: "/it/sermons/date/70s",
    component: _7c24b9d9,
    name: "sermons-date-70s___it"
  }, {
    path: "/it/sermons/date/week",
    component: _4081ec36,
    name: "sermons-date-week___it"
  }, {
    path: "/km/dashboard/account/customization",
    component: _8e303aa4,
    name: "dashboard-account-customization___km"
  }, {
    path: "/km/dashboard/account/donations",
    component: _b01caf08,
    name: "dashboard-account-donations___km"
  }, {
    path: "/km/dashboard/account/email-settings",
    component: _6353ca69,
    name: "dashboard-account-email-settings___km"
  }, {
    path: "/km/dashboard/account/managers",
    component: _5bb8fe5b,
    name: "dashboard-account-managers___km"
  }, {
    path: "/km/dashboard/account/settings",
    component: _3157ad98,
    name: "dashboard-account-settings___km"
  }, {
    path: "/km/dashboard/django/articles",
    component: _3c962d1e,
    name: "dashboard-django-articles___km"
  }, {
    path: "/km/dashboard/django/customization",
    component: _45933172,
    name: "dashboard-django-customization___km"
  }, {
    path: "/km/dashboard/django/donations",
    component: _6efaf940,
    name: "dashboard-django-donations___km"
  }, {
    path: "/km/dashboard/django/email-settings",
    component: _6c125425,
    name: "dashboard-django-email-settings___km"
  }, {
    path: "/km/dashboard/django/managers",
    component: _51a08517,
    name: "dashboard-django-managers___km"
  }, {
    path: "/km/dashboard/django/settings",
    component: _273f3454,
    name: "dashboard-django-settings___km"
  }, {
    path: "/km/dashboard/embed/link-user",
    component: _127bbd85,
    name: "dashboard-embed-link-user___km"
  }, {
    path: "/km/dashboard/embed/payment-method-input",
    component: _73465e64,
    name: "dashboard-embed-payment-method-input___km"
  }, {
    path: "/km/dashboard/embed/user-dropdown",
    component: _3b84a19a,
    name: "dashboard-embed-user-dropdown___km"
  }, {
    path: "/km/embed/classic/examples",
    component: _08602154,
    name: "embed-classic-examples___km"
  }, {
    path: "/km/sermons/date/50s",
    component: _12584357,
    name: "sermons-date-50s___km"
  }, {
    path: "/km/sermons/date/60s",
    component: _473e7e98,
    name: "sermons-date-60s___km"
  }, {
    path: "/km/sermons/date/70s",
    component: _7c24b9d9,
    name: "sermons-date-70s___km"
  }, {
    path: "/km/sermons/date/week",
    component: _4081ec36,
    name: "sermons-date-week___km"
  }, {
    path: "/ko/dashboard/account/customization",
    component: _8e303aa4,
    name: "dashboard-account-customization___ko"
  }, {
    path: "/ko/dashboard/account/donations",
    component: _b01caf08,
    name: "dashboard-account-donations___ko"
  }, {
    path: "/ko/dashboard/account/email-settings",
    component: _6353ca69,
    name: "dashboard-account-email-settings___ko"
  }, {
    path: "/ko/dashboard/account/managers",
    component: _5bb8fe5b,
    name: "dashboard-account-managers___ko"
  }, {
    path: "/ko/dashboard/account/settings",
    component: _3157ad98,
    name: "dashboard-account-settings___ko"
  }, {
    path: "/ko/dashboard/django/articles",
    component: _3c962d1e,
    name: "dashboard-django-articles___ko"
  }, {
    path: "/ko/dashboard/django/customization",
    component: _45933172,
    name: "dashboard-django-customization___ko"
  }, {
    path: "/ko/dashboard/django/donations",
    component: _6efaf940,
    name: "dashboard-django-donations___ko"
  }, {
    path: "/ko/dashboard/django/email-settings",
    component: _6c125425,
    name: "dashboard-django-email-settings___ko"
  }, {
    path: "/ko/dashboard/django/managers",
    component: _51a08517,
    name: "dashboard-django-managers___ko"
  }, {
    path: "/ko/dashboard/django/settings",
    component: _273f3454,
    name: "dashboard-django-settings___ko"
  }, {
    path: "/ko/dashboard/embed/link-user",
    component: _127bbd85,
    name: "dashboard-embed-link-user___ko"
  }, {
    path: "/ko/dashboard/embed/payment-method-input",
    component: _73465e64,
    name: "dashboard-embed-payment-method-input___ko"
  }, {
    path: "/ko/dashboard/embed/user-dropdown",
    component: _3b84a19a,
    name: "dashboard-embed-user-dropdown___ko"
  }, {
    path: "/ko/embed/classic/examples",
    component: _08602154,
    name: "embed-classic-examples___ko"
  }, {
    path: "/ko/sermons/date/50s",
    component: _12584357,
    name: "sermons-date-50s___ko"
  }, {
    path: "/ko/sermons/date/60s",
    component: _473e7e98,
    name: "sermons-date-60s___ko"
  }, {
    path: "/ko/sermons/date/70s",
    component: _7c24b9d9,
    name: "sermons-date-70s___ko"
  }, {
    path: "/ko/sermons/date/week",
    component: _4081ec36,
    name: "sermons-date-week___ko"
  }, {
    path: "/pt/dashboard/account/customization",
    component: _8e303aa4,
    name: "dashboard-account-customization___pt"
  }, {
    path: "/pt/dashboard/account/donations",
    component: _b01caf08,
    name: "dashboard-account-donations___pt"
  }, {
    path: "/pt/dashboard/account/email-settings",
    component: _6353ca69,
    name: "dashboard-account-email-settings___pt"
  }, {
    path: "/pt/dashboard/account/managers",
    component: _5bb8fe5b,
    name: "dashboard-account-managers___pt"
  }, {
    path: "/pt/dashboard/account/settings",
    component: _3157ad98,
    name: "dashboard-account-settings___pt"
  }, {
    path: "/pt/dashboard/django/articles",
    component: _3c962d1e,
    name: "dashboard-django-articles___pt"
  }, {
    path: "/pt/dashboard/django/customization",
    component: _45933172,
    name: "dashboard-django-customization___pt"
  }, {
    path: "/pt/dashboard/django/donations",
    component: _6efaf940,
    name: "dashboard-django-donations___pt"
  }, {
    path: "/pt/dashboard/django/email-settings",
    component: _6c125425,
    name: "dashboard-django-email-settings___pt"
  }, {
    path: "/pt/dashboard/django/managers",
    component: _51a08517,
    name: "dashboard-django-managers___pt"
  }, {
    path: "/pt/dashboard/django/settings",
    component: _273f3454,
    name: "dashboard-django-settings___pt"
  }, {
    path: "/pt/dashboard/embed/link-user",
    component: _127bbd85,
    name: "dashboard-embed-link-user___pt"
  }, {
    path: "/pt/dashboard/embed/payment-method-input",
    component: _73465e64,
    name: "dashboard-embed-payment-method-input___pt"
  }, {
    path: "/pt/dashboard/embed/user-dropdown",
    component: _3b84a19a,
    name: "dashboard-embed-user-dropdown___pt"
  }, {
    path: "/pt/embed/classic/examples",
    component: _08602154,
    name: "embed-classic-examples___pt"
  }, {
    path: "/pt/sermons/date/50s",
    component: _12584357,
    name: "sermons-date-50s___pt"
  }, {
    path: "/pt/sermons/date/60s",
    component: _473e7e98,
    name: "sermons-date-60s___pt"
  }, {
    path: "/pt/sermons/date/70s",
    component: _7c24b9d9,
    name: "sermons-date-70s___pt"
  }, {
    path: "/pt/sermons/date/week",
    component: _4081ec36,
    name: "sermons-date-week___pt"
  }, {
    path: "/zh/dashboard/account/customization",
    component: _8e303aa4,
    name: "dashboard-account-customization___zh"
  }, {
    path: "/zh/dashboard/account/donations",
    component: _b01caf08,
    name: "dashboard-account-donations___zh"
  }, {
    path: "/zh/dashboard/account/email-settings",
    component: _6353ca69,
    name: "dashboard-account-email-settings___zh"
  }, {
    path: "/zh/dashboard/account/managers",
    component: _5bb8fe5b,
    name: "dashboard-account-managers___zh"
  }, {
    path: "/zh/dashboard/account/settings",
    component: _3157ad98,
    name: "dashboard-account-settings___zh"
  }, {
    path: "/zh/dashboard/django/articles",
    component: _3c962d1e,
    name: "dashboard-django-articles___zh"
  }, {
    path: "/zh/dashboard/django/customization",
    component: _45933172,
    name: "dashboard-django-customization___zh"
  }, {
    path: "/zh/dashboard/django/donations",
    component: _6efaf940,
    name: "dashboard-django-donations___zh"
  }, {
    path: "/zh/dashboard/django/email-settings",
    component: _6c125425,
    name: "dashboard-django-email-settings___zh"
  }, {
    path: "/zh/dashboard/django/managers",
    component: _51a08517,
    name: "dashboard-django-managers___zh"
  }, {
    path: "/zh/dashboard/django/settings",
    component: _273f3454,
    name: "dashboard-django-settings___zh"
  }, {
    path: "/zh/dashboard/embed/link-user",
    component: _127bbd85,
    name: "dashboard-embed-link-user___zh"
  }, {
    path: "/zh/dashboard/embed/payment-method-input",
    component: _73465e64,
    name: "dashboard-embed-payment-method-input___zh"
  }, {
    path: "/zh/dashboard/embed/user-dropdown",
    component: _3b84a19a,
    name: "dashboard-embed-user-dropdown___zh"
  }, {
    path: "/zh/embed/classic/examples",
    component: _08602154,
    name: "embed-classic-examples___zh"
  }, {
    path: "/zh/sermons/date/50s",
    component: _12584357,
    name: "sermons-date-50s___zh"
  }, {
    path: "/zh/sermons/date/60s",
    component: _473e7e98,
    name: "sermons-date-60s___zh"
  }, {
    path: "/zh/sermons/date/70s",
    component: _7c24b9d9,
    name: "sermons-date-70s___zh"
  }, {
    path: "/zh/sermons/date/week",
    component: _4081ec36,
    name: "sermons-date-week___zh"
  }, {
    path: "/",
    component: _bf4be96c,
    name: "index___en"
  }, {
    path: "/de/embed/classic/player/l/:broadcasterID?",
    component: _56caa800,
    name: "embed-classic-player-l-broadcasterID___de"
  }, {
    path: "/de/player/sermon/legacy/video/:sermon?",
    component: _716b6a7e,
    name: "player-sermon-legacy-video-sermon___de"
  }, {
    path: "/es/embed/classic/player/l/:broadcasterID?",
    component: _56caa800,
    name: "embed-classic-player-l-broadcasterID___es"
  }, {
    path: "/es/player/sermon/legacy/video/:sermon?",
    component: _716b6a7e,
    name: "player-sermon-legacy-video-sermon___es"
  }, {
    path: "/fr/embed/classic/player/l/:broadcasterID?",
    component: _56caa800,
    name: "embed-classic-player-l-broadcasterID___fr"
  }, {
    path: "/fr/player/sermon/legacy/video/:sermon?",
    component: _716b6a7e,
    name: "player-sermon-legacy-video-sermon___fr"
  }, {
    path: "/gb/embed/classic/player/l/:broadcasterID?",
    component: _56caa800,
    name: "embed-classic-player-l-broadcasterID___gb"
  }, {
    path: "/gb/player/sermon/legacy/video/:sermon?",
    component: _716b6a7e,
    name: "player-sermon-legacy-video-sermon___gb"
  }, {
    path: "/hi/embed/classic/player/l/:broadcasterID?",
    component: _56caa800,
    name: "embed-classic-player-l-broadcasterID___hi"
  }, {
    path: "/hi/player/sermon/legacy/video/:sermon?",
    component: _716b6a7e,
    name: "player-sermon-legacy-video-sermon___hi"
  }, {
    path: "/it/embed/classic/player/l/:broadcasterID?",
    component: _56caa800,
    name: "embed-classic-player-l-broadcasterID___it"
  }, {
    path: "/it/player/sermon/legacy/video/:sermon?",
    component: _716b6a7e,
    name: "player-sermon-legacy-video-sermon___it"
  }, {
    path: "/km/embed/classic/player/l/:broadcasterID?",
    component: _56caa800,
    name: "embed-classic-player-l-broadcasterID___km"
  }, {
    path: "/km/player/sermon/legacy/video/:sermon?",
    component: _716b6a7e,
    name: "player-sermon-legacy-video-sermon___km"
  }, {
    path: "/ko/embed/classic/player/l/:broadcasterID?",
    component: _56caa800,
    name: "embed-classic-player-l-broadcasterID___ko"
  }, {
    path: "/ko/player/sermon/legacy/video/:sermon?",
    component: _716b6a7e,
    name: "player-sermon-legacy-video-sermon___ko"
  }, {
    path: "/pt/embed/classic/player/l/:broadcasterID?",
    component: _56caa800,
    name: "embed-classic-player-l-broadcasterID___pt"
  }, {
    path: "/pt/player/sermon/legacy/video/:sermon?",
    component: _716b6a7e,
    name: "player-sermon-legacy-video-sermon___pt"
  }, {
    path: "/zh/embed/classic/player/l/:broadcasterID?",
    component: _56caa800,
    name: "embed-classic-player-l-broadcasterID___zh"
  }, {
    path: "/zh/player/sermon/legacy/video/:sermon?",
    component: _716b6a7e,
    name: "player-sermon-legacy-video-sermon___zh"
  }, {
    path: "/de/dashboard/django/articles/:articleID",
    component: _774fca03,
    name: "dashboard-django-articles-articleID___de"
  }, {
    path: "/de/player/sermon/legacy/:sermon?",
    component: _40aa2952,
    name: "player-sermon-legacy-sermon___de"
  }, {
    path: "/de/promotions/stats/ad/:adId?",
    component: _23cca990,
    name: "promotions-stats-ad-adId___de"
  }, {
    path: "/de/promotions/stats/all/:adType?",
    component: _02b08957,
    name: "promotions-stats-all-adType___de"
  }, {
    path: "/de/rewrite/psalter/search/:keyword?",
    component: _07d07bec,
    name: "rewrite-psalter-search-keyword___de"
  }, {
    path: "/embed/classic/player/l/:broadcasterID?",
    component: _56caa800,
    name: "embed-classic-player-l-broadcasterID___en"
  }, {
    path: "/es/dashboard/django/articles/:articleID",
    component: _774fca03,
    name: "dashboard-django-articles-articleID___es"
  }, {
    path: "/es/player/sermon/legacy/:sermon?",
    component: _40aa2952,
    name: "player-sermon-legacy-sermon___es"
  }, {
    path: "/es/promotions/stats/ad/:adId?",
    component: _23cca990,
    name: "promotions-stats-ad-adId___es"
  }, {
    path: "/es/promotions/stats/all/:adType?",
    component: _02b08957,
    name: "promotions-stats-all-adType___es"
  }, {
    path: "/es/rewrite/psalter/search/:keyword?",
    component: _07d07bec,
    name: "rewrite-psalter-search-keyword___es"
  }, {
    path: "/fr/dashboard/django/articles/:articleID",
    component: _774fca03,
    name: "dashboard-django-articles-articleID___fr"
  }, {
    path: "/fr/player/sermon/legacy/:sermon?",
    component: _40aa2952,
    name: "player-sermon-legacy-sermon___fr"
  }, {
    path: "/fr/promotions/stats/ad/:adId?",
    component: _23cca990,
    name: "promotions-stats-ad-adId___fr"
  }, {
    path: "/fr/promotions/stats/all/:adType?",
    component: _02b08957,
    name: "promotions-stats-all-adType___fr"
  }, {
    path: "/fr/rewrite/psalter/search/:keyword?",
    component: _07d07bec,
    name: "rewrite-psalter-search-keyword___fr"
  }, {
    path: "/gb/dashboard/django/articles/:articleID",
    component: _774fca03,
    name: "dashboard-django-articles-articleID___gb"
  }, {
    path: "/gb/player/sermon/legacy/:sermon?",
    component: _40aa2952,
    name: "player-sermon-legacy-sermon___gb"
  }, {
    path: "/gb/promotions/stats/ad/:adId?",
    component: _23cca990,
    name: "promotions-stats-ad-adId___gb"
  }, {
    path: "/gb/promotions/stats/all/:adType?",
    component: _02b08957,
    name: "promotions-stats-all-adType___gb"
  }, {
    path: "/gb/rewrite/psalter/search/:keyword?",
    component: _07d07bec,
    name: "rewrite-psalter-search-keyword___gb"
  }, {
    path: "/hi/dashboard/django/articles/:articleID",
    component: _774fca03,
    name: "dashboard-django-articles-articleID___hi"
  }, {
    path: "/hi/player/sermon/legacy/:sermon?",
    component: _40aa2952,
    name: "player-sermon-legacy-sermon___hi"
  }, {
    path: "/hi/promotions/stats/ad/:adId?",
    component: _23cca990,
    name: "promotions-stats-ad-adId___hi"
  }, {
    path: "/hi/promotions/stats/all/:adType?",
    component: _02b08957,
    name: "promotions-stats-all-adType___hi"
  }, {
    path: "/hi/rewrite/psalter/search/:keyword?",
    component: _07d07bec,
    name: "rewrite-psalter-search-keyword___hi"
  }, {
    path: "/it/dashboard/django/articles/:articleID",
    component: _774fca03,
    name: "dashboard-django-articles-articleID___it"
  }, {
    path: "/it/player/sermon/legacy/:sermon?",
    component: _40aa2952,
    name: "player-sermon-legacy-sermon___it"
  }, {
    path: "/it/promotions/stats/ad/:adId?",
    component: _23cca990,
    name: "promotions-stats-ad-adId___it"
  }, {
    path: "/it/promotions/stats/all/:adType?",
    component: _02b08957,
    name: "promotions-stats-all-adType___it"
  }, {
    path: "/it/rewrite/psalter/search/:keyword?",
    component: _07d07bec,
    name: "rewrite-psalter-search-keyword___it"
  }, {
    path: "/km/dashboard/django/articles/:articleID",
    component: _774fca03,
    name: "dashboard-django-articles-articleID___km"
  }, {
    path: "/km/player/sermon/legacy/:sermon?",
    component: _40aa2952,
    name: "player-sermon-legacy-sermon___km"
  }, {
    path: "/km/promotions/stats/ad/:adId?",
    component: _23cca990,
    name: "promotions-stats-ad-adId___km"
  }, {
    path: "/km/promotions/stats/all/:adType?",
    component: _02b08957,
    name: "promotions-stats-all-adType___km"
  }, {
    path: "/km/rewrite/psalter/search/:keyword?",
    component: _07d07bec,
    name: "rewrite-psalter-search-keyword___km"
  }, {
    path: "/ko/dashboard/django/articles/:articleID",
    component: _774fca03,
    name: "dashboard-django-articles-articleID___ko"
  }, {
    path: "/ko/player/sermon/legacy/:sermon?",
    component: _40aa2952,
    name: "player-sermon-legacy-sermon___ko"
  }, {
    path: "/ko/promotions/stats/ad/:adId?",
    component: _23cca990,
    name: "promotions-stats-ad-adId___ko"
  }, {
    path: "/ko/promotions/stats/all/:adType?",
    component: _02b08957,
    name: "promotions-stats-all-adType___ko"
  }, {
    path: "/ko/rewrite/psalter/search/:keyword?",
    component: _07d07bec,
    name: "rewrite-psalter-search-keyword___ko"
  }, {
    path: "/player/sermon/legacy/video/:sermon?",
    component: _716b6a7e,
    name: "player-sermon-legacy-video-sermon___en"
  }, {
    path: "/pt/dashboard/django/articles/:articleID",
    component: _774fca03,
    name: "dashboard-django-articles-articleID___pt"
  }, {
    path: "/pt/player/sermon/legacy/:sermon?",
    component: _40aa2952,
    name: "player-sermon-legacy-sermon___pt"
  }, {
    path: "/pt/promotions/stats/ad/:adId?",
    component: _23cca990,
    name: "promotions-stats-ad-adId___pt"
  }, {
    path: "/pt/promotions/stats/all/:adType?",
    component: _02b08957,
    name: "promotions-stats-all-adType___pt"
  }, {
    path: "/pt/rewrite/psalter/search/:keyword?",
    component: _07d07bec,
    name: "rewrite-psalter-search-keyword___pt"
  }, {
    path: "/zh/dashboard/django/articles/:articleID",
    component: _774fca03,
    name: "dashboard-django-articles-articleID___zh"
  }, {
    path: "/zh/player/sermon/legacy/:sermon?",
    component: _40aa2952,
    name: "player-sermon-legacy-sermon___zh"
  }, {
    path: "/zh/promotions/stats/ad/:adId?",
    component: _23cca990,
    name: "promotions-stats-ad-adId___zh"
  }, {
    path: "/zh/promotions/stats/all/:adType?",
    component: _02b08957,
    name: "promotions-stats-all-adType___zh"
  }, {
    path: "/zh/rewrite/psalter/search/:keyword?",
    component: _07d07bec,
    name: "rewrite-psalter-search-keyword___zh"
  }, {
    path: "/de/embed/classic/player/:mediaType?/:sermonID?",
    component: _e5514dee,
    name: "embed-classic-player-mediaType-sermonID___de"
  }, {
    path: "/es/embed/classic/player/:mediaType?/:sermonID?",
    component: _e5514dee,
    name: "embed-classic-player-mediaType-sermonID___es"
  }, {
    path: "/fr/embed/classic/player/:mediaType?/:sermonID?",
    component: _e5514dee,
    name: "embed-classic-player-mediaType-sermonID___fr"
  }, {
    path: "/gb/embed/classic/player/:mediaType?/:sermonID?",
    component: _e5514dee,
    name: "embed-classic-player-mediaType-sermonID___gb"
  }, {
    path: "/hi/embed/classic/player/:mediaType?/:sermonID?",
    component: _e5514dee,
    name: "embed-classic-player-mediaType-sermonID___hi"
  }, {
    path: "/it/embed/classic/player/:mediaType?/:sermonID?",
    component: _e5514dee,
    name: "embed-classic-player-mediaType-sermonID___it"
  }, {
    path: "/km/embed/classic/player/:mediaType?/:sermonID?",
    component: _e5514dee,
    name: "embed-classic-player-mediaType-sermonID___km"
  }, {
    path: "/ko/embed/classic/player/:mediaType?/:sermonID?",
    component: _e5514dee,
    name: "embed-classic-player-mediaType-sermonID___ko"
  }, {
    path: "/pt/embed/classic/player/:mediaType?/:sermonID?",
    component: _e5514dee,
    name: "embed-classic-player-mediaType-sermonID___pt"
  }, {
    path: "/zh/embed/classic/player/:mediaType?/:sermonID?",
    component: _e5514dee,
    name: "embed-classic-player-mediaType-sermonID___zh"
  }, {
    path: "/de/embed/classic/player/:mediaType?/:category?/:categoryValue?/:filter?/:filterValue?",
    component: _0ff2c3de,
    name: "embed-classic-player-mediaType-category-categoryValue-filter-filterValue___de"
  }, {
    path: "/es/embed/classic/player/:mediaType?/:category?/:categoryValue?/:filter?/:filterValue?",
    component: _0ff2c3de,
    name: "embed-classic-player-mediaType-category-categoryValue-filter-filterValue___es"
  }, {
    path: "/fr/embed/classic/player/:mediaType?/:category?/:categoryValue?/:filter?/:filterValue?",
    component: _0ff2c3de,
    name: "embed-classic-player-mediaType-category-categoryValue-filter-filterValue___fr"
  }, {
    path: "/gb/embed/classic/player/:mediaType?/:category?/:categoryValue?/:filter?/:filterValue?",
    component: _0ff2c3de,
    name: "embed-classic-player-mediaType-category-categoryValue-filter-filterValue___gb"
  }, {
    path: "/hi/embed/classic/player/:mediaType?/:category?/:categoryValue?/:filter?/:filterValue?",
    component: _0ff2c3de,
    name: "embed-classic-player-mediaType-category-categoryValue-filter-filterValue___hi"
  }, {
    path: "/it/embed/classic/player/:mediaType?/:category?/:categoryValue?/:filter?/:filterValue?",
    component: _0ff2c3de,
    name: "embed-classic-player-mediaType-category-categoryValue-filter-filterValue___it"
  }, {
    path: "/km/embed/classic/player/:mediaType?/:category?/:categoryValue?/:filter?/:filterValue?",
    component: _0ff2c3de,
    name: "embed-classic-player-mediaType-category-categoryValue-filter-filterValue___km"
  }, {
    path: "/ko/embed/classic/player/:mediaType?/:category?/:categoryValue?/:filter?/:filterValue?",
    component: _0ff2c3de,
    name: "embed-classic-player-mediaType-category-categoryValue-filter-filterValue___ko"
  }, {
    path: "/pt/embed/classic/player/:mediaType?/:category?/:categoryValue?/:filter?/:filterValue?",
    component: _0ff2c3de,
    name: "embed-classic-player-mediaType-category-categoryValue-filter-filterValue___pt"
  }, {
    path: "/zh/embed/classic/player/:mediaType?/:category?/:categoryValue?/:filter?/:filterValue?",
    component: _0ff2c3de,
    name: "embed-classic-player-mediaType-category-categoryValue-filter-filterValue___zh"
  }, {
    path: "/dashboard/django/articles/:articleID",
    component: _774fca03,
    name: "dashboard-django-articles-articleID___en"
  }, {
    path: "/de/broadcasters/alphabetical/:letter?",
    component: _dea67fa0,
    name: "broadcasters-alphabetical-letter___de"
  }, {
    path: "/de/broadcasters/country/:country?",
    component: _165e621c,
    name: "broadcasters-country-country___de"
  }, {
    path: "/de/broadcasters/group/:group",
    component: _27c29b55,
    name: "broadcasters-group-group___de"
  }, {
    path: "/de/broadcasters/search/:search?",
    component: _5b5cf3bc,
    name: "broadcasters-search-search___de"
  }, {
    path: "/de/broadcasters/state/:state?",
    component: _052bbbdc,
    name: "broadcasters-state-state___de"
  }, {
    path: "/de/dashboard/articles/:articleID",
    component: _1f878521,
    name: "dashboard-articles-articleID___de"
  }, {
    path: "/de/hymnal/search/:keyword?",
    component: _1468bf77,
    name: "hymnal-search-keyword___de"
  }, {
    path: "/de/library/following/:following?",
    component: _24ad13dc,
    name: "library-following-following___de"
  }, {
    path: "/de/promotions/create/:adType?",
    component: _d48a9f80,
    name: "promotions-create-adType___de"
  }, {
    path: "/de/promotions/edit/:adId?",
    component: _0bb4586f,
    name: "promotions-edit-adId___de"
  }, {
    path: "/de/promotions/help/:helpPage?",
    component: _4cc3d4b8,
    name: "promotions-help-helpPage___de"
  }, {
    path: "/de/promotions/orders/:adType?",
    component: _cc4846ae,
    name: "promotions-orders-adType___de"
  }, {
    path: "/de/promotions/stats/:adType?",
    component: _3fc74d45,
    name: "promotions-stats-adType___de"
  }, {
    path: "/de/psalter/search/:keyword?",
    component: _d792bfe6,
    name: "psalter-search-keyword___de"
  }, {
    path: "/de/rewrite/psalter/:psalmID",
    component: _342df156,
    name: "rewrite-psalter-psalmID___de"
  }, {
    path: "/de/series/search/:search?",
    component: _32e83920,
    name: "series-search-search___de"
  }, {
    path: "/de/sermons/categories/:category",
    component: _c2200eac,
    name: "sermons-categories-category___de"
  }, {
    path: "/de/sermons/languages/:language",
    component: _0a3fb2f3,
    name: "sermons-languages-language___de"
  }, {
    path: "/de/sermons/topics/:topic",
    component: _6adeb4ab,
    name: "sermons-topics-topic___de"
  }, {
    path: "/de/speakers/alphabetical/:letter?",
    component: _a7ce187e,
    name: "speakers-alphabetical-letter___de"
  }, {
    path: "/de/speakers/search/:search?",
    component: _9ce80b1a,
    name: "speakers-search-search___de"
  }, {
    path: "/de/video-wall/broadcaster/:broadcasterID?",
    component: _b6304b54,
    name: "video-wall-broadcaster-broadcasterID___de"
  }, {
    path: "/de/video-wall/group/:group?",
    component: _eb99b74a,
    name: "video-wall-group-group___de"
  }, {
    path: "/es/broadcasters/alphabetical/:letter?",
    component: _dea67fa0,
    name: "broadcasters-alphabetical-letter___es"
  }, {
    path: "/es/broadcasters/country/:country?",
    component: _165e621c,
    name: "broadcasters-country-country___es"
  }, {
    path: "/es/broadcasters/group/:group",
    component: _27c29b55,
    name: "broadcasters-group-group___es"
  }, {
    path: "/es/broadcasters/search/:search?",
    component: _5b5cf3bc,
    name: "broadcasters-search-search___es"
  }, {
    path: "/es/broadcasters/state/:state?",
    component: _052bbbdc,
    name: "broadcasters-state-state___es"
  }, {
    path: "/es/dashboard/articles/:articleID",
    component: _1f878521,
    name: "dashboard-articles-articleID___es"
  }, {
    path: "/es/hymnal/search/:keyword?",
    component: _1468bf77,
    name: "hymnal-search-keyword___es"
  }, {
    path: "/es/library/following/:following?",
    component: _24ad13dc,
    name: "library-following-following___es"
  }, {
    path: "/es/promotions/create/:adType?",
    component: _d48a9f80,
    name: "promotions-create-adType___es"
  }, {
    path: "/es/promotions/edit/:adId?",
    component: _0bb4586f,
    name: "promotions-edit-adId___es"
  }, {
    path: "/es/promotions/help/:helpPage?",
    component: _4cc3d4b8,
    name: "promotions-help-helpPage___es"
  }, {
    path: "/es/promotions/orders/:adType?",
    component: _cc4846ae,
    name: "promotions-orders-adType___es"
  }, {
    path: "/es/promotions/stats/:adType?",
    component: _3fc74d45,
    name: "promotions-stats-adType___es"
  }, {
    path: "/es/psalter/search/:keyword?",
    component: _d792bfe6,
    name: "psalter-search-keyword___es"
  }, {
    path: "/es/rewrite/psalter/:psalmID",
    component: _342df156,
    name: "rewrite-psalter-psalmID___es"
  }, {
    path: "/es/series/search/:search?",
    component: _32e83920,
    name: "series-search-search___es"
  }, {
    path: "/es/sermons/categories/:category",
    component: _c2200eac,
    name: "sermons-categories-category___es"
  }, {
    path: "/es/sermons/languages/:language",
    component: _0a3fb2f3,
    name: "sermons-languages-language___es"
  }, {
    path: "/es/sermons/topics/:topic",
    component: _6adeb4ab,
    name: "sermons-topics-topic___es"
  }, {
    path: "/es/speakers/alphabetical/:letter?",
    component: _a7ce187e,
    name: "speakers-alphabetical-letter___es"
  }, {
    path: "/es/speakers/search/:search?",
    component: _9ce80b1a,
    name: "speakers-search-search___es"
  }, {
    path: "/es/video-wall/broadcaster/:broadcasterID?",
    component: _b6304b54,
    name: "video-wall-broadcaster-broadcasterID___es"
  }, {
    path: "/es/video-wall/group/:group?",
    component: _eb99b74a,
    name: "video-wall-group-group___es"
  }, {
    path: "/fr/broadcasters/alphabetical/:letter?",
    component: _dea67fa0,
    name: "broadcasters-alphabetical-letter___fr"
  }, {
    path: "/fr/broadcasters/country/:country?",
    component: _165e621c,
    name: "broadcasters-country-country___fr"
  }, {
    path: "/fr/broadcasters/group/:group",
    component: _27c29b55,
    name: "broadcasters-group-group___fr"
  }, {
    path: "/fr/broadcasters/search/:search?",
    component: _5b5cf3bc,
    name: "broadcasters-search-search___fr"
  }, {
    path: "/fr/broadcasters/state/:state?",
    component: _052bbbdc,
    name: "broadcasters-state-state___fr"
  }, {
    path: "/fr/dashboard/articles/:articleID",
    component: _1f878521,
    name: "dashboard-articles-articleID___fr"
  }, {
    path: "/fr/hymnal/search/:keyword?",
    component: _1468bf77,
    name: "hymnal-search-keyword___fr"
  }, {
    path: "/fr/library/following/:following?",
    component: _24ad13dc,
    name: "library-following-following___fr"
  }, {
    path: "/fr/promotions/create/:adType?",
    component: _d48a9f80,
    name: "promotions-create-adType___fr"
  }, {
    path: "/fr/promotions/edit/:adId?",
    component: _0bb4586f,
    name: "promotions-edit-adId___fr"
  }, {
    path: "/fr/promotions/help/:helpPage?",
    component: _4cc3d4b8,
    name: "promotions-help-helpPage___fr"
  }, {
    path: "/fr/promotions/orders/:adType?",
    component: _cc4846ae,
    name: "promotions-orders-adType___fr"
  }, {
    path: "/fr/promotions/stats/:adType?",
    component: _3fc74d45,
    name: "promotions-stats-adType___fr"
  }, {
    path: "/fr/psalter/search/:keyword?",
    component: _d792bfe6,
    name: "psalter-search-keyword___fr"
  }, {
    path: "/fr/rewrite/psalter/:psalmID",
    component: _342df156,
    name: "rewrite-psalter-psalmID___fr"
  }, {
    path: "/fr/series/search/:search?",
    component: _32e83920,
    name: "series-search-search___fr"
  }, {
    path: "/fr/sermons/categories/:category",
    component: _c2200eac,
    name: "sermons-categories-category___fr"
  }, {
    path: "/fr/sermons/languages/:language",
    component: _0a3fb2f3,
    name: "sermons-languages-language___fr"
  }, {
    path: "/fr/sermons/topics/:topic",
    component: _6adeb4ab,
    name: "sermons-topics-topic___fr"
  }, {
    path: "/fr/speakers/alphabetical/:letter?",
    component: _a7ce187e,
    name: "speakers-alphabetical-letter___fr"
  }, {
    path: "/fr/speakers/search/:search?",
    component: _9ce80b1a,
    name: "speakers-search-search___fr"
  }, {
    path: "/fr/video-wall/broadcaster/:broadcasterID?",
    component: _b6304b54,
    name: "video-wall-broadcaster-broadcasterID___fr"
  }, {
    path: "/fr/video-wall/group/:group?",
    component: _eb99b74a,
    name: "video-wall-group-group___fr"
  }, {
    path: "/gb/broadcasters/alphabetical/:letter?",
    component: _dea67fa0,
    name: "broadcasters-alphabetical-letter___gb"
  }, {
    path: "/gb/broadcasters/country/:country?",
    component: _165e621c,
    name: "broadcasters-country-country___gb"
  }, {
    path: "/gb/broadcasters/group/:group",
    component: _27c29b55,
    name: "broadcasters-group-group___gb"
  }, {
    path: "/gb/broadcasters/search/:search?",
    component: _5b5cf3bc,
    name: "broadcasters-search-search___gb"
  }, {
    path: "/gb/broadcasters/state/:state?",
    component: _052bbbdc,
    name: "broadcasters-state-state___gb"
  }, {
    path: "/gb/dashboard/articles/:articleID",
    component: _1f878521,
    name: "dashboard-articles-articleID___gb"
  }, {
    path: "/gb/hymnal/search/:keyword?",
    component: _1468bf77,
    name: "hymnal-search-keyword___gb"
  }, {
    path: "/gb/library/following/:following?",
    component: _24ad13dc,
    name: "library-following-following___gb"
  }, {
    path: "/gb/promotions/create/:adType?",
    component: _d48a9f80,
    name: "promotions-create-adType___gb"
  }, {
    path: "/gb/promotions/edit/:adId?",
    component: _0bb4586f,
    name: "promotions-edit-adId___gb"
  }, {
    path: "/gb/promotions/help/:helpPage?",
    component: _4cc3d4b8,
    name: "promotions-help-helpPage___gb"
  }, {
    path: "/gb/promotions/orders/:adType?",
    component: _cc4846ae,
    name: "promotions-orders-adType___gb"
  }, {
    path: "/gb/promotions/stats/:adType?",
    component: _3fc74d45,
    name: "promotions-stats-adType___gb"
  }, {
    path: "/gb/psalter/search/:keyword?",
    component: _d792bfe6,
    name: "psalter-search-keyword___gb"
  }, {
    path: "/gb/rewrite/psalter/:psalmID",
    component: _342df156,
    name: "rewrite-psalter-psalmID___gb"
  }, {
    path: "/gb/series/search/:search?",
    component: _32e83920,
    name: "series-search-search___gb"
  }, {
    path: "/gb/sermons/categories/:category",
    component: _c2200eac,
    name: "sermons-categories-category___gb"
  }, {
    path: "/gb/sermons/languages/:language",
    component: _0a3fb2f3,
    name: "sermons-languages-language___gb"
  }, {
    path: "/gb/sermons/topics/:topic",
    component: _6adeb4ab,
    name: "sermons-topics-topic___gb"
  }, {
    path: "/gb/speakers/alphabetical/:letter?",
    component: _a7ce187e,
    name: "speakers-alphabetical-letter___gb"
  }, {
    path: "/gb/speakers/search/:search?",
    component: _9ce80b1a,
    name: "speakers-search-search___gb"
  }, {
    path: "/gb/video-wall/broadcaster/:broadcasterID?",
    component: _b6304b54,
    name: "video-wall-broadcaster-broadcasterID___gb"
  }, {
    path: "/gb/video-wall/group/:group?",
    component: _eb99b74a,
    name: "video-wall-group-group___gb"
  }, {
    path: "/hi/broadcasters/alphabetical/:letter?",
    component: _dea67fa0,
    name: "broadcasters-alphabetical-letter___hi"
  }, {
    path: "/hi/broadcasters/country/:country?",
    component: _165e621c,
    name: "broadcasters-country-country___hi"
  }, {
    path: "/hi/broadcasters/group/:group",
    component: _27c29b55,
    name: "broadcasters-group-group___hi"
  }, {
    path: "/hi/broadcasters/search/:search?",
    component: _5b5cf3bc,
    name: "broadcasters-search-search___hi"
  }, {
    path: "/hi/broadcasters/state/:state?",
    component: _052bbbdc,
    name: "broadcasters-state-state___hi"
  }, {
    path: "/hi/dashboard/articles/:articleID",
    component: _1f878521,
    name: "dashboard-articles-articleID___hi"
  }, {
    path: "/hi/hymnal/search/:keyword?",
    component: _1468bf77,
    name: "hymnal-search-keyword___hi"
  }, {
    path: "/hi/library/following/:following?",
    component: _24ad13dc,
    name: "library-following-following___hi"
  }, {
    path: "/hi/promotions/create/:adType?",
    component: _d48a9f80,
    name: "promotions-create-adType___hi"
  }, {
    path: "/hi/promotions/edit/:adId?",
    component: _0bb4586f,
    name: "promotions-edit-adId___hi"
  }, {
    path: "/hi/promotions/help/:helpPage?",
    component: _4cc3d4b8,
    name: "promotions-help-helpPage___hi"
  }, {
    path: "/hi/promotions/orders/:adType?",
    component: _cc4846ae,
    name: "promotions-orders-adType___hi"
  }, {
    path: "/hi/promotions/stats/:adType?",
    component: _3fc74d45,
    name: "promotions-stats-adType___hi"
  }, {
    path: "/hi/psalter/search/:keyword?",
    component: _d792bfe6,
    name: "psalter-search-keyword___hi"
  }, {
    path: "/hi/rewrite/psalter/:psalmID",
    component: _342df156,
    name: "rewrite-psalter-psalmID___hi"
  }, {
    path: "/hi/series/search/:search?",
    component: _32e83920,
    name: "series-search-search___hi"
  }, {
    path: "/hi/sermons/categories/:category",
    component: _c2200eac,
    name: "sermons-categories-category___hi"
  }, {
    path: "/hi/sermons/languages/:language",
    component: _0a3fb2f3,
    name: "sermons-languages-language___hi"
  }, {
    path: "/hi/sermons/topics/:topic",
    component: _6adeb4ab,
    name: "sermons-topics-topic___hi"
  }, {
    path: "/hi/speakers/alphabetical/:letter?",
    component: _a7ce187e,
    name: "speakers-alphabetical-letter___hi"
  }, {
    path: "/hi/speakers/search/:search?",
    component: _9ce80b1a,
    name: "speakers-search-search___hi"
  }, {
    path: "/hi/video-wall/broadcaster/:broadcasterID?",
    component: _b6304b54,
    name: "video-wall-broadcaster-broadcasterID___hi"
  }, {
    path: "/hi/video-wall/group/:group?",
    component: _eb99b74a,
    name: "video-wall-group-group___hi"
  }, {
    path: "/it/broadcasters/alphabetical/:letter?",
    component: _dea67fa0,
    name: "broadcasters-alphabetical-letter___it"
  }, {
    path: "/it/broadcasters/country/:country?",
    component: _165e621c,
    name: "broadcasters-country-country___it"
  }, {
    path: "/it/broadcasters/group/:group",
    component: _27c29b55,
    name: "broadcasters-group-group___it"
  }, {
    path: "/it/broadcasters/search/:search?",
    component: _5b5cf3bc,
    name: "broadcasters-search-search___it"
  }, {
    path: "/it/broadcasters/state/:state?",
    component: _052bbbdc,
    name: "broadcasters-state-state___it"
  }, {
    path: "/it/dashboard/articles/:articleID",
    component: _1f878521,
    name: "dashboard-articles-articleID___it"
  }, {
    path: "/it/hymnal/search/:keyword?",
    component: _1468bf77,
    name: "hymnal-search-keyword___it"
  }, {
    path: "/it/library/following/:following?",
    component: _24ad13dc,
    name: "library-following-following___it"
  }, {
    path: "/it/promotions/create/:adType?",
    component: _d48a9f80,
    name: "promotions-create-adType___it"
  }, {
    path: "/it/promotions/edit/:adId?",
    component: _0bb4586f,
    name: "promotions-edit-adId___it"
  }, {
    path: "/it/promotions/help/:helpPage?",
    component: _4cc3d4b8,
    name: "promotions-help-helpPage___it"
  }, {
    path: "/it/promotions/orders/:adType?",
    component: _cc4846ae,
    name: "promotions-orders-adType___it"
  }, {
    path: "/it/promotions/stats/:adType?",
    component: _3fc74d45,
    name: "promotions-stats-adType___it"
  }, {
    path: "/it/psalter/search/:keyword?",
    component: _d792bfe6,
    name: "psalter-search-keyword___it"
  }, {
    path: "/it/rewrite/psalter/:psalmID",
    component: _342df156,
    name: "rewrite-psalter-psalmID___it"
  }, {
    path: "/it/series/search/:search?",
    component: _32e83920,
    name: "series-search-search___it"
  }, {
    path: "/it/sermons/categories/:category",
    component: _c2200eac,
    name: "sermons-categories-category___it"
  }, {
    path: "/it/sermons/languages/:language",
    component: _0a3fb2f3,
    name: "sermons-languages-language___it"
  }, {
    path: "/it/sermons/topics/:topic",
    component: _6adeb4ab,
    name: "sermons-topics-topic___it"
  }, {
    path: "/it/speakers/alphabetical/:letter?",
    component: _a7ce187e,
    name: "speakers-alphabetical-letter___it"
  }, {
    path: "/it/speakers/search/:search?",
    component: _9ce80b1a,
    name: "speakers-search-search___it"
  }, {
    path: "/it/video-wall/broadcaster/:broadcasterID?",
    component: _b6304b54,
    name: "video-wall-broadcaster-broadcasterID___it"
  }, {
    path: "/it/video-wall/group/:group?",
    component: _eb99b74a,
    name: "video-wall-group-group___it"
  }, {
    path: "/km/broadcasters/alphabetical/:letter?",
    component: _dea67fa0,
    name: "broadcasters-alphabetical-letter___km"
  }, {
    path: "/km/broadcasters/country/:country?",
    component: _165e621c,
    name: "broadcasters-country-country___km"
  }, {
    path: "/km/broadcasters/group/:group",
    component: _27c29b55,
    name: "broadcasters-group-group___km"
  }, {
    path: "/km/broadcasters/search/:search?",
    component: _5b5cf3bc,
    name: "broadcasters-search-search___km"
  }, {
    path: "/km/broadcasters/state/:state?",
    component: _052bbbdc,
    name: "broadcasters-state-state___km"
  }, {
    path: "/km/dashboard/articles/:articleID",
    component: _1f878521,
    name: "dashboard-articles-articleID___km"
  }, {
    path: "/km/hymnal/search/:keyword?",
    component: _1468bf77,
    name: "hymnal-search-keyword___km"
  }, {
    path: "/km/library/following/:following?",
    component: _24ad13dc,
    name: "library-following-following___km"
  }, {
    path: "/km/promotions/create/:adType?",
    component: _d48a9f80,
    name: "promotions-create-adType___km"
  }, {
    path: "/km/promotions/edit/:adId?",
    component: _0bb4586f,
    name: "promotions-edit-adId___km"
  }, {
    path: "/km/promotions/help/:helpPage?",
    component: _4cc3d4b8,
    name: "promotions-help-helpPage___km"
  }, {
    path: "/km/promotions/orders/:adType?",
    component: _cc4846ae,
    name: "promotions-orders-adType___km"
  }, {
    path: "/km/promotions/stats/:adType?",
    component: _3fc74d45,
    name: "promotions-stats-adType___km"
  }, {
    path: "/km/psalter/search/:keyword?",
    component: _d792bfe6,
    name: "psalter-search-keyword___km"
  }, {
    path: "/km/rewrite/psalter/:psalmID",
    component: _342df156,
    name: "rewrite-psalter-psalmID___km"
  }, {
    path: "/km/series/search/:search?",
    component: _32e83920,
    name: "series-search-search___km"
  }, {
    path: "/km/sermons/categories/:category",
    component: _c2200eac,
    name: "sermons-categories-category___km"
  }, {
    path: "/km/sermons/languages/:language",
    component: _0a3fb2f3,
    name: "sermons-languages-language___km"
  }, {
    path: "/km/sermons/topics/:topic",
    component: _6adeb4ab,
    name: "sermons-topics-topic___km"
  }, {
    path: "/km/speakers/alphabetical/:letter?",
    component: _a7ce187e,
    name: "speakers-alphabetical-letter___km"
  }, {
    path: "/km/speakers/search/:search?",
    component: _9ce80b1a,
    name: "speakers-search-search___km"
  }, {
    path: "/km/video-wall/broadcaster/:broadcasterID?",
    component: _b6304b54,
    name: "video-wall-broadcaster-broadcasterID___km"
  }, {
    path: "/km/video-wall/group/:group?",
    component: _eb99b74a,
    name: "video-wall-group-group___km"
  }, {
    path: "/ko/broadcasters/alphabetical/:letter?",
    component: _dea67fa0,
    name: "broadcasters-alphabetical-letter___ko"
  }, {
    path: "/ko/broadcasters/country/:country?",
    component: _165e621c,
    name: "broadcasters-country-country___ko"
  }, {
    path: "/ko/broadcasters/group/:group",
    component: _27c29b55,
    name: "broadcasters-group-group___ko"
  }, {
    path: "/ko/broadcasters/search/:search?",
    component: _5b5cf3bc,
    name: "broadcasters-search-search___ko"
  }, {
    path: "/ko/broadcasters/state/:state?",
    component: _052bbbdc,
    name: "broadcasters-state-state___ko"
  }, {
    path: "/ko/dashboard/articles/:articleID",
    component: _1f878521,
    name: "dashboard-articles-articleID___ko"
  }, {
    path: "/ko/hymnal/search/:keyword?",
    component: _1468bf77,
    name: "hymnal-search-keyword___ko"
  }, {
    path: "/ko/library/following/:following?",
    component: _24ad13dc,
    name: "library-following-following___ko"
  }, {
    path: "/ko/promotions/create/:adType?",
    component: _d48a9f80,
    name: "promotions-create-adType___ko"
  }, {
    path: "/ko/promotions/edit/:adId?",
    component: _0bb4586f,
    name: "promotions-edit-adId___ko"
  }, {
    path: "/ko/promotions/help/:helpPage?",
    component: _4cc3d4b8,
    name: "promotions-help-helpPage___ko"
  }, {
    path: "/ko/promotions/orders/:adType?",
    component: _cc4846ae,
    name: "promotions-orders-adType___ko"
  }, {
    path: "/ko/promotions/stats/:adType?",
    component: _3fc74d45,
    name: "promotions-stats-adType___ko"
  }, {
    path: "/ko/psalter/search/:keyword?",
    component: _d792bfe6,
    name: "psalter-search-keyword___ko"
  }, {
    path: "/ko/rewrite/psalter/:psalmID",
    component: _342df156,
    name: "rewrite-psalter-psalmID___ko"
  }, {
    path: "/ko/series/search/:search?",
    component: _32e83920,
    name: "series-search-search___ko"
  }, {
    path: "/ko/sermons/categories/:category",
    component: _c2200eac,
    name: "sermons-categories-category___ko"
  }, {
    path: "/ko/sermons/languages/:language",
    component: _0a3fb2f3,
    name: "sermons-languages-language___ko"
  }, {
    path: "/ko/sermons/topics/:topic",
    component: _6adeb4ab,
    name: "sermons-topics-topic___ko"
  }, {
    path: "/ko/speakers/alphabetical/:letter?",
    component: _a7ce187e,
    name: "speakers-alphabetical-letter___ko"
  }, {
    path: "/ko/speakers/search/:search?",
    component: _9ce80b1a,
    name: "speakers-search-search___ko"
  }, {
    path: "/ko/video-wall/broadcaster/:broadcasterID?",
    component: _b6304b54,
    name: "video-wall-broadcaster-broadcasterID___ko"
  }, {
    path: "/ko/video-wall/group/:group?",
    component: _eb99b74a,
    name: "video-wall-group-group___ko"
  }, {
    path: "/player/sermon/legacy/:sermon?",
    component: _40aa2952,
    name: "player-sermon-legacy-sermon___en"
  }, {
    path: "/promotions/stats/ad/:adId?",
    component: _23cca990,
    name: "promotions-stats-ad-adId___en"
  }, {
    path: "/promotions/stats/all/:adType?",
    component: _02b08957,
    name: "promotions-stats-all-adType___en"
  }, {
    path: "/pt/broadcasters/alphabetical/:letter?",
    component: _dea67fa0,
    name: "broadcasters-alphabetical-letter___pt"
  }, {
    path: "/pt/broadcasters/country/:country?",
    component: _165e621c,
    name: "broadcasters-country-country___pt"
  }, {
    path: "/pt/broadcasters/group/:group",
    component: _27c29b55,
    name: "broadcasters-group-group___pt"
  }, {
    path: "/pt/broadcasters/search/:search?",
    component: _5b5cf3bc,
    name: "broadcasters-search-search___pt"
  }, {
    path: "/pt/broadcasters/state/:state?",
    component: _052bbbdc,
    name: "broadcasters-state-state___pt"
  }, {
    path: "/pt/dashboard/articles/:articleID",
    component: _1f878521,
    name: "dashboard-articles-articleID___pt"
  }, {
    path: "/pt/hymnal/search/:keyword?",
    component: _1468bf77,
    name: "hymnal-search-keyword___pt"
  }, {
    path: "/pt/library/following/:following?",
    component: _24ad13dc,
    name: "library-following-following___pt"
  }, {
    path: "/pt/promotions/create/:adType?",
    component: _d48a9f80,
    name: "promotions-create-adType___pt"
  }, {
    path: "/pt/promotions/edit/:adId?",
    component: _0bb4586f,
    name: "promotions-edit-adId___pt"
  }, {
    path: "/pt/promotions/help/:helpPage?",
    component: _4cc3d4b8,
    name: "promotions-help-helpPage___pt"
  }, {
    path: "/pt/promotions/orders/:adType?",
    component: _cc4846ae,
    name: "promotions-orders-adType___pt"
  }, {
    path: "/pt/promotions/stats/:adType?",
    component: _3fc74d45,
    name: "promotions-stats-adType___pt"
  }, {
    path: "/pt/psalter/search/:keyword?",
    component: _d792bfe6,
    name: "psalter-search-keyword___pt"
  }, {
    path: "/pt/rewrite/psalter/:psalmID",
    component: _342df156,
    name: "rewrite-psalter-psalmID___pt"
  }, {
    path: "/pt/series/search/:search?",
    component: _32e83920,
    name: "series-search-search___pt"
  }, {
    path: "/pt/sermons/categories/:category",
    component: _c2200eac,
    name: "sermons-categories-category___pt"
  }, {
    path: "/pt/sermons/languages/:language",
    component: _0a3fb2f3,
    name: "sermons-languages-language___pt"
  }, {
    path: "/pt/sermons/topics/:topic",
    component: _6adeb4ab,
    name: "sermons-topics-topic___pt"
  }, {
    path: "/pt/speakers/alphabetical/:letter?",
    component: _a7ce187e,
    name: "speakers-alphabetical-letter___pt"
  }, {
    path: "/pt/speakers/search/:search?",
    component: _9ce80b1a,
    name: "speakers-search-search___pt"
  }, {
    path: "/pt/video-wall/broadcaster/:broadcasterID?",
    component: _b6304b54,
    name: "video-wall-broadcaster-broadcasterID___pt"
  }, {
    path: "/pt/video-wall/group/:group?",
    component: _eb99b74a,
    name: "video-wall-group-group___pt"
  }, {
    path: "/rewrite/psalter/search/:keyword?",
    component: _07d07bec,
    name: "rewrite-psalter-search-keyword___en"
  }, {
    path: "/zh/broadcasters/alphabetical/:letter?",
    component: _dea67fa0,
    name: "broadcasters-alphabetical-letter___zh"
  }, {
    path: "/zh/broadcasters/country/:country?",
    component: _165e621c,
    name: "broadcasters-country-country___zh"
  }, {
    path: "/zh/broadcasters/group/:group",
    component: _27c29b55,
    name: "broadcasters-group-group___zh"
  }, {
    path: "/zh/broadcasters/search/:search?",
    component: _5b5cf3bc,
    name: "broadcasters-search-search___zh"
  }, {
    path: "/zh/broadcasters/state/:state?",
    component: _052bbbdc,
    name: "broadcasters-state-state___zh"
  }, {
    path: "/zh/dashboard/articles/:articleID",
    component: _1f878521,
    name: "dashboard-articles-articleID___zh"
  }, {
    path: "/zh/hymnal/search/:keyword?",
    component: _1468bf77,
    name: "hymnal-search-keyword___zh"
  }, {
    path: "/zh/library/following/:following?",
    component: _24ad13dc,
    name: "library-following-following___zh"
  }, {
    path: "/zh/promotions/create/:adType?",
    component: _d48a9f80,
    name: "promotions-create-adType___zh"
  }, {
    path: "/zh/promotions/edit/:adId?",
    component: _0bb4586f,
    name: "promotions-edit-adId___zh"
  }, {
    path: "/zh/promotions/help/:helpPage?",
    component: _4cc3d4b8,
    name: "promotions-help-helpPage___zh"
  }, {
    path: "/zh/promotions/orders/:adType?",
    component: _cc4846ae,
    name: "promotions-orders-adType___zh"
  }, {
    path: "/zh/promotions/stats/:adType?",
    component: _3fc74d45,
    name: "promotions-stats-adType___zh"
  }, {
    path: "/zh/psalter/search/:keyword?",
    component: _d792bfe6,
    name: "psalter-search-keyword___zh"
  }, {
    path: "/zh/rewrite/psalter/:psalmID",
    component: _342df156,
    name: "rewrite-psalter-psalmID___zh"
  }, {
    path: "/zh/series/search/:search?",
    component: _32e83920,
    name: "series-search-search___zh"
  }, {
    path: "/zh/sermons/categories/:category",
    component: _c2200eac,
    name: "sermons-categories-category___zh"
  }, {
    path: "/zh/sermons/languages/:language",
    component: _0a3fb2f3,
    name: "sermons-languages-language___zh"
  }, {
    path: "/zh/sermons/topics/:topic",
    component: _6adeb4ab,
    name: "sermons-topics-topic___zh"
  }, {
    path: "/zh/speakers/alphabetical/:letter?",
    component: _a7ce187e,
    name: "speakers-alphabetical-letter___zh"
  }, {
    path: "/zh/speakers/search/:search?",
    component: _9ce80b1a,
    name: "speakers-search-search___zh"
  }, {
    path: "/zh/video-wall/broadcaster/:broadcasterID?",
    component: _b6304b54,
    name: "video-wall-broadcaster-broadcasterID___zh"
  }, {
    path: "/zh/video-wall/group/:group?",
    component: _eb99b74a,
    name: "video-wall-group-group___zh"
  }, {
    path: "/de/rewrite/psalter/:psalmID/fullscreen",
    component: _1b714ec8,
    name: "rewrite-psalter-psalmID-fullscreen___de"
  }, {
    path: "/es/rewrite/psalter/:psalmID/fullscreen",
    component: _1b714ec8,
    name: "rewrite-psalter-psalmID-fullscreen___es"
  }, {
    path: "/fr/rewrite/psalter/:psalmID/fullscreen",
    component: _1b714ec8,
    name: "rewrite-psalter-psalmID-fullscreen___fr"
  }, {
    path: "/gb/rewrite/psalter/:psalmID/fullscreen",
    component: _1b714ec8,
    name: "rewrite-psalter-psalmID-fullscreen___gb"
  }, {
    path: "/hi/rewrite/psalter/:psalmID/fullscreen",
    component: _1b714ec8,
    name: "rewrite-psalter-psalmID-fullscreen___hi"
  }, {
    path: "/it/rewrite/psalter/:psalmID/fullscreen",
    component: _1b714ec8,
    name: "rewrite-psalter-psalmID-fullscreen___it"
  }, {
    path: "/km/rewrite/psalter/:psalmID/fullscreen",
    component: _1b714ec8,
    name: "rewrite-psalter-psalmID-fullscreen___km"
  }, {
    path: "/ko/rewrite/psalter/:psalmID/fullscreen",
    component: _1b714ec8,
    name: "rewrite-psalter-psalmID-fullscreen___ko"
  }, {
    path: "/pt/rewrite/psalter/:psalmID/fullscreen",
    component: _1b714ec8,
    name: "rewrite-psalter-psalmID-fullscreen___pt"
  }, {
    path: "/zh/rewrite/psalter/:psalmID/fullscreen",
    component: _1b714ec8,
    name: "rewrite-psalter-psalmID-fullscreen___zh"
  }, {
    path: "/embed/classic/player/:mediaType?/:sermonID?",
    component: _e5514dee,
    name: "embed-classic-player-mediaType-sermonID___en"
  }, {
    path: "/de/sermons/date/:year/:month?/:day?",
    component: _7f334fd2,
    name: "sermons-date-year-month-day___de"
  }, {
    path: "/de/sermons/scripture/:book/:chapter?/:verse?",
    component: _5c44cfee,
    name: "sermons-scripture-book-chapter-verse___de"
  }, {
    path: "/es/sermons/date/:year/:month?/:day?",
    component: _7f334fd2,
    name: "sermons-date-year-month-day___es"
  }, {
    path: "/es/sermons/scripture/:book/:chapter?/:verse?",
    component: _5c44cfee,
    name: "sermons-scripture-book-chapter-verse___es"
  }, {
    path: "/fr/sermons/date/:year/:month?/:day?",
    component: _7f334fd2,
    name: "sermons-date-year-month-day___fr"
  }, {
    path: "/fr/sermons/scripture/:book/:chapter?/:verse?",
    component: _5c44cfee,
    name: "sermons-scripture-book-chapter-verse___fr"
  }, {
    path: "/gb/sermons/date/:year/:month?/:day?",
    component: _7f334fd2,
    name: "sermons-date-year-month-day___gb"
  }, {
    path: "/gb/sermons/scripture/:book/:chapter?/:verse?",
    component: _5c44cfee,
    name: "sermons-scripture-book-chapter-verse___gb"
  }, {
    path: "/hi/sermons/date/:year/:month?/:day?",
    component: _7f334fd2,
    name: "sermons-date-year-month-day___hi"
  }, {
    path: "/hi/sermons/scripture/:book/:chapter?/:verse?",
    component: _5c44cfee,
    name: "sermons-scripture-book-chapter-verse___hi"
  }, {
    path: "/it/sermons/date/:year/:month?/:day?",
    component: _7f334fd2,
    name: "sermons-date-year-month-day___it"
  }, {
    path: "/it/sermons/scripture/:book/:chapter?/:verse?",
    component: _5c44cfee,
    name: "sermons-scripture-book-chapter-verse___it"
  }, {
    path: "/km/sermons/date/:year/:month?/:day?",
    component: _7f334fd2,
    name: "sermons-date-year-month-day___km"
  }, {
    path: "/km/sermons/scripture/:book/:chapter?/:verse?",
    component: _5c44cfee,
    name: "sermons-scripture-book-chapter-verse___km"
  }, {
    path: "/ko/sermons/date/:year/:month?/:day?",
    component: _7f334fd2,
    name: "sermons-date-year-month-day___ko"
  }, {
    path: "/ko/sermons/scripture/:book/:chapter?/:verse?",
    component: _5c44cfee,
    name: "sermons-scripture-book-chapter-verse___ko"
  }, {
    path: "/pt/sermons/date/:year/:month?/:day?",
    component: _7f334fd2,
    name: "sermons-date-year-month-day___pt"
  }, {
    path: "/pt/sermons/scripture/:book/:chapter?/:verse?",
    component: _5c44cfee,
    name: "sermons-scripture-book-chapter-verse___pt"
  }, {
    path: "/zh/sermons/date/:year/:month?/:day?",
    component: _7f334fd2,
    name: "sermons-date-year-month-day___zh"
  }, {
    path: "/zh/sermons/scripture/:book/:chapter?/:verse?",
    component: _5c44cfee,
    name: "sermons-scripture-book-chapter-verse___zh"
  }, {
    path: "/embed/classic/player/:mediaType?/:category?/:categoryValue?/:filter?/:filterValue?",
    component: _0ff2c3de,
    name: "embed-classic-player-mediaType-category-categoryValue-filter-filterValue___en"
  }, {
    path: "/broadcasters/alphabetical/:letter?",
    component: _dea67fa0,
    name: "broadcasters-alphabetical-letter___en"
  }, {
    path: "/broadcasters/country/:country?",
    component: _165e621c,
    name: "broadcasters-country-country___en"
  }, {
    path: "/broadcasters/group/:group",
    component: _27c29b55,
    name: "broadcasters-group-group___en"
  }, {
    path: "/broadcasters/search/:search?",
    component: _5b5cf3bc,
    name: "broadcasters-search-search___en"
  }, {
    path: "/broadcasters/state/:state?",
    component: _052bbbdc,
    name: "broadcasters-state-state___en"
  }, {
    path: "/dashboard/articles/:articleID",
    component: _1f878521,
    name: "dashboard-articles-articleID___en"
  }, {
    path: "/de/events/:id",
    component: _47ad48ff,
    name: "events-id___de"
  }, {
    path: "/de/feed/:feedType?",
    component: _ecabca44,
    name: "feed-feedType___de"
  }, {
    path: "/de/give/:broadcasterID?",
    component: _9445da00,
    name: "give-broadcasterID___de"
  }, {
    path: "/de/hashtag/:hashtag?",
    component: _8dc459b0,
    name: "hashtag-hashtag___de"
  }, {
    path: "/de/hymnal/:hymnID",
    component: _1a62e016,
    name: "hymnal-hymnID___de"
  }, {
    path: "/de/news/:slug",
    component: _4e624806,
    name: "news-slug___de"
  }, {
    path: "/de/psalter/:psalmID",
    component: _8d76461c,
    name: "psalter-psalmID___de"
  }, {
    path: "/de/release-notes/:slug?",
    component: _1b03f26e,
    name: "release-notes-slug___de"
  }, {
    path: "/de/series/:seriesID",
    component: _e8844a5e,
    name: "series-seriesID___de"
  }, {
    path: "/de/webcasts/:broadcasterID",
    component: _797d7371,
    name: "webcasts-broadcasterID___de"
  }, {
    path: "/es/events/:id",
    component: _47ad48ff,
    name: "events-id___es"
  }, {
    path: "/es/feed/:feedType?",
    component: _ecabca44,
    name: "feed-feedType___es"
  }, {
    path: "/es/give/:broadcasterID?",
    component: _9445da00,
    name: "give-broadcasterID___es"
  }, {
    path: "/es/hashtag/:hashtag?",
    component: _8dc459b0,
    name: "hashtag-hashtag___es"
  }, {
    path: "/es/hymnal/:hymnID",
    component: _1a62e016,
    name: "hymnal-hymnID___es"
  }, {
    path: "/es/news/:slug",
    component: _4e624806,
    name: "news-slug___es"
  }, {
    path: "/es/psalter/:psalmID",
    component: _8d76461c,
    name: "psalter-psalmID___es"
  }, {
    path: "/es/release-notes/:slug?",
    component: _1b03f26e,
    name: "release-notes-slug___es"
  }, {
    path: "/es/series/:seriesID",
    component: _e8844a5e,
    name: "series-seriesID___es"
  }, {
    path: "/es/webcasts/:broadcasterID",
    component: _797d7371,
    name: "webcasts-broadcasterID___es"
  }, {
    path: "/fr/events/:id",
    component: _47ad48ff,
    name: "events-id___fr"
  }, {
    path: "/fr/feed/:feedType?",
    component: _ecabca44,
    name: "feed-feedType___fr"
  }, {
    path: "/fr/give/:broadcasterID?",
    component: _9445da00,
    name: "give-broadcasterID___fr"
  }, {
    path: "/fr/hashtag/:hashtag?",
    component: _8dc459b0,
    name: "hashtag-hashtag___fr"
  }, {
    path: "/fr/hymnal/:hymnID",
    component: _1a62e016,
    name: "hymnal-hymnID___fr"
  }, {
    path: "/fr/news/:slug",
    component: _4e624806,
    name: "news-slug___fr"
  }, {
    path: "/fr/psalter/:psalmID",
    component: _8d76461c,
    name: "psalter-psalmID___fr"
  }, {
    path: "/fr/release-notes/:slug?",
    component: _1b03f26e,
    name: "release-notes-slug___fr"
  }, {
    path: "/fr/series/:seriesID",
    component: _e8844a5e,
    name: "series-seriesID___fr"
  }, {
    path: "/fr/webcasts/:broadcasterID",
    component: _797d7371,
    name: "webcasts-broadcasterID___fr"
  }, {
    path: "/gb/events/:id",
    component: _47ad48ff,
    name: "events-id___gb"
  }, {
    path: "/gb/feed/:feedType?",
    component: _ecabca44,
    name: "feed-feedType___gb"
  }, {
    path: "/gb/give/:broadcasterID?",
    component: _9445da00,
    name: "give-broadcasterID___gb"
  }, {
    path: "/gb/hashtag/:hashtag?",
    component: _8dc459b0,
    name: "hashtag-hashtag___gb"
  }, {
    path: "/gb/hymnal/:hymnID",
    component: _1a62e016,
    name: "hymnal-hymnID___gb"
  }, {
    path: "/gb/news/:slug",
    component: _4e624806,
    name: "news-slug___gb"
  }, {
    path: "/gb/psalter/:psalmID",
    component: _8d76461c,
    name: "psalter-psalmID___gb"
  }, {
    path: "/gb/release-notes/:slug?",
    component: _1b03f26e,
    name: "release-notes-slug___gb"
  }, {
    path: "/gb/series/:seriesID",
    component: _e8844a5e,
    name: "series-seriesID___gb"
  }, {
    path: "/gb/webcasts/:broadcasterID",
    component: _797d7371,
    name: "webcasts-broadcasterID___gb"
  }, {
    path: "/hi/events/:id",
    component: _47ad48ff,
    name: "events-id___hi"
  }, {
    path: "/hi/feed/:feedType?",
    component: _ecabca44,
    name: "feed-feedType___hi"
  }, {
    path: "/hi/give/:broadcasterID?",
    component: _9445da00,
    name: "give-broadcasterID___hi"
  }, {
    path: "/hi/hashtag/:hashtag?",
    component: _8dc459b0,
    name: "hashtag-hashtag___hi"
  }, {
    path: "/hi/hymnal/:hymnID",
    component: _1a62e016,
    name: "hymnal-hymnID___hi"
  }, {
    path: "/hi/news/:slug",
    component: _4e624806,
    name: "news-slug___hi"
  }, {
    path: "/hi/psalter/:psalmID",
    component: _8d76461c,
    name: "psalter-psalmID___hi"
  }, {
    path: "/hi/release-notes/:slug?",
    component: _1b03f26e,
    name: "release-notes-slug___hi"
  }, {
    path: "/hi/series/:seriesID",
    component: _e8844a5e,
    name: "series-seriesID___hi"
  }, {
    path: "/hi/webcasts/:broadcasterID",
    component: _797d7371,
    name: "webcasts-broadcasterID___hi"
  }, {
    path: "/hymnal/search/:keyword?",
    component: _1468bf77,
    name: "hymnal-search-keyword___en"
  }, {
    path: "/it/events/:id",
    component: _47ad48ff,
    name: "events-id___it"
  }, {
    path: "/it/feed/:feedType?",
    component: _ecabca44,
    name: "feed-feedType___it"
  }, {
    path: "/it/give/:broadcasterID?",
    component: _9445da00,
    name: "give-broadcasterID___it"
  }, {
    path: "/it/hashtag/:hashtag?",
    component: _8dc459b0,
    name: "hashtag-hashtag___it"
  }, {
    path: "/it/hymnal/:hymnID",
    component: _1a62e016,
    name: "hymnal-hymnID___it"
  }, {
    path: "/it/news/:slug",
    component: _4e624806,
    name: "news-slug___it"
  }, {
    path: "/it/psalter/:psalmID",
    component: _8d76461c,
    name: "psalter-psalmID___it"
  }, {
    path: "/it/release-notes/:slug?",
    component: _1b03f26e,
    name: "release-notes-slug___it"
  }, {
    path: "/it/series/:seriesID",
    component: _e8844a5e,
    name: "series-seriesID___it"
  }, {
    path: "/it/webcasts/:broadcasterID",
    component: _797d7371,
    name: "webcasts-broadcasterID___it"
  }, {
    path: "/km/events/:id",
    component: _47ad48ff,
    name: "events-id___km"
  }, {
    path: "/km/feed/:feedType?",
    component: _ecabca44,
    name: "feed-feedType___km"
  }, {
    path: "/km/give/:broadcasterID?",
    component: _9445da00,
    name: "give-broadcasterID___km"
  }, {
    path: "/km/hashtag/:hashtag?",
    component: _8dc459b0,
    name: "hashtag-hashtag___km"
  }, {
    path: "/km/hymnal/:hymnID",
    component: _1a62e016,
    name: "hymnal-hymnID___km"
  }, {
    path: "/km/news/:slug",
    component: _4e624806,
    name: "news-slug___km"
  }, {
    path: "/km/psalter/:psalmID",
    component: _8d76461c,
    name: "psalter-psalmID___km"
  }, {
    path: "/km/release-notes/:slug?",
    component: _1b03f26e,
    name: "release-notes-slug___km"
  }, {
    path: "/km/series/:seriesID",
    component: _e8844a5e,
    name: "series-seriesID___km"
  }, {
    path: "/km/webcasts/:broadcasterID",
    component: _797d7371,
    name: "webcasts-broadcasterID___km"
  }, {
    path: "/ko/events/:id",
    component: _47ad48ff,
    name: "events-id___ko"
  }, {
    path: "/ko/feed/:feedType?",
    component: _ecabca44,
    name: "feed-feedType___ko"
  }, {
    path: "/ko/give/:broadcasterID?",
    component: _9445da00,
    name: "give-broadcasterID___ko"
  }, {
    path: "/ko/hashtag/:hashtag?",
    component: _8dc459b0,
    name: "hashtag-hashtag___ko"
  }, {
    path: "/ko/hymnal/:hymnID",
    component: _1a62e016,
    name: "hymnal-hymnID___ko"
  }, {
    path: "/ko/news/:slug",
    component: _4e624806,
    name: "news-slug___ko"
  }, {
    path: "/ko/psalter/:psalmID",
    component: _8d76461c,
    name: "psalter-psalmID___ko"
  }, {
    path: "/ko/release-notes/:slug?",
    component: _1b03f26e,
    name: "release-notes-slug___ko"
  }, {
    path: "/ko/series/:seriesID",
    component: _e8844a5e,
    name: "series-seriesID___ko"
  }, {
    path: "/ko/webcasts/:broadcasterID",
    component: _797d7371,
    name: "webcasts-broadcasterID___ko"
  }, {
    path: "/library/following/:following?",
    component: _24ad13dc,
    name: "library-following-following___en"
  }, {
    path: "/promotions/create/:adType?",
    component: _d48a9f80,
    name: "promotions-create-adType___en"
  }, {
    path: "/promotions/edit/:adId?",
    component: _0bb4586f,
    name: "promotions-edit-adId___en"
  }, {
    path: "/promotions/help/:helpPage?",
    component: _4cc3d4b8,
    name: "promotions-help-helpPage___en"
  }, {
    path: "/promotions/orders/:adType?",
    component: _cc4846ae,
    name: "promotions-orders-adType___en"
  }, {
    path: "/promotions/stats/:adType?",
    component: _3fc74d45,
    name: "promotions-stats-adType___en"
  }, {
    path: "/psalter/search/:keyword?",
    component: _d792bfe6,
    name: "psalter-search-keyword___en"
  }, {
    path: "/pt/events/:id",
    component: _47ad48ff,
    name: "events-id___pt"
  }, {
    path: "/pt/feed/:feedType?",
    component: _ecabca44,
    name: "feed-feedType___pt"
  }, {
    path: "/pt/give/:broadcasterID?",
    component: _9445da00,
    name: "give-broadcasterID___pt"
  }, {
    path: "/pt/hashtag/:hashtag?",
    component: _8dc459b0,
    name: "hashtag-hashtag___pt"
  }, {
    path: "/pt/hymnal/:hymnID",
    component: _1a62e016,
    name: "hymnal-hymnID___pt"
  }, {
    path: "/pt/news/:slug",
    component: _4e624806,
    name: "news-slug___pt"
  }, {
    path: "/pt/psalter/:psalmID",
    component: _8d76461c,
    name: "psalter-psalmID___pt"
  }, {
    path: "/pt/release-notes/:slug?",
    component: _1b03f26e,
    name: "release-notes-slug___pt"
  }, {
    path: "/pt/series/:seriesID",
    component: _e8844a5e,
    name: "series-seriesID___pt"
  }, {
    path: "/pt/webcasts/:broadcasterID",
    component: _797d7371,
    name: "webcasts-broadcasterID___pt"
  }, {
    path: "/rewrite/psalter/:psalmID",
    component: _342df156,
    name: "rewrite-psalter-psalmID___en"
  }, {
    path: "/series/search/:search?",
    component: _32e83920,
    name: "series-search-search___en"
  }, {
    path: "/sermons/categories/:category",
    component: _c2200eac,
    name: "sermons-categories-category___en"
  }, {
    path: "/sermons/languages/:language",
    component: _0a3fb2f3,
    name: "sermons-languages-language___en"
  }, {
    path: "/sermons/topics/:topic",
    component: _6adeb4ab,
    name: "sermons-topics-topic___en"
  }, {
    path: "/speakers/alphabetical/:letter?",
    component: _a7ce187e,
    name: "speakers-alphabetical-letter___en"
  }, {
    path: "/speakers/search/:search?",
    component: _9ce80b1a,
    name: "speakers-search-search___en"
  }, {
    path: "/video-wall/broadcaster/:broadcasterID?",
    component: _b6304b54,
    name: "video-wall-broadcaster-broadcasterID___en"
  }, {
    path: "/video-wall/group/:group?",
    component: _eb99b74a,
    name: "video-wall-group-group___en"
  }, {
    path: "/zh/events/:id",
    component: _47ad48ff,
    name: "events-id___zh"
  }, {
    path: "/zh/feed/:feedType?",
    component: _ecabca44,
    name: "feed-feedType___zh"
  }, {
    path: "/zh/give/:broadcasterID?",
    component: _9445da00,
    name: "give-broadcasterID___zh"
  }, {
    path: "/zh/hashtag/:hashtag?",
    component: _8dc459b0,
    name: "hashtag-hashtag___zh"
  }, {
    path: "/zh/hymnal/:hymnID",
    component: _1a62e016,
    name: "hymnal-hymnID___zh"
  }, {
    path: "/zh/news/:slug",
    component: _4e624806,
    name: "news-slug___zh"
  }, {
    path: "/zh/psalter/:psalmID",
    component: _8d76461c,
    name: "psalter-psalmID___zh"
  }, {
    path: "/zh/release-notes/:slug?",
    component: _1b03f26e,
    name: "release-notes-slug___zh"
  }, {
    path: "/zh/series/:seriesID",
    component: _e8844a5e,
    name: "series-seriesID___zh"
  }, {
    path: "/zh/webcasts/:broadcasterID",
    component: _797d7371,
    name: "webcasts-broadcasterID___zh"
  }, {
    path: "/de/broadcasters/:broadcasterID/disabled",
    component: _859f9d62,
    name: "broadcasters-broadcasterID-disabled___de"
  }, {
    path: "/de/events/:id?/registration-confirmation",
    component: _c338a614,
    name: "events-id-registration-confirmation___de"
  }, {
    path: "/de/hymnal/:hymnID/fullscreen",
    component: _79c82a28,
    name: "hymnal-hymnID-fullscreen___de"
  }, {
    path: "/de/psalter/:psalmID/fullscreen",
    component: _a5c9196a,
    name: "psalter-psalmID-fullscreen___de"
  }, {
    path: "/de/sermons/:sermonID/globalize",
    component: _6606a6d8,
    name: "sermons-sermonID-globalize___de"
  }, {
    path: "/es/broadcasters/:broadcasterID/disabled",
    component: _859f9d62,
    name: "broadcasters-broadcasterID-disabled___es"
  }, {
    path: "/es/events/:id?/registration-confirmation",
    component: _c338a614,
    name: "events-id-registration-confirmation___es"
  }, {
    path: "/es/hymnal/:hymnID/fullscreen",
    component: _79c82a28,
    name: "hymnal-hymnID-fullscreen___es"
  }, {
    path: "/es/psalter/:psalmID/fullscreen",
    component: _a5c9196a,
    name: "psalter-psalmID-fullscreen___es"
  }, {
    path: "/es/sermons/:sermonID/globalize",
    component: _6606a6d8,
    name: "sermons-sermonID-globalize___es"
  }, {
    path: "/fr/broadcasters/:broadcasterID/disabled",
    component: _859f9d62,
    name: "broadcasters-broadcasterID-disabled___fr"
  }, {
    path: "/fr/events/:id?/registration-confirmation",
    component: _c338a614,
    name: "events-id-registration-confirmation___fr"
  }, {
    path: "/fr/hymnal/:hymnID/fullscreen",
    component: _79c82a28,
    name: "hymnal-hymnID-fullscreen___fr"
  }, {
    path: "/fr/psalter/:psalmID/fullscreen",
    component: _a5c9196a,
    name: "psalter-psalmID-fullscreen___fr"
  }, {
    path: "/fr/sermons/:sermonID/globalize",
    component: _6606a6d8,
    name: "sermons-sermonID-globalize___fr"
  }, {
    path: "/gb/broadcasters/:broadcasterID/disabled",
    component: _859f9d62,
    name: "broadcasters-broadcasterID-disabled___gb"
  }, {
    path: "/gb/events/:id?/registration-confirmation",
    component: _c338a614,
    name: "events-id-registration-confirmation___gb"
  }, {
    path: "/gb/hymnal/:hymnID/fullscreen",
    component: _79c82a28,
    name: "hymnal-hymnID-fullscreen___gb"
  }, {
    path: "/gb/psalter/:psalmID/fullscreen",
    component: _a5c9196a,
    name: "psalter-psalmID-fullscreen___gb"
  }, {
    path: "/gb/sermons/:sermonID/globalize",
    component: _6606a6d8,
    name: "sermons-sermonID-globalize___gb"
  }, {
    path: "/hi/broadcasters/:broadcasterID/disabled",
    component: _859f9d62,
    name: "broadcasters-broadcasterID-disabled___hi"
  }, {
    path: "/hi/events/:id?/registration-confirmation",
    component: _c338a614,
    name: "events-id-registration-confirmation___hi"
  }, {
    path: "/hi/hymnal/:hymnID/fullscreen",
    component: _79c82a28,
    name: "hymnal-hymnID-fullscreen___hi"
  }, {
    path: "/hi/psalter/:psalmID/fullscreen",
    component: _a5c9196a,
    name: "psalter-psalmID-fullscreen___hi"
  }, {
    path: "/hi/sermons/:sermonID/globalize",
    component: _6606a6d8,
    name: "sermons-sermonID-globalize___hi"
  }, {
    path: "/it/broadcasters/:broadcasterID/disabled",
    component: _859f9d62,
    name: "broadcasters-broadcasterID-disabled___it"
  }, {
    path: "/it/events/:id?/registration-confirmation",
    component: _c338a614,
    name: "events-id-registration-confirmation___it"
  }, {
    path: "/it/hymnal/:hymnID/fullscreen",
    component: _79c82a28,
    name: "hymnal-hymnID-fullscreen___it"
  }, {
    path: "/it/psalter/:psalmID/fullscreen",
    component: _a5c9196a,
    name: "psalter-psalmID-fullscreen___it"
  }, {
    path: "/it/sermons/:sermonID/globalize",
    component: _6606a6d8,
    name: "sermons-sermonID-globalize___it"
  }, {
    path: "/km/broadcasters/:broadcasterID/disabled",
    component: _859f9d62,
    name: "broadcasters-broadcasterID-disabled___km"
  }, {
    path: "/km/events/:id?/registration-confirmation",
    component: _c338a614,
    name: "events-id-registration-confirmation___km"
  }, {
    path: "/km/hymnal/:hymnID/fullscreen",
    component: _79c82a28,
    name: "hymnal-hymnID-fullscreen___km"
  }, {
    path: "/km/psalter/:psalmID/fullscreen",
    component: _a5c9196a,
    name: "psalter-psalmID-fullscreen___km"
  }, {
    path: "/km/sermons/:sermonID/globalize",
    component: _6606a6d8,
    name: "sermons-sermonID-globalize___km"
  }, {
    path: "/ko/broadcasters/:broadcasterID/disabled",
    component: _859f9d62,
    name: "broadcasters-broadcasterID-disabled___ko"
  }, {
    path: "/ko/events/:id?/registration-confirmation",
    component: _c338a614,
    name: "events-id-registration-confirmation___ko"
  }, {
    path: "/ko/hymnal/:hymnID/fullscreen",
    component: _79c82a28,
    name: "hymnal-hymnID-fullscreen___ko"
  }, {
    path: "/ko/psalter/:psalmID/fullscreen",
    component: _a5c9196a,
    name: "psalter-psalmID-fullscreen___ko"
  }, {
    path: "/ko/sermons/:sermonID/globalize",
    component: _6606a6d8,
    name: "sermons-sermonID-globalize___ko"
  }, {
    path: "/pt/broadcasters/:broadcasterID/disabled",
    component: _859f9d62,
    name: "broadcasters-broadcasterID-disabled___pt"
  }, {
    path: "/pt/events/:id?/registration-confirmation",
    component: _c338a614,
    name: "events-id-registration-confirmation___pt"
  }, {
    path: "/pt/hymnal/:hymnID/fullscreen",
    component: _79c82a28,
    name: "hymnal-hymnID-fullscreen___pt"
  }, {
    path: "/pt/psalter/:psalmID/fullscreen",
    component: _a5c9196a,
    name: "psalter-psalmID-fullscreen___pt"
  }, {
    path: "/pt/sermons/:sermonID/globalize",
    component: _6606a6d8,
    name: "sermons-sermonID-globalize___pt"
  }, {
    path: "/rewrite/psalter/:psalmID/fullscreen",
    component: _1b714ec8,
    name: "rewrite-psalter-psalmID-fullscreen___en"
  }, {
    path: "/zh/broadcasters/:broadcasterID/disabled",
    component: _859f9d62,
    name: "broadcasters-broadcasterID-disabled___zh"
  }, {
    path: "/zh/events/:id?/registration-confirmation",
    component: _c338a614,
    name: "events-id-registration-confirmation___zh"
  }, {
    path: "/zh/hymnal/:hymnID/fullscreen",
    component: _79c82a28,
    name: "hymnal-hymnID-fullscreen___zh"
  }, {
    path: "/zh/psalter/:psalmID/fullscreen",
    component: _a5c9196a,
    name: "psalter-psalmID-fullscreen___zh"
  }, {
    path: "/zh/sermons/:sermonID/globalize",
    component: _6606a6d8,
    name: "sermons-sermonID-globalize___zh"
  }, {
    path: "/de/article/:broadcasterID?/:slug",
    component: _7a283a39,
    name: "article-broadcasterID-slug___de"
  }, {
    path: "/de/broadcasters/:broadcasterID/:tab?",
    component: _ce4d03ae,
    name: "broadcasters-broadcasterID-tab___de"
  }, {
    path: "/de/sermons/:sermonID/:mediaType?",
    component: _94394270,
    name: "sermons-sermonID-mediaType___de"
  }, {
    path: "/de/speakers/:speaker/:tab?",
    component: _49aa6302,
    name: "speakers-speaker-tab___de"
  }, {
    path: "/es/article/:broadcasterID?/:slug",
    component: _7a283a39,
    name: "article-broadcasterID-slug___es"
  }, {
    path: "/es/broadcasters/:broadcasterID/:tab?",
    component: _ce4d03ae,
    name: "broadcasters-broadcasterID-tab___es"
  }, {
    path: "/es/sermons/:sermonID/:mediaType?",
    component: _94394270,
    name: "sermons-sermonID-mediaType___es"
  }, {
    path: "/es/speakers/:speaker/:tab?",
    component: _49aa6302,
    name: "speakers-speaker-tab___es"
  }, {
    path: "/fr/article/:broadcasterID?/:slug",
    component: _7a283a39,
    name: "article-broadcasterID-slug___fr"
  }, {
    path: "/fr/broadcasters/:broadcasterID/:tab?",
    component: _ce4d03ae,
    name: "broadcasters-broadcasterID-tab___fr"
  }, {
    path: "/fr/sermons/:sermonID/:mediaType?",
    component: _94394270,
    name: "sermons-sermonID-mediaType___fr"
  }, {
    path: "/fr/speakers/:speaker/:tab?",
    component: _49aa6302,
    name: "speakers-speaker-tab___fr"
  }, {
    path: "/gb/article/:broadcasterID?/:slug",
    component: _7a283a39,
    name: "article-broadcasterID-slug___gb"
  }, {
    path: "/gb/broadcasters/:broadcasterID/:tab?",
    component: _ce4d03ae,
    name: "broadcasters-broadcasterID-tab___gb"
  }, {
    path: "/gb/sermons/:sermonID/:mediaType?",
    component: _94394270,
    name: "sermons-sermonID-mediaType___gb"
  }, {
    path: "/gb/speakers/:speaker/:tab?",
    component: _49aa6302,
    name: "speakers-speaker-tab___gb"
  }, {
    path: "/hi/article/:broadcasterID?/:slug",
    component: _7a283a39,
    name: "article-broadcasterID-slug___hi"
  }, {
    path: "/hi/broadcasters/:broadcasterID/:tab?",
    component: _ce4d03ae,
    name: "broadcasters-broadcasterID-tab___hi"
  }, {
    path: "/hi/sermons/:sermonID/:mediaType?",
    component: _94394270,
    name: "sermons-sermonID-mediaType___hi"
  }, {
    path: "/hi/speakers/:speaker/:tab?",
    component: _49aa6302,
    name: "speakers-speaker-tab___hi"
  }, {
    path: "/it/article/:broadcasterID?/:slug",
    component: _7a283a39,
    name: "article-broadcasterID-slug___it"
  }, {
    path: "/it/broadcasters/:broadcasterID/:tab?",
    component: _ce4d03ae,
    name: "broadcasters-broadcasterID-tab___it"
  }, {
    path: "/it/sermons/:sermonID/:mediaType?",
    component: _94394270,
    name: "sermons-sermonID-mediaType___it"
  }, {
    path: "/it/speakers/:speaker/:tab?",
    component: _49aa6302,
    name: "speakers-speaker-tab___it"
  }, {
    path: "/km/article/:broadcasterID?/:slug",
    component: _7a283a39,
    name: "article-broadcasterID-slug___km"
  }, {
    path: "/km/broadcasters/:broadcasterID/:tab?",
    component: _ce4d03ae,
    name: "broadcasters-broadcasterID-tab___km"
  }, {
    path: "/km/sermons/:sermonID/:mediaType?",
    component: _94394270,
    name: "sermons-sermonID-mediaType___km"
  }, {
    path: "/km/speakers/:speaker/:tab?",
    component: _49aa6302,
    name: "speakers-speaker-tab___km"
  }, {
    path: "/ko/article/:broadcasterID?/:slug",
    component: _7a283a39,
    name: "article-broadcasterID-slug___ko"
  }, {
    path: "/ko/broadcasters/:broadcasterID/:tab?",
    component: _ce4d03ae,
    name: "broadcasters-broadcasterID-tab___ko"
  }, {
    path: "/ko/sermons/:sermonID/:mediaType?",
    component: _94394270,
    name: "sermons-sermonID-mediaType___ko"
  }, {
    path: "/ko/speakers/:speaker/:tab?",
    component: _49aa6302,
    name: "speakers-speaker-tab___ko"
  }, {
    path: "/pt/article/:broadcasterID?/:slug",
    component: _7a283a39,
    name: "article-broadcasterID-slug___pt"
  }, {
    path: "/pt/broadcasters/:broadcasterID/:tab?",
    component: _ce4d03ae,
    name: "broadcasters-broadcasterID-tab___pt"
  }, {
    path: "/pt/sermons/:sermonID/:mediaType?",
    component: _94394270,
    name: "sermons-sermonID-mediaType___pt"
  }, {
    path: "/pt/speakers/:speaker/:tab?",
    component: _49aa6302,
    name: "speakers-speaker-tab___pt"
  }, {
    path: "/zh/article/:broadcasterID?/:slug",
    component: _7a283a39,
    name: "article-broadcasterID-slug___zh"
  }, {
    path: "/zh/broadcasters/:broadcasterID/:tab?",
    component: _ce4d03ae,
    name: "broadcasters-broadcasterID-tab___zh"
  }, {
    path: "/zh/sermons/:sermonID/:mediaType?",
    component: _94394270,
    name: "sermons-sermonID-mediaType___zh"
  }, {
    path: "/zh/speakers/:speaker/:tab?",
    component: _49aa6302,
    name: "speakers-speaker-tab___zh"
  }, {
    path: "/de/article/:broadcasterID?/:slug?/print",
    component: _3f2e5e94,
    name: "article-broadcasterID-slug-print___de"
  }, {
    path: "/es/article/:broadcasterID?/:slug?/print",
    component: _3f2e5e94,
    name: "article-broadcasterID-slug-print___es"
  }, {
    path: "/fr/article/:broadcasterID?/:slug?/print",
    component: _3f2e5e94,
    name: "article-broadcasterID-slug-print___fr"
  }, {
    path: "/gb/article/:broadcasterID?/:slug?/print",
    component: _3f2e5e94,
    name: "article-broadcasterID-slug-print___gb"
  }, {
    path: "/hi/article/:broadcasterID?/:slug?/print",
    component: _3f2e5e94,
    name: "article-broadcasterID-slug-print___hi"
  }, {
    path: "/it/article/:broadcasterID?/:slug?/print",
    component: _3f2e5e94,
    name: "article-broadcasterID-slug-print___it"
  }, {
    path: "/km/article/:broadcasterID?/:slug?/print",
    component: _3f2e5e94,
    name: "article-broadcasterID-slug-print___km"
  }, {
    path: "/ko/article/:broadcasterID?/:slug?/print",
    component: _3f2e5e94,
    name: "article-broadcasterID-slug-print___ko"
  }, {
    path: "/pt/article/:broadcasterID?/:slug?/print",
    component: _3f2e5e94,
    name: "article-broadcasterID-slug-print___pt"
  }, {
    path: "/zh/article/:broadcasterID?/:slug?/print",
    component: _3f2e5e94,
    name: "article-broadcasterID-slug-print___zh"
  }, {
    path: "/sermons/date/:year/:month?/:day?",
    component: _7f334fd2,
    name: "sermons-date-year-month-day___en"
  }, {
    path: "/sermons/scripture/:book/:chapter?/:verse?",
    component: _5c44cfee,
    name: "sermons-scripture-book-chapter-verse___en"
  }, {
    path: "/events/:id",
    component: _47ad48ff,
    name: "events-id___en"
  }, {
    path: "/feed/:feedType?",
    component: _ecabca44,
    name: "feed-feedType___en"
  }, {
    path: "/give/:broadcasterID?",
    component: _9445da00,
    name: "give-broadcasterID___en"
  }, {
    path: "/hashtag/:hashtag?",
    component: _8dc459b0,
    name: "hashtag-hashtag___en"
  }, {
    path: "/hymnal/:hymnID",
    component: _1a62e016,
    name: "hymnal-hymnID___en"
  }, {
    path: "/news/:slug",
    component: _4e624806,
    name: "news-slug___en"
  }, {
    path: "/psalter/:psalmID",
    component: _8d76461c,
    name: "psalter-psalmID___en"
  }, {
    path: "/release-notes/:slug?",
    component: _1b03f26e,
    name: "release-notes-slug___en"
  }, {
    path: "/series/:seriesID",
    component: _e8844a5e,
    name: "series-seriesID___en"
  }, {
    path: "/webcasts/:broadcasterID",
    component: _797d7371,
    name: "webcasts-broadcasterID___en"
  }, {
    path: "/broadcasters/:broadcasterID/disabled",
    component: _859f9d62,
    name: "broadcasters-broadcasterID-disabled___en"
  }, {
    path: "/events/:id?/registration-confirmation",
    component: _c338a614,
    name: "events-id-registration-confirmation___en"
  }, {
    path: "/hymnal/:hymnID/fullscreen",
    component: _79c82a28,
    name: "hymnal-hymnID-fullscreen___en"
  }, {
    path: "/psalter/:psalmID/fullscreen",
    component: _a5c9196a,
    name: "psalter-psalmID-fullscreen___en"
  }, {
    path: "/sermons/:sermonID/globalize",
    component: _6606a6d8,
    name: "sermons-sermonID-globalize___en"
  }, {
    path: "/article/:broadcasterID?/:slug",
    component: _7a283a39,
    name: "article-broadcasterID-slug___en"
  }, {
    path: "/broadcasters/:broadcasterID/:tab?",
    component: _ce4d03ae,
    name: "broadcasters-broadcasterID-tab___en"
  }, {
    path: "/sermons/:sermonID/:mediaType?",
    component: _94394270,
    name: "sermons-sermonID-mediaType___en"
  }, {
    path: "/speakers/:speaker/:tab?",
    component: _49aa6302,
    name: "speakers-speaker-tab___en"
  }, {
    path: "/article/:broadcasterID?/:slug?/print",
    component: _3f2e5e94,
    name: "article-broadcasterID-slug-print___en"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
