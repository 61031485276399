import { ApiClient } from '~/apiclient/apiclient'

interface MapPath {
  url: string
  status: 301 | 302
}

// This will likely change to sermonaudio.com or www.sermonaudio.com some day
const newSiteHost = 'https://web.sermonaudio.com'

export async function mapPath(
  this: ApiClient,
  url: string
): Promise<MapPath | undefined> {
  try {
    const { data } = await this.$axios.post('map-path', {
      url,
    })
    const newUrl = data as string
    return {
      // turn this into a relative url so that it works in all subdomains
      url: newUrl.replace(newSiteHost, ''),
      // internal urls get a 301, everything else gets a 302
      status: newUrl.includes(newSiteHost) ? 301 : 302,
    }
  } catch (_e) {
    return undefined
  }
}
