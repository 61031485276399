// https://git.sermonaudio.com/project-management/main/-/wikis/user-roles
// https://api-translate.review.sermonaudio.com/v2/site/users/roles

export enum UserRoles {
  Translate = 1,
  Admin = 2,
  VideoWall = 3,
}

export enum BroadcasterRoles {
  Admin = 2,
  Uploader = 3,
}

export interface apiBroadcasterRoles {
  broadcasterID: string
  broadcasterRoleID: BroadcasterRoles
}

export class Roles {
  ids: number[]
  titles: string[]

  constructor(info: Record<string, any>[]) {
    this.ids = []
    this.titles = []
    for (let i = 0; i < info.length; i++) {
      this.ids.push(info[i].roleID)
      this.titles.push(info[i].roleDescription)
    }
  }

  HasRole(role: UserRoles) {
    return this.ids.includes(role)
  }

  get Admin(): boolean {
    return this.HasRole(UserRoles.Admin)
  }

  get Translator(): boolean {
    return this.HasRole(UserRoles.Translate)
  }

  get VideoWall(): boolean {
    return this.HasRole(UserRoles.VideoWall)
  }
}
