import { ApiClient, ApiPaginationResult } from '~/apiclient/apiclient'
import { PodcastType } from '~/models/series'
import { BroadcasterApi } from '~/apiclient/apibroadcasters'

export const DefaultSeriesPageSize = 20

export type SeriesApiSortBy =
  | 'last_updated'
  | 'random'
  | 'newest_sermon_create_date'
  | 'title'
  | 'sermon_count_highest'
  | 'staff_pick_date'
  | string

export class SeriesRequestOptions {
  page = 1
  pageSize = 1
  searchKeyword?: string
  staffPicks = false
  sort_by: SeriesApiSortBy = ''
  onlyWithAlbumArt = false
  onlyPodcasts = false
  broadcasterID?: string
}

export interface FeedLinkApi {
  feedLinkID: string
  label: string
  link: string
  podcastType: PodcastType
  seriesID: string
}

export interface SeriesApi {
  id: number
  seriesID: number
  broadcaster: BroadcasterApi
  broadcasterID: string
  count: number
  description: string
  earliest: string
  feedLinks: FeedLinkApi[]
  imageUrl: string
  imageResizableUrl?: string
  latest: string
  isPodcast: boolean
  podcastSpeaker: string
  rssAtomUrl: string
  rssUrl: string
  title: string
  updated: number
  following: boolean
  lite: boolean
  defaultSortBy: string
}

export async function getSeriesList(
  this: ApiClient,
  options: Partial<SeriesRequestOptions>
): Promise<ApiPaginationResult<SeriesApi>> {
  const { data } = await this.$axios.get(`node/discover/series`, {
    params: { ...options },
  })

  return data
}

export async function getSeries(
  this: ApiClient,
  seriesID: string
): Promise<SeriesApi> {
  const { data } = await this.$axios.get(`node/series/${seriesID}`)
  return data
}
