import Vue from 'vue'
import { ActionTree, GetterTree, MutationTree } from 'vuex'
import { logError } from '~/assets/ts/utils/misc'
import { SermonApi, SermonRequestOptions } from '~/apiclient/apisermons'

export const state = () => ({
  sermons: {} as Record<string, SermonApi>,
  waveforms: {} as Record<string, number[]>,
  sermonList: [] as Record<string, SermonApi>[],
})

export type SermonsState = ReturnType<typeof state>

export const mutations: MutationTree<SermonsState> = {
  ADD_SERMON: (state, sermon: SermonApi) => {
    Vue.set(state.sermons, sermon.sermonID, sermon)
  },
  ADD_WAVEFORM: (state, { sermonID, waveform }) => {
    Vue.set(state.waveforms, sermonID, waveform)
  },
  SET_SERMON_LIST: (state, sermons: any) => {
    state.sermonList = sermons
  },
}

export const getters: GetterTree<SermonsState, SermonsState> = {
  sermon: (state) => (sermonID: string) => state.sermons[sermonID],
  waveform: (state) => (sermonID: string) => state.waveforms[sermonID],
  sermonList: (state) => () => state.sermonList,
}

export const actions: ActionTree<SermonsState, SermonsState> = {
  async fetchFilteredSermonList(
    { commit, getters },
    options: Partial<SermonRequestOptions | any>
  ) {
    try {
      const { results } = await this.$apiClient.getFilteredSermonList({
        ...options,
        eventType: options.event,
        broadcasterID: options.broadcaster,
        speakerName: options.speaker,
        book: options.scripture,
        page: options.page,
        pageSize: options.page_size,
        sortBy: options.sort,
        series: options.series,
      })
      if (results.length) {
        if (options.includeWaveform) {
          const sermonID = results[0].sermonID
          if (!getters.waveform(sermonID)) {
            await this.$apiClient.getWaveform(sermonID).then((waveform) => {
              commit('ADD_WAVEFORM', {
                waveform,
                sermonID,
              })
            })
          }
        }
        commit('SET_SERMON_LIST', results)
      }
    } catch (e) {
      logError(e)
    }
  },
  async fetchSermon({ commit, getters }, options) {
    try {
      const sermonID = options.sermonID as string
      const promises = [] as Promise<any>[]

      if (!getters.sermon(sermonID)) {
        promises.push(
          this.$apiClient.getSermon(sermonID, options.lite).then((sermon) => {
            commit('ADD_SERMON', sermon)
          })
        )
      }
      if (!!options.includeWaveform && !getters.waveform(sermonID)) {
        promises.push(
          this.$apiClient.getWaveform(sermonID).then((waveform) => {
            commit('ADD_WAVEFORM', {
              waveform,
              sermonID,
            })
          })
        )
      }
      await Promise.all(promises)
    } catch (e) {
      logError(e)
    }
  },

  async fetchWaveform({ commit, getters }, { sermonID }) {
    try {
      if (!getters.waveform(sermonID)) {
        await this.$apiClient.getWaveform(sermonID).then((waveform) => {
          commit('ADD_WAVEFORM', {
            waveform,
            sermonID,
          })
        })
      }
    } catch (e) {
      logError(e)
    }
  },
}
