import { ApiClient, ApiPaginationResult } from '~/apiclient/apiclient'
import { SermonApiEventType } from '~/apiclient/apisermons'

export interface LanguageApi {
  languageCode: string
  languageCode3: string
  languageName: string
  localizedName: string
  noCaptions: boolean
  type: 'language'
}

export async function getLanguages(this: ApiClient): Promise<LanguageApi[]> {
  const { data } = await this.$axios.get('node/languages')
  // TODO: remove this extra check once the apis get sorted
  // breaking change was introduced with fastapi that turns this endpoint from pagination to an array
  return data.results ?? data
}

export interface EventTypeApi {
  eventID: number
  description: SermonApiEventType
  displayEventType: string
  numberOfSermons: number
  eventImage: string
  popular: false
  isServiceTimeOption: boolean
}

export async function getSermonEventTypes(
  this: ApiClient
): Promise<ApiPaginationResult<EventTypeApi>> {
  const { data } = await this.$axios.get(
    'node/filter_options/sermon_event_types'
  )
  return data
}

export interface BibleVersionsApi {
  code: string
  title: string
}

export async function getBibleVersions(
  this: ApiClient
): Promise<ApiPaginationResult<BibleVersionsApi>> {
  const { data } = await this.$axios.get('node/bible_versions')
  return data
}

export type SocialAccountLabel = 'Facebook' | 'Twitter' | string

export interface SocialAccountTypeApi {
  label: SocialAccountLabel
  urlPrefix: string
}

export async function getSocialAccountTypes(
  this: ApiClient
): Promise<ApiPaginationResult<SocialAccountTypeApi>> {
  const { data } = await this.$axios.get('node/social_account_types')
  return data
}
