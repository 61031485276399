/* eslint-disable prefer-spread */
import { Context } from '@nuxt/types'
import Vue from 'vue'
import { GetApiCacheMax } from '~/assets/ts/utils/date'

export default function (context: Context) {
  process.env.LOCALE = context.i18n.locale
  Vue.prototype.$t = function (...args: any) {
    const result = this.$i18n.t.apply(this.$i18n, args)
    return result === '' ? args[0] : result
  }
  Vue.prototype.$tc = function (...args: any) {
    const result = this.$i18n.tc.apply(this.$i18n, args)
    return result === '' ? args[0] : result
  }

  const app = context.app

  // onBeforeLanguageSwitch called right before setting a new locale
  // app.i18n.onBeforeLanguageSwitch = (
  //   oldLocale: string,
  //   newLocale: string,
  //   isInitialSetup: boolean
  // ) => {
  //   console.log(oldLocale, newLocale, isInitialSetup)
  // }

  // onLanguageSwitched called right after a new locale has been set
  // app.i18n.onLanguageSwitched = (_oldLocale: string, newLocale: string) => {
  //   console.log(_oldLocale, newLocale)
  //   context.$axios.onRequest((config) => {
  //     if (!context.$isClient) return
  //     config.headers.common['Accept-Language'] = newLocale
  //   })
  // }
  app.$axios.interceptors.request.use((config) => {
    const locale = app.i18n.locale
    app.$axios.setHeader('Accept-Language', locale)

    config.params = config.params || {}

    // make sure our cached language matches the accept-language
    config.params.cacheLanguage = locale

    // set our cacheMax to today so that the cache is never more than 1 day old
    config.params.cacheMax = GetApiCacheMax()

    // we need to set the domain to alleviate CORS caching issues
    if (context.req?.headers.host) {
      config.params.cacheDomain = context.req.headers.host.split(/[:.]/)[0]
    } else if (typeof window !== 'undefined') {
      config.params.cacheDomain = window.location.hostname.split(/[:.]/)[0]
    }

    return config
  })
}
