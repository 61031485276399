
import Vue, { PropType } from 'vue'
import { NuxtError } from '@nuxt/types'
import { metadata } from '~/assets/ts/utils/metadata'
import SiteLayoutDefault from '~/components/site/layout/Default.vue'
import SaIcon from '~/components/_general/SaIcon.vue'

export default Vue.extend({
  components: { SaIcon, SiteLayoutDefault },
  layout: 'site',
  props: {
    error: {
      type: Object as PropType<NuxtError>,
      default() {
        return {
          statusCode: 404,
          message: this.$t('An error occurred'),
        }
      },
    },
  },
  head(this: any) {
    return metadata({ title: this.title })
  },
  computed: {
    title(): string {
      const error = this.error as NuxtError
      return `${error.statusCode} | ${error.message}`
    },
  },
})
