import { Auth } from '@nuxtjs/auth-next/dist/runtime'
import type { NuxtAxiosInstance } from '@nuxtjs/axios'
import { Context } from '@nuxt/types'
import * as sermons from '~/apiclient/apisermons'
import * as broadcasters from '~/apiclient/apibroadcasters'
import * as speakers from '~/apiclient/apispeakers'
import * as series from '~/apiclient/apiseries'
import * as search from '~/apiclient/apisearch'
import * as devotional from '~/apiclient/apidevotional'
import * as hymns from '~/apiclient/apihymns'
import * as comments from '~/apiclient/apicomments'
import * as customSites from '~/apiclient/apicustomsites'
import * as donationCampaigns from '~/apiclient/apidonationcampaigns'
import * as thirdParty from '~/apiclient/apithirdparty'
import * as webcasts from '~/apiclient/apiwebcasts'
import * as users from '~/apiclient/apiusers'
import * as radio from '~/apiclient/apiradio'
import * as groups from '~/apiclient/apigroups'
import * as bible from '~/apiclient/apibible'
import * as filters from '~/apiclient/apifilters'
import * as ads from '~/apiclient/apiads'
import * as hashtags from '~/apiclient/apihashtags'
import * as topics from '~/apiclient/apitopics'
import * as videoWall from '~/apiclient/apivideowall'
import * as broadcasterSignup from '~/apiclient/apibroadcastersignup'
import * as coupons from '~/apiclient/apicoupons'
import * as articles from '~/apiclient/apiarticles'
import * as misc from '~/apiclient/apimisc'

/**
 0: No Cache
 5: 5 Seconds
 10: 10 Seconds
 15: 15 Seconds
 20: 20 Seconds
 30: 30 Seconds
 45: 45 Seconds
 60: 1 Minute
 90: 1.5 Minutes
 120: 2 Minutes
 300: 5 Minutes
 3600: 1 Hour
 */
export type ApiCacheTTL =
  | 0
  | 5
  | 10
  | 15
  | 20
  | 30
  | 45
  | 60
  | 90
  | 120
  | 300
  | 3600

export interface ApiPaginationResult<K> {
  totalCount: number
  results: K[]
  nodeType: string
  nodeDisplayName: string
  next?: string
}

export interface ApiPaginationParams {
  page?: number
  pageSize?: number
  cacheTTL?: ApiCacheTTL
}

export class ApiClient {
  $auth: Auth
  $axios: NuxtAxiosInstance
  $context: Context

  constructor(settings: any) {
    this.$context = settings.$context as Context
    this.$auth = settings.$context.$auth as Auth
    this.$axios = settings.$context.$axios as NuxtAxiosInstance
  }

  async downloadFromApi(apiUrl: string, filename: string) {
    // Create a blob object from the text
    const { data } = await this.$axios.get(apiUrl, {
      responseType: 'blob',
    })

    const anchor = document.createElement('a')
    anchor.href = URL.createObjectURL(data)
    anchor.download = filename

    anchor.dispatchEvent(new MouseEvent('click'))

    URL.revokeObjectURL(anchor.href)
  }

  get baseURL(): string {
    return this.$axios.defaults.baseURL ?? 'https://api.sermonaudio.com'
  }

  // sermon methods
  public getFilteredSermonList = sermons.getFilteredSermonList
  public getSermon = sermons.getSermon
  public getWaveform = sermons.getWaveform
  public getSermonComments = sermons.getSermonComments
  public getFeaturedSermonCampaignID = sermons.getFeaturedSermonCampaignID

  // broadcaster methods
  public getBroadcaster = broadcasters.getBroadcaster
  public getBroadcasterGroups = broadcasters.getBroadcasterGroups
  public getBroadcasterList = broadcasters.getBroadcasterList
  public getAllBroadcasterSeries = broadcasters.getAllBroadcasterSeries
  public getBroadcasterLocations = broadcasters.getBroadcasterLocations
  public getBroadcasterNotices = broadcasters.getBroadcasterNotices

  // broadcaster settings methods
  public patchBroadcaster = broadcasters.patchBroadcaster
  public broadcasterUserRoles = broadcasters.broadcasterUserRoles
  public broadcasterUserInvites = broadcasters.broadcasterUserInvites
  public sendBroadcasterUserInvite = broadcasters.sendBroadcasterUserInvite
  public resendBroadcasterUserInvite = broadcasters.resendBroadcasterUserInvite
  public deleteBroadcasterUserInvite = broadcasters.deleteBroadcasterUserInvite
  public broadcasterBannerGallery = broadcasters.broadcasterBannerGallery
  public getBroadcasterApiKey = broadcasters.getBroadcasterApiKey
  public broadcasterPrivateData = broadcasters.broadcasterPrivateData
  public speakersForBroadcasters = broadcasters.speakersForBroadcasters
  public speakerSermonCountsForBroadcaster =
    broadcasters.speakerSermonCountsForBroadcaster

  public broadcasterHighlightedSermons =
    broadcasters.broadcasterHighlightedSermons

  public patchBroadcasterHighlightedSermons =
    broadcasters.patchBroadcasterHighlightedSermons

  public getBroadcasterBannerUploadUrl =
    broadcasters.getBroadcasterBannerUploadUrl

  public getBroadcasterImageUploadUrl =
    broadcasters.getBroadcasterImageUploadUrl

  // speaker methods
  public getSpeakerList = speakers.getSpeakerList
  public getSpeakerHighlightedList = speakers.getSpeakerHighlightedList
  public getSpeaker = speakers.getSpeaker
  public getBroadcastersFromSpeaker = speakers.getBroadcastersFromSpeaker
  public createSpeaker = speakers.createSpeaker

  // series methods
  public getSeriesList = series.getSeriesList
  public getSeries = series.getSeries

  // multisearch methods
  public getMultiSearch = search.getMultiSearch

  // daily devotional methods
  public getMorning = devotional.getMorning
  public getEvening = devotional.getEvening
  public getCheckbook = devotional.getCheckbook
  public getReading = devotional.getReading
  public getTodayHistory = devotional.getTodayHistory
  public getQuote = devotional.getQuote

  // hymn methods
  public getHymn = hymns.getHymn
  public getHymns = hymns.getHymns
  public getTunesByMetric = hymns.getTunesByMetric
  public getPrimaryPsalterTunes = hymns.getPrimaryPsalterTunes
  public getPsalmsFromTune = hymns.getPsalmsFromTune

  // comment methods
  public getFilteredComments = comments.getFilteredComments
  public getUserComments = comments.getUserComments
  public deleteComment = comments.deleteComment
  public createComment = comments.createComment

  // custom site methods
  public getCustomText = customSites.getCustomText

  // donation campaign methods
  public getDonationCampaign = donationCampaigns.getDonationCampaign
  public getDonationCampaignDonors = donationCampaigns.getDonationCampaignDonors
  public getDonationCampaignSupport =
    donationCampaigns.getDonationCampaignSupport

  // third party methods
  public getLocation = thirdParty.getLocation

  // webcast methods
  public getWebcastsInProgress = webcasts.getWebcastsInProgress
  public getWebcastHistory = webcasts.getWebcastHistory
  public getLiveWebcast = webcasts.getLiveWebcast
  public getWebcast = webcasts.getWebcast

  // radio streams
  public getRadioStreams = radio.getRadioStreams
  public getRadioStreamHistory = radio.getRadioStreamHistory

  // users
  public logOutAllDevices = users.logOutAllDevices
  public getUser = users.getUser
  public getFollowedBroadcasters = users.getFollowedBroadcasters
  public getFollowedSpeakers = users.getFollowedSpeakers
  public getFollowedSeries = users.getFollowedSeries
  public getFavoriteSermons = users.getFavoriteSermons
  public isFavoriteSermon = users.isFavoriteSermon
  public setFavoriteSermon = users.setFavoriteSermon
  public removeFavoriteSermon = users.removeFavoriteSermon
  public isFollowedBroadcaster = users.isFollowedBroadcaster
  public setFollowedBroadcaster = users.setFollowedBroadcaster
  public removeFollowedBroadcaster = users.removeFollowedBroadcaster
  public isFollowedSpeaker = users.isFollowedSpeaker
  public setFollowedSpeaker = users.setFollowedSpeaker
  public removeFollowedSpeaker = users.removeFollowedSpeaker
  public isFollowedSeries = users.isFollowedSeries
  public setFollowedSeries = users.setFollowedSeries
  public removeFollowedSeries = users.removeFollowedSeries
  public getUserBroadcasters = users.getUserBroadcasters
  public findUser = users.findUser
  public getUserFeed = users.getUserFeed
  public activateDevice = users.activateDevice

  public isValidEmail = users.isValidEmail
  public isValidUsername = users.isValidUsername
  public legacyBroadcasterLogin = users.legacyBroadcasterLogin

  public getPlayHistory = users.getPlayHistory
  public getSermonPlayHistory = users.getSermonPlayHistory
  public setSermonPlayHistory = users.setSermonPlayHistory
  public deletePlayHistory = users.deletePlayHistory

  // groups
  public getLegacyGroups = groups.getLegacyGroups
  public getGroups = groups.getGroups

  // bible
  public getBibleStructure = bible.getBibleStructure

  // filters
  public getLanguages = filters.getLanguages
  public getSermonEventTypes = filters.getSermonEventTypes
  public getSocialAccountTypes = filters.getSocialAccountTypes
  public getBibleVersions = filters.getBibleVersions

  // ads
  public getAd = ads.getAd
  public getAds = ads.getAds
  public getCouponsAvailable = ads.getCouponsAvailable
  public getAdConfig = ads.getAdConfig
  public getAdStatsAggregate = ads.getAdStatsAggregate
  public getAdUploadUrl = ads.getAdUploadUrl
  public updateAd = ads.updateAd
  public createAd = ads.createAd
  public getDisplayAds = ads.getDisplayAds

  // hashtags
  public getHashtags = hashtags.getHashtags

  // topics
  public getTopics = topics.getTopics

  // video wall
  public getVideoWallWebcastPlays = videoWall.getVideoWallWebcastPlays
  public doNotDisplaySermon = videoWall.doNotDisplaySermon
  public videoWallSermonPlays = videoWall.videoWallSermonPlays

  // broadcaster signup
  public getSignupInfo = broadcasterSignup.getSignupInfo
  public getBroadcasterIDAvailability =
    broadcasterSignup.getBroadcasterIDAvailability

  public createBroadcaster = broadcasterSignup.createBroadcaster

  // coupons
  public getSignupCouponInfo = coupons.getSignupCouponInfo
  public getEventCouponInfo = coupons.getEventCouponInfo

  // articles
  public getArticlesConfig = articles.getArticlesConfig
  public getArticles = articles.getArticles
  public getArticle = articles.getArticle
  public getArticleCategory = articles.getArticleCategory
  public getArticleCategories = articles.getArticleCategories
  public createArticle = articles.createArticle
  public createPlaceholderArticle = articles.createPlaceholderArticle
  public editArticle = articles.editArticle
  public deleteArticle = articles.deleteArticle
  public getArticleBannerUploadUrl = articles.getArticleBannerUploadUrl
  public deleteArticleBanner = articles.deleteArticleBanner

  // misc
  public mapPath = misc.mapPath
}

export default ApiClient
