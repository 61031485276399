
import Vue, { PropType } from 'vue'
import { TranslateResult } from 'vue-i18n'
import {
  SermonFilterCategories,
  SermonFilterSelection,
} from '~/components/filter/SermonsElement.vue'
import { BibleBookApi } from '~/apiclient/apibible'
import { SearchFor } from '~/apiclient/apisearch'
import FancyDropdown, {
  FancyDropdownOption,
} from '~/components/_general/FancyDropdown.vue'
import { Broadcaster, BroadcasterImageAspectRatio } from '~/models/broadcaster'
import { Speaker } from '~/models/speaker'
import { Series } from '~/models/series'
import { EventTypeApi, LanguageApi } from '~/apiclient/apifilters'
import { waitOneFrame } from '~/assets/ts/utils/misc'
import { GroupApi } from '~/apiclient/apigroups'
import SearchInput from '~/components/_general/SearchInput.vue'
import HorizontalRule from '~/components/_general/HorizontalRule.vue'

export default Vue.extend({
  name: 'FilterSermonValueDropdown',
  components: { HorizontalRule, FancyDropdown, SearchInput },
  props: {
    category: {
      type: String as PropType<SermonFilterCategories>,
      default: undefined,
    },
    broadcasterID: {
      type: String,
      default: undefined,
    },
    categoryTitle: {
      type: String as PropType<TranslateResult>,
      default: '',
    },
    selection: {
      type: Object as PropType<SermonFilterSelection>,
      default: undefined,
    },
    filters: {
      type: Array as PropType<SermonFilterSelection[]>,
      default: () => [],
    },
  },
  data() {
    return {
      startYear: 1950,
      selectedChapter: undefined as FancyDropdownOption | undefined,
      series: [] as SermonFilterSelection[],
      broadcasters: [] as SermonFilterSelection[],
      speakers: [] as SermonFilterSelection[],
      searchQuery: '',
      searching: false,
      searched: false,
      apiSearchMin: 3,
      optionImageSize: 32,
    }
  },
  computed: {
    searchPlaceholder(): TranslateResult {
      return this.$t('Search for {categoryItem}', {
        categoryItem: this.categoryTitle,
      })
    },
    searchMin(): number {
      return this.apiSearch ? this.apiSearchMin : 0
    },
    apiSearch(): boolean {
      return [
        SermonFilterCategories.Speaker,
        SermonFilterCategories.Series,
        SermonFilterCategories.Broadcaster,
      ].includes(this.category)
    },
    options(): FancyDropdownOption[] {
      return this.searchedFilterList.map((f) => {
        return {
          value: f.value,
          title: f.display ?? f.value,
          roundImage: this.category === SermonFilterCategories.Speaker,
          subtitle: f.subtitle,
          imageURL: f.imageURL,
        }
      })
    },
    searchedFilterList(): SermonFilterSelection[] {
      if (this.apiSearch) return this.filterList
      return this.filterList.filter((f) => this.selectionMatchesSearch(f))
    },
    filterList(): SermonFilterSelection[] {
      switch (this.category) {
        case SermonFilterCategories.Broadcaster:
          return this.broadcasters
        case SermonFilterCategories.Event:
          return this.events
        case SermonFilterCategories.Language:
          return this.languages
        case SermonFilterCategories.Denomination:
          return this.denominations
        case SermonFilterCategories.Series:
          return this.series
        case SermonFilterCategories.Speaker:
          return this.speakers
        case SermonFilterCategories.Year:
          return this.yearFilters
        case SermonFilterCategories.Book:
          return this.bibleFilters
        default:
          return []
      }
    },
    selectingScripture(): boolean {
      return this.category === SermonFilterCategories.Book
    },
    showChapterPicker(): boolean {
      return this.selectingScripture && !!this.currentValue
    },
    languages(): SermonFilterSelection[] {
      return this.$store.getters['filters/languages'].map((l: LanguageApi) => {
        return {
          value: l.languageCode3,
          display: l.localizedName,
          category: SermonFilterCategories.Language,
        }
      })
    },
    events(): SermonFilterSelection[] {
      return this.$store.getters['filters/events'].map((e: EventTypeApi) => {
        return {
          value: e.description,
          display: e.displayEventType,
          category: SermonFilterCategories.Event,
        }
      })
    },
    denominations(): SermonFilterSelection[] {
      return this.$store.getters['filters/groups'].map((d: GroupApi) => {
        return {
          value: d.shortTitle,
          category: SermonFilterCategories.Denomination,
        }
      })
    },
    bible(): BibleBookApi[] {
      return this.$store.getters['filters/bible']
    },
    bibleFilters(): SermonFilterSelection[] {
      return this.bible.map((b) => {
        return {
          value: b.osisAbbrev,
          display: b.displayTitle,
          category: SermonFilterCategories.Book,
        }
      })
    },
    chapters(): FancyDropdownOption[] {
      const chapters = [this.valueToChapter()] as FancyDropdownOption[]
      if (!this.showChapterPicker) return chapters
      const book = this.bible.find((b) => b.osisAbbrev === this.currentValue)
      if (book) {
        for (let n = 1; n < book.chapters + 1; n++) {
          chapters.push(this.valueToChapter(n))
        }
      }
      return chapters
    },
    yearFilters(): SermonFilterSelection[] {
      const year = new Date().getFullYear()
      const years = [] as SermonFilterSelection[]
      for (let i = this.startYear; i < year + 1; i++) {
        years.push({
          value: i.toString(),
          category: SermonFilterCategories.Year,
        })
      }
      return years.reverse()
    },
    currentValue(): string {
      return this.selection?.value ?? ''
    },
    chapterDisplayValue(): TranslateResult {
      if (this.selectedChapter) {
        return this.selectedChapter.title
      }
      return this.$t('No {categoryItem} Selected', {
        categoryItem: this.$t('Chapter'),
      })
    },
    displayValue(): TranslateResult {
      if (this.selection && this.selection.category === this.category) {
        return this.selection.display ?? this.selection.value
      }
      return this.$t('No {categoryItem} Selected', {
        categoryItem: this.categoryTitle,
      })
    },
    casedSearchQuery(): string {
      return this.searchQuery.toLowerCase()
    },
    noValuesText(): TranslateResult {
      if (this.apiSearch) {
        if (!this.searched) {
          return this.$t('Please search for a {categoryItem}', {
            categoryItem: this.categoryTitle,
          })
        } else if (this.searching) {
          return this.$t('Searching for {categoryItem}', {
            categoryItem: this.categoryTitle,
          })
        } else {
          return this.$t('No results found')
        }
      } else if (!this.filterList.length) {
        return this.$t('Retrieving {categoryItem} options', {
          categoryItem: this.categoryTitle,
        })
      } else {
        return this.$t('No options match search query')
      }
    },
    apiSearchFor(): SearchFor {
      if (this.category === SermonFilterCategories.Series) {
        return SearchFor.Series
      } else if (this.category === SermonFilterCategories.Speaker) {
        return SearchFor.Speaker
      }
      return SearchFor.Broadcaster
    },
    imageWidth() {
      if (this.category === SermonFilterCategories.Broadcaster) {
        return Math.round(this.optionImageSize / BroadcasterImageAspectRatio)
      }
      return this.optionImageSize
    },
  },
  watch: {
    category() {
      this.categoryChanged()
    },
  },
  destroyed() {
    this.$off('open', this.openValueDropdown)
    this.$off('openChapter', this.openChapterDropdown)
  },
  mounted() {
    this.$on('open', this.openValueDropdown)
    this.$on('openChapter', this.openChapterDropdown)
  },
  methods: {
    openChapterDropdown() {
      const valueDropdown = this.$refs.chapterDropdown as Vue
      valueDropdown.$emit('open')
    },
    openValueDropdown() {
      const valueDropdown = this.$refs.valueDropdown as Vue
      valueDropdown.$emit('open')
    },
    dropdownOpened() {
      const search = this.$refs.search as Vue
      search.$emit('focus')
    },
    selectionMatchesSearch(filter: SermonFilterSelection): boolean {
      const q = this.casedSearchQuery
      if (filter.value.toLowerCase().includes(q)) return true
      if (!filter.display) return false
      return filter.display.toString().toLowerCase().includes(q)
    },
    clearApiSearch() {
      this.broadcasters = []
      this.series = []
      this.speakers = []
      this.searched = false
    },
    async updateApiSearch() {
      if (!this.apiSearch) return
      this.searching = true
      this.searched = true
      const searchFor = this.apiSearchFor
      const data = await this.$apiClient.getMultiSearch({
        searchFor,
        query: this.searchQuery,
        pageSize: 15,
      })

      this.broadcasters = data.broadcasterResults.map(
        (b: Record<string, any>) => {
          const broadcaster = new Broadcaster(b)
          return {
            value: broadcaster.id,
            display: broadcaster.displayName,
            category: this.category,
            subtitle: broadcaster.location,
            imageURL: broadcaster.imageResizable(this.imageWidth),
          } as SermonFilterSelection
        }
      )

      this.speakers = data.speakerResults.map((s: Record<string, any>) => {
        const speaker = new Speaker(s)
        return {
          value: speaker.id.toString(),
          display: speaker.displayName,
          category: this.category,
          imageURL: speaker.resizableImage(this.imageWidth),
        } as SermonFilterSelection
      })

      this.series = data.seriesResults.map((s: Record<string, any>) => {
        const series = new Series(s)
        const broadcaster = series.broadcaster.displayName
        return {
          value: series.id.toString(),
          display: series.title,
          category: this.category,
          imageURL: series.albumArt(this.imageWidth),
          subtitle: this.$t('by {broadcaster}', { broadcaster }),
        } as SermonFilterSelection
      })

      this.searching = false
    },
    setFirstValue() {
      if (!this.options.length) return
      const filter = this.filters.find((f) => f.category === this.category)
      if (!filter) return
      this.changeValue(filter, true)
      if (!this.selectingScripture) return
      const chapter = this.filters.find(
        (f) => f.category === SermonFilterCategories.Chapter
      )
      if (!chapter) return
      this.selectedChapter = this.valueToChapter(chapter.value)
    },
    categoryChanged() {
      this.searchQuery = ''
      this.setFirstValue()
      if (this.category === SermonFilterCategories.Language) {
        this.getLanguages()
      } else if (this.category === SermonFilterCategories.Denomination) {
        this.getDenominations()
      } else if (this.category === SermonFilterCategories.Book) {
        this.getBible()
      } else if (this.category === SermonFilterCategories.Event) {
        this.getEvents()
      }
    },
    getBible() {
      this.$store.dispatch('filters/getBible')
    },
    getEvents() {
      this.$store.dispatch('filters/getEvents')
    },
    getDenominations() {
      this.$store.dispatch('filters/getGroups')
    },
    getLanguages() {
      this.$store.dispatch('filters/getLanguages')
    },
    changeValue(
      selection: SermonFilterSelection | undefined,
      dontApply = false
    ) {
      if (!selection) return
      this.$emit('change', selection, dontApply)
    },
    setValue(value: string | undefined) {
      this.changeValue(
        this.getFilterSelectionFromValue(value),
        this.selectingScripture
      )
      this.selectedChapter = undefined
    },
    async bibleChanged(value: string | undefined) {
      this.selectedChapter = this.chapters.find((c) => c.value === value)
      this.changeValue(this.getFilterSelectionFromValue(this.currentValue))

      await waitOneFrame()
      this.changeValue({
        category: SermonFilterCategories.Chapter,
        value: value ?? '',
        display: this.selectedChapter?.title,
      })
      this.selectedChapter = undefined
    },
    valueToChapter(
      n: string | number | undefined = undefined
    ): FancyDropdownOption {
      return {
        value: n?.toString() ?? '',
        title: n ? this.$t('Chapter {n}', { n }) : this.$t('All Chapters'),
      }
    },
    getFilterSelectionFromValue(
      value: string | undefined
    ): SermonFilterSelection | undefined {
      if (!value) return undefined
      const option = this.options.find((o) => o.value === value)
      if (!option) return undefined
      return {
        category: this.category,
        value,
        display: option.title,
      } as SermonFilterSelection
    },
  },
})
