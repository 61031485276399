import { TranslateResult } from 'vue-i18n'
import { ToastObject, ToastOptions } from 'vue-toasted'
import Vue from 'vue'
import { getApiError } from '~/assets/ts/utils/api'
import { isInIframe, sendMessageToIframeParent } from '~/assets/ts/utils/iframe'

export const ToastDefaultDuration = 5000

export enum ToastType {
  Info,
  Success,
  Error,
}

function toast(
  message: TranslateResult,
  options: ToastOptions = {},
  type = ToastType.Info
): ToastObject {
  const str = message.toString()
  if (type === ToastType.Error) {
    return Vue.toasted.error(str, options)
  } else if (type === ToastType.Success) {
    return Vue.toasted.success(str, options)
  } else {
    return Vue.toasted.info(str, options)
  }
}

export function ToastError(
  message: TranslateResult,
  duration = ToastDefaultDuration,
  options: ToastOptions = {}
) {
  /** Sends a toast to django if we're using this toast in an iframe */
  if (isInIframe()) {
    sendMessageToIframeParent('toast-error', message)
    return
  }
  return toast(message, { ...options, duration }, ToastType.Error)
}

export function ToastApiError(
  error: any,
  context: Record<any, any>,
  duration = ToastDefaultDuration,
  options: ToastOptions = {}
) {
  const message = getApiError(error, context).message
  /** Sends an error to django if we're using this toast in an iframe */
  if (isInIframe()) {
    sendMessageToIframeParent('toast-error', message)
    return
  }
  return toast(message, { ...options, duration }, ToastType.Error)
}

export interface CustomApiError {
  error: string
  message: TranslateResult
}

export function ToastCustomApiError(
  error: any,
  context: Vue,
  custom: CustomApiError[]
) {
  let message = getApiError(error, context).message
  for (let i = 0; i < custom.length; i++) {
    if (message.includes(custom[i].error)) {
      message = custom[i].message.toString()
    }
  }
  ToastError(message)
}

export function ToastSuccess(
  message: TranslateResult,
  duration = ToastDefaultDuration,
  options: ToastOptions = {}
) {
  /** Sends a toast to django if we're using this toast in an iframe */
  if (isInIframe()) {
    sendMessageToIframeParent('toast-success', message)
    return
  }
  toast(message, { ...options, duration }, ToastType.Success)
}

export function Toast(
  message: TranslateResult,
  duration = ToastDefaultDuration,
  options: ToastOptions = {}
): ToastObject {
  return toast(message, { ...options, duration }, ToastType.Info)
}
