
import Vue, { PropType } from 'vue'
import { TranslateResult } from 'vue-i18n'
import { ToastError } from '~/assets/ts/utils/toast'
import { waitOneFrame } from '~/assets/ts/utils/misc'
import SaIcon from '~/components/_general/SaIcon.vue'
import Spinner from '~/components/_general/Spinner.vue'
import { InputValue } from '~/assets/ts/utils/input'

export default Vue.extend({
  name: 'SearchInput',
  components: { Spinner, SaIcon },
  model: {
    prop: 'input',
    event: 'input',
  },
  props: {
    min: {
      type: Number,
      default: 1,
    },
    searching: {
      type: Boolean,
    },
    autofocus: {
      type: Boolean,
    },
    localSearch: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
    },
    useTheme: {
      type: Boolean,
      default: true,
    },
    input: {
      type: String,
      default: '',
    },
    border: {
      type: Boolean,
      default: true,
    },
    rounded: {
      type: Boolean,
      default: true,
    },
    backgroundThemeColor: {
      type: Boolean,
    },
    placeholder: {
      type: String as PropType<TranslateResult>,
      default: '',
    },
  },
  data() {
    return {
      focus: false,
    }
  },
  computed: {
    hasInput(): boolean {
      return !!this.input.length
    },
    minInput(): boolean {
      return this.input.length >= this.min
    },
    isLocalSearching(): boolean {
      return this.localSearch && !!this.input
    },
  },
  destroyed() {
    this.$off('focus', this.focusInput)
  },
  mounted() {
    this.$on('focus', this.focusInput)
  },
  methods: {
    InputValue,
    async focusInput() {
      await waitOneFrame()
      const input = this.$refs.input as HTMLInputElement
      input.focus()
    },
    clear() {
      this.$emit('input', '')
      this.$emit('clear')
    },
    trySearch() {
      if (!this.hasInput) {
        this.clear()
        return
      }
      if (!this.minInput) {
        ToastError(
          this.$t('Search must include at least {count} characters', {
            count: this.min,
          })
        )
      } else {
        this.$emit('search')
      }
    },
  },
})
