import { render, staticRenderFns } from "./BatchModal.vue?vue&type=template&id=7d2e19a4&scoped=true&"
import script from "./BatchModal.vue?vue&type=script&lang=ts&"
export * from "./BatchModal.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7d2e19a4",
  null
  
)

export default component.exports