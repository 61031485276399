import Vue from 'vue'
import { ActionTree, GetterTree, MutationTree } from 'vuex'
import { WebcastApi, WebcastsInProgressOptions } from '~/apiclient/apiwebcasts'
import { logError } from '~/assets/ts/utils/misc'

export const state = () => ({
  webcasts: {} as Record<string, WebcastApi>,
  boostedWebcasts: [] as WebcastApi[],
  broadcasterWebcast: undefined as WebcastApi | undefined,
  webcastsUpdateTimestamp: 0,
})

export type WebcastsState = ReturnType<typeof state>
export interface SetWebcastFollowedOptions {
  broadcasterID: string
  followed: boolean
}

function GetWebcast(
  state: WebcastsState,
  broadcasterID: string
): WebcastApi | undefined {
  if (state.broadcasterWebcast?.broadcasterID === broadcasterID) {
    return state.broadcasterWebcast
  }
  return state.webcasts[broadcasterID]
}

export const mutations: MutationTree<WebcastsState> = {
  SET_BROADCASTER_WEBCAST_IN_PROGRESS: (
    state,
    webcast: WebcastApi | undefined
  ) => {
    state.broadcasterWebcast = webcast
  },
  SET_WEBCASTS_IN_PROGRESS: (state, webcasts: WebcastApi[]) => {
    const live = webcasts.map((w) => w.broadcasterID)
    const recentlyLive = Object.keys(state.webcasts)
    const offline = recentlyLive.filter((id) => !live.includes(id))
    offline.forEach((broadcasterId) => {
      Vue.delete(state.webcasts, broadcasterId)
    })

    for (let i = 0; i < webcasts.length; i++) {
      const webcast = webcasts[i]
      Vue.set(state.webcasts, webcast.broadcasterID, webcast)
    }
    state.webcastsUpdateTimestamp = new Date().getTime()
  },
  SET_BOOSTED_WEBCASTS: (state, boostedWebcasts: WebcastApi[]) => {
    state.boostedWebcasts = boostedWebcasts
  },
  CLEAR_WEBCASTS_IN_PROGRESS: (state) => {
    state.webcasts = {}
  },
  SET_WEBCAST_IN_PROGRESS_FOLLOWED: (
    state,
    options: SetWebcastFollowedOptions
  ) => {
    const webcast = state.webcasts[options.broadcasterID]
    if (!webcast) return
    webcast.followedBroadcaster = options.followed
    Vue.set(state.webcasts, options.broadcasterID, webcast)
  },
}

export const getters: GetterTree<WebcastsState, WebcastsState> = {
  broadcasterLive: (state) => (broadcasterID: string) => {
    return !!GetWebcast(state, broadcasterID)
  },
  webcastsInProgress: (state) =>
    Object.keys(state.webcasts).map((id) => state.webcasts[id]),
  webcastsUpdateTimestamp: (state) => state.webcastsUpdateTimestamp,
  broadcasterWebcast: (state) => (broadcasterID: string) => {
    return GetWebcast(state, broadcasterID)
  },
  webcastById: (state) => (webcastID: number) => {
    const webcasts = Object.keys(state.webcasts).map(
      (broadcasterID) => state.webcasts[broadcasterID]
    ) as WebcastApi[]
    return webcasts.find(
      (webcast: WebcastApi) => webcast.webcastID === webcastID
    )
  },
  boostedWebcast: (state) => {
    return state.boostedWebcasts.length ? state.boostedWebcasts[0] : undefined
  },
}

export const actions: ActionTree<WebcastsState, WebcastsState> = {
  setWebcastInProgressFollowed({ commit }, options: SetWebcastFollowedOptions) {
    commit('SET_WEBCAST_IN_PROGRESS_FOLLOWED', options)
  },
  async fetchBoostedWebcasts({ commit }) {
    try {
      const webcasts = await this.$apiClient.getWebcastsInProgress({
        boostedOnly: true,
      })
      commit('SET_BOOSTED_WEBCASTS', webcasts)
    } catch (e) {
      logError(e)
    }
  },
  async fetchWebcastsInProgress(
    { commit },
    options: WebcastsInProgressOptions = {}
  ) {
    try {
      const webcasts = await this.$apiClient.getWebcastsInProgress(options)
      if (options.broadcasterID) {
        const webcast = webcasts.length ? webcasts[0] : undefined
        commit('SET_BROADCASTER_WEBCAST_IN_PROGRESS', webcast)
      } else {
        commit('SET_WEBCASTS_IN_PROGRESS', webcasts)
      }
    } catch (e) {
      logError(e)
    }
  },
}
